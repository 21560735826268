import { Box, Flex, IconButton } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import MobileLayout from "../MobileLayout/MobileLayout";

import { BiSolidHome } from "react-icons/bi";
import { BsFillPersonFill } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useToastWrapper } from "../../../Wrapper/toastWrapper";

type Props = {
  children: React.ReactNode;
};

const noFooter = [
  "/organizer/company",
  "/organizer/chat",
  "/organizer/notifications",
];

const OrganizerLayout = ({ children }: Props) => {
  const location = useLocation();

  const navigate = useNavigate();

  const { user } = useSelector((state: any) => state);

  const { error } = useToastWrapper();

  const [authState, setAuthState] = useState(false);

  useEffect(() => {
    if (user && user?.role === "Event_Organizer") {
      setAuthState(true);
    } else {
      navigate("/login");
    }
  }, [user]);

  return (
    <MobileLayout>
      <Flex
        maxW={"600px"}
        w="100%"
        direction="column"
        alignSelf={"center"}
        mx={"auto"}
        minH={"100vh"}
        maxH={"100vh"}
      >
        {authState && children}
      </Flex>

      {noFooter.includes(location.pathname) ? null : (
        <Box
          mt={"20px"}
          w={"100%"}
          position={"absolute"}
          bg={"white"}
          py={"30px"}
          shadow={"0px 0px 10px rgba(0, 0, 0, 0.1)"}
          border={"1px solid #E2E8F0"}
          bottom={0}
          borderTopRadius={"40px"}
        >
          <Flex
            maxW={"400px"}
            mx={"auto"}
            justifyContent={"space-between"}
            px={"50px"}
          >
            <IconButton
              aria-label="Home"
              icon={<BiSolidHome size={30} color={"black"} />}
              onClick={() => {
                navigate("/organizer");
              }}
              colorScheme="transparent"
            />

            <IconButton
              aria-label="Profile"
              icon={<BsFillPersonFill size={30} color={"black"} />}
              onClick={() => {
                navigate("/organizer/myaccount");
              }}
              colorScheme="transparent"
            />
          </Flex>
        </Box>
      )}
    </MobileLayout>
  );
};

export default OrganizerLayout;
