import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { AiFillEye, AiFillEyeInvisible, AiFillMail } from "react-icons/ai";
import { Icons } from "../../Assets/icons";
import Header from "../../Components/Common/Header";

import Loader from "../../Components/Loader/Loader";
import { useNavigate, useParams } from "react-router-dom";
import { useToastWrapper } from "../../Wrapper/toastWrapper";
import { errorHandler } from "../../Utils/handler";
import { AuthAPI } from "../../Apis/authAPI";

type Props = {};

const CreatePassword = (props: Props) => {
  const { token } = useParams();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [loading, setLoading] = useState(false);

  const submitRef = useRef<any>(null);

  const navigate = useNavigate();

  const { success, error } = useToastWrapper();

  const [showPassword, setShowPassword] = useState({
    old: false,
    new: false,
  });

  const handleSubmit = async () => {
    if (!password || !confirmPassword)
      return error("Please fill all the fields", "");
    if (password !== confirmPassword)
      return error("Passwords do not match", "");
    try {
      setLoading(true);
      const res = await AuthAPI.createPassword(token, { password });
      if (res) {
        success("Password Updated Succcessfully", "");
        navigate("/");
      }
    } catch (err) {
      error(errorHandler(err), "");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const listener = (event: any) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        submitRef.current.click();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  return (
    <Box height={"100%"} flexGrow={"grow"} p={6}>
      {loading ? <Loader /> : null}
      <Header>Create Password</Header>
      {/* <Text fontSize={"14px"} textAlign={"center"} mt={6}>
        Retrieve your password
      </Text> */}
      <Box mt={"32px"}>
        <FormControl>
          <FormLabel>Password</FormLabel>
          <InputGroup>
            <InputLeftElement h={"50px"} pointerEvents="none">
              <Image src={Icons.LoginMail} alt="" />
            </InputLeftElement>
            <Input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              h={"50px"}
              type={showPassword.old ? "text" : "password"}
              placeholder="Enter New Password"
            />
            <InputRightElement h={"50px"}>
              {showPassword.old ? (
                <AiFillEyeInvisible
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setShowPassword((prev) => ({
                      ...prev,
                      old: !prev.old,
                    }));
                  }}
                />
              ) : (
                <AiFillEye
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setShowPassword((prev) => ({
                      ...prev,
                      old: !prev.old,
                    }));
                  }}
                />
              )}
            </InputRightElement>
          </InputGroup>
        </FormControl>
        <FormControl mt={2}>
          <FormLabel>Confirm Password</FormLabel>
          <InputGroup>
            <InputLeftElement h={"50px"} pointerEvents="none">
              <Image src={Icons.LoginMail} alt="" />
            </InputLeftElement>
            <Input
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              h={"50px"}
              type={showPassword.new ? "text" : "password"}
              placeholder="Confirm New Password"
            />
            <InputRightElement h={"50px"}>
              {showPassword.new ? (
                <AiFillEyeInvisible
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setShowPassword((prev) => ({
                      ...prev,
                      new: !prev.new,
                    }));
                  }}
                />
              ) : (
                <AiFillEye
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setShowPassword((prev) => ({
                      ...prev,
                      new: !prev.new,
                    }));
                  }}
                />
              )}
            </InputRightElement>
          </InputGroup>
        </FormControl>
        <Flex justifyContent={"end"}>
          <Text
            onClick={() => {
              navigate(-1);
            }}
            color={"blue"}
            mt={2}
            cursor={"pointer"}
          >
            Back to Sign In
          </Text>
        </Flex>
      </Box>

      <Button
        ref={submitRef}
        onClick={handleSubmit}
        mt={"80px"}
        width={"full"}
        h={"60px"}
        alignItems={"center"}
        justifyContent={"center"}
        borderRadius={"16px"}
        display={"flex"}
        background={"primary.main"}
        color={"white"}
      >
        Submit
      </Button>
      {/* <HStack mt={'34px'} justifyContent={'center'}>
                        <Text>
                        Don't have an account?
                        </Text>
                        <Text color={'#2869FE'}>
                        Sign Up
                        </Text>
                    </HStack> */}
    </Box>
  );
};

export default CreatePassword;
