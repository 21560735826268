import { Box, Flex, Image, Text } from "@chakra-ui/react";
import React from "react";
import Header from "./Header";
import { Images } from "../../Assets/images";

type Props = {};

const NoNotifications = (props: Props) => {
  return (
    <Flex flexGrow={1} direction="column" p={6}>
      <Flex
        flexGrow={1}
        justifyContent={"center"}
        alignItems={"center"}
        direction={"column"}
        gap={10}
      >
        <Image src={Images.NotificationIllustration} alt="" />
        <Text fontSize={"15px"} fontWeight={400} textAlign={"center"}>
          There are no notifications
        </Text>
      </Flex>
    </Flex>
  );
};

export default NoNotifications;
