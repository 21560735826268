import LoginMail from './login_mail.png'
import LoginPassword from './login_password.png'
import BackButton from './back_button.png'
import BackButtonWhite from './back_button_white.svg'
import CheckOutline from './check_outline.svg'
import CheckFilled from './check_fill.svg'
import ProfileFullName from './profile_full_name.svg'
import ProfileCompanyName from './profile_company_name.svg'
import ProfileCompanyWebsite from './profile_company_website.svg'
import ProfileDesignation from './profile_designation.svg'
import ProfileWorkEmail from './profile_work_email.svg'
import ProfilePhoneNumber from './profile_phone_number.svg'
import Promotion from './promotion.png'
import cross from './cross.svg'

export const Icons =  {
    LoginMail, 
    LoginPassword, 
    BackButton, 
    BackButtonWhite, 
    CheckOutline, 
    CheckFilled,
    cross,
    
    // Profile
    ProfileFullName,
    ProfileCompanyName,
    ProfileCompanyWebsite,
    ProfileDesignation,
    ProfileWorkEmail,
    ProfilePhoneNumber,

    Promotion

}
