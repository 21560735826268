import { Box, Flex, Text, VStack } from "@chakra-ui/react";
import React from "react";

//// Icons
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { BsFillPersonFill } from "react-icons/bs";
import { BiSolidLockAlt } from "react-icons/bi";
import { FaBoxOpen } from "react-icons/fa";
import { IoLogOut } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { handleLogout } from "../../Utils/logout";

const Account = () => {
  const navigate = useNavigate();
  return (
    <Box
      w={"100%"}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      minH={"100vh"}
      maxH={"100vh"}
    >
      <Flex mt={"30px"} alignItems={"center"} gap={4}>
        <Text fontSize={"lg"} fontWeight={500}>
          My Account Info
        </Text>
        <AiOutlineQuestionCircle size={22} />
      </Flex>

      <Box mt={"auto"} w={"100%"}>
        <Text
          textAlign={"center"}
          fontSize={"lg"}
          fontWeight={500}
          color={"gray.700"}
          mt={2}
        >
          Organizer Company Name
        </Text>

        <Box
          mt={"20px"}
          w={"100%"}
          bg={"linear.100"}
          pt={"30px"}
          pb={"100px"}
          borderTopRadius={"40px"}
          px={"30px"}
        >
          <VStack spacing={0}>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"start"}
              py={"20px"}
              gap={4}
              w={"100%"}
              borderBottom={"1px solid #E2E8F0"}
              onClick={() => {
                navigate("/profile");
              }}
            >
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                h={"50px"}
                w={"50px"}
                bg={"#ffffff1a"}
                rounded={"15px"}
              >
                <BsFillPersonFill size={24} color={"white"} />
              </Box>
              <Text fontSize={"lg"} fontWeight={500} color={"white"}>
                My Profile
              </Text>
            </Box>

            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"start"}
              py={"20px"}
              gap={4}
              w={"100%"}
              borderBottom={"1px solid #E2E8F0"}
              onClick={() => {
                navigate("/reset-password");
              }}
            >
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                h={"50px"}
                w={"50px"}
                bg={"#ffffff1a"}
                rounded={"15px"}
              >
                <BiSolidLockAlt size={24} color={"white"} />
              </Box>
              <Text fontSize={"lg"} fontWeight={500} color={"white"}>
                Change Password
              </Text>
            </Box>

            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"start"}
              py={"20px"}
              gap={4}
              w={"100%"}
              borderBottom={"1px solid #E2E8F0"}
            >
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                h={"50px"}
                w={"50px"}
                bg={"#ffffff1a"}
                rounded={"15px"}
              >
                <FaBoxOpen size={24} color={"white"} />
              </Box>
              <Text fontSize={"lg"} fontWeight={500} color={"white"}>
                Events
              </Text>
            </Box>

            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"start"}
              py={"20px"}
              gap={4}
              w={"100%"}
              //   borderBottom={"1px solid #E2E8F0"}
            >
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                h={"50px"}
                w={"50px"}
                bg={"#ffffff1a"}
                rounded={"15px"}
                onClick={() => handleLogout()}
              >
                <IoLogOut size={24} color={"white"} />
              </Box>
              <Text fontSize={"lg"} fontWeight={500} color={"white"}>
                Logout
              </Text>
            </Box>
          </VStack>
        </Box>
      </Box>
    </Box>
  );
};

export default Account;
