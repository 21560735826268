import { createSlice } from "@reduxjs/toolkit";

const notificationSlice = createSlice({
  name: "notification",

  initialState: {
    notifications: [],
    unreadNotifications: 0,
    loading: false,
  },

  reducers: {
    setNotifications: (state, action) => {
        state.notifications = action.payload.notifications;
        state.unreadNotifications = action.payload.unreadNotifications;
    },
    setLoading: (state, action) => {
        state.loading = action.payload;
    }

  },
});

export const { setNotifications, setLoading } = notificationSlice.actions;

export const notificationReducer = notificationSlice.reducer;
