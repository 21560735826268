import {
  Box,
  Button,
  Flex,
  Image,
  Link,
  Text,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Icons } from "../../Assets/icons";
import { CommonAPI } from "../../Apis/commonApi";
import { useToastWrapper } from "../../Wrapper/toastWrapper";
import { errorHandler } from "../../Utils/handler";
import Loader from "../Loader/Loader";
import { MdClose, MdOutlineStarOutline } from "react-icons/md";

type Props = {
  handleCloseModal: () => void;
  exhibitorName?: string;
  docLinks?: LinkItemProps[];
};

type LinkItemProps = {
  id: string;
  assetTitle: string;
  assetUrl: string;
};

const LinkItem: React.FC<LinkItemProps> = ({ id, assetTitle, assetUrl }) => {
  return (
    <Box
      key={id}
      width={"100%"}
      pt={2}
      pb={2}
      pl={2}
      pr={2}
      mb={2}
      display={"flex"}
      flexDirection={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      border={"1px"}
      borderColor={"gray.200"}
    >
      <Link href={assetUrl} textColor={"card.borderBlue"} isExternal>
        {assetTitle}
      </Link>
      <Box cursor={"pointer"} onClick={() => {}}>
        <MdOutlineStarOutline size={32} color="black" />
      </Box>
    </Box>
  );
};

const ViewDocsModal = (props: Props) => {
  return (
    <Box w={"100%"} h={"100%"} py={"21px"}>
      <VStack
        position={"relative"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        className={"create-meeting-modal"}
      >
        {/* <Text
            fontSize={"20px"}
            fontWeight={700}
            textAlign={"center"}
            color={"black"}
          >
             Create meeting 
          </Text> */}
        {/* <Image
            onClick={() => {
              props.handleCloseModal();
            }}
            position={"absolute"}
            src={Icons.cross}
            right={0}
            top={0}
          /> */}
        <Box
          position={"absolute"}
          right={0}
          top={0}
          pb={3}
          cursor={"pointer"}
          zIndex={200}
        >
          <MdClose
            size={20}
            onClick={() => {
              props.handleCloseModal();
            }}
          />
        </Box>
      </VStack>
      <Box
        position={"relative"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
        // h={"60px"}
      >
        <Text
          fontSize={"md"}
          fontWeight={"regular"}
          textAlign={"center"}
          color={"black"}
          mb={"15px"}
        >
          Documents, Images & Videos
        </Text>
        <Text
          fontSize={"sm"}
          fontWeight={"medium"}
          textAlign={"center"}
          color={"black"}
          mb={"16px"}
        >
          {props.exhibitorName}
        </Text>
        {props.docLinks && props.docLinks.length > 0 ? (
          props.docLinks.map((link) => (
            <LinkItem
              id={link.id}
              assetTitle={link.assetTitle}
              assetUrl={link.assetUrl}
            />
          ))
        ) : (
          <Text
            fontSize={"sm"}
            fontWeight={"light"}
            textAlign={"center"}
            color={"black"}
            mb={"16px"}
          >
            No attachments found
          </Text>
        )}
      </Box>
    </Box>
  );
};

export default ViewDocsModal;
