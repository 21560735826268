import React, { useEffect, useState } from "react";
import { QrScanner } from "@yudiel/react-qr-scanner";
import { Box } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import actions from "../../redux/Actions";
import { VisitorScreens } from "../../Constants/data";

const Qrscan = () => {

  const dispatch = useDispatch();

  const [qrState, setQrState] = useState({
    delay: 100,
    result: "No result",
  });

  const previewStyle = {
    height: 240,
    width: 320,
  };

  useEffect(() => {
    dispatch(
      actions.visitorMenu.set({
        activeMenu: VisitorScreens.SCAN
      })
    )
  }, [])

  const handleScan = (data: any) => {
    setQrState({
      ...qrState,
      result: data,
    });
  };
  const handleError = (err: any) => {
    console.error(err);
  };

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      height={"100%"}
    >
      <QrScanner
        onDecode={(result) => console.log(result)}
        onError={(error) => console.log(error?.message)}
      />
    </Box>
  );
};

export default Qrscan;
