import actions from "../redux/Actions";
import { store } from "../redux/Store";

export const handleLogout = () => {
  const d = {
    token: null,
    user: null,
    role: null,
  };
  store.dispatch(actions.user.set(d));
};
