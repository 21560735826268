import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { reducer } from "./slices/user";
import { notificationReducer } from "./slices/notification";
import { exhibitorMenuReducer } from "./slices/exhibitorMenu";
import { visitorMenuReducer } from "./slices/visitorMenu";
import { exhibitorAssetReducer } from "./slices/exhibitorAssetSlice";
import { subscriptionReducer } from "./slices/subscriptionSlice";






import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web


const persistConfig = {
  key: 'root',
  storage,
}

const reducers = combineReducers({
  user: reducer,
  notification: notificationReducer,
  exhibitorMenu: exhibitorMenuReducer,
  visitorMenu: visitorMenuReducer,
  exhibitorAsset: exhibitorAssetReducer,
  subscription: subscriptionReducer
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});


export const persistor = persistStore(store);