import { useDispatch } from "react-redux";
import actions from "../redux/Actions";
import { store } from "../redux/Store";
import { handleLogout } from "./logout";

export const errorHandler = (err: any) => {
  const errMsg =
    err?.response?.data?.message ||
    err?.response?.data?.error?.message ||
    err?.data?.message ||
    "Something went wrong";

  return errMsg;
};

export const responseHandler = (res: any) => {
  if (res.status === 200 || res.status === 201) {
    res.data.success = true;
  } else {
    res.data.success = false;
  }

  return res.data;
};

export const errorResponseHandler = (err: any) => {
  if (err.response.status == 403) {
    handleLogout();
  }
  return err;
};
