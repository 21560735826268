import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Header from "../../Components/Common/Header";
import { Icons } from "../../Assets/icons";
import { color } from "framer-motion";
import { useSelector } from "react-redux";
import { ExhibitorAPI } from "../../Apis/exhibitorAPI";
import { useToastWrapper } from "../../Wrapper/toastWrapper";
import { errorHandler } from "../../Utils/handler";
import Loader from "../../Components/Loader/Loader";

type Props = {};

const Profile = (props: Props) => {
  const { user } = useSelector((state: any) => state.user);

  const { error, success } = useToastWrapper();

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState<any>({
    name: "",
    companyName: "",
    designation: "",
    workEmail: "",
    socialEmail: "",
    phoneNumber: "",
    alternatePhoneNumber: "",
    companyWebsite: "",
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const [flag, setFlag] = useState(false);

  const getData = async () => {
    setLoading(true);
    try {
      const res = await ExhibitorAPI.getExhibitorProfile();
      if (res) {
        console.log(res);
        const obj = {
          name: res.data.data?.personName,
          companyName: res.data.data?.companyName,
          designation: res.data.data?.personDesignation,
          workEmail: res.data.data?.personEmail,
          // socialEmail: res.data.data?.personEmail,
          phoneNumber: res.data.data?.personPhoneNumber,
          companyWebsite: res.data.data?.companyUrl,
        };
        setData(obj);
      }
    } catch (err) {
      console.log(err);
      error(errorHandler(err));
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async () => {
    setLoading(true);
    try {
      const d = {
        personName: data.name,
        companyName: data.companyName,
        personDesignation: data.designation,
        personEmail: data.workEmail,
        personPhoneNumber: data.phoneNumber,
        companyUrl: data.companyWebsite,
      };
      const res = await ExhibitorAPI.updateExhibitorProfile(d);
      if (res) {
        console.log(res);
        success("Profile Updated Successfully", "");
        getData();
        // setFlag(false)
      }
    } catch (err) {
      console.log(err);
      error(errorHandler(err));
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  console.log(user);

  return (
    <Box flexGrow={1} p={6}>
      {loading ? <Loader /> : null}
      <Header backButton>My Profile</Header>
      <Flex direction={"column"} w={"100%"} mt={"20px"} gap={"30px"}>
        {/* Full Name */}
        <Flex w={"100%"}>
          <Flex display={"flex"} gap={"8px"} w={"100%"}>
            <Input
              h={"50px"}
              type="text"
              placeholder="Mr"
              w={"60px"}
              borderColor={"text.black"}
              color={"text.black"}
              _placeholder={{ color: "text.black" }}
              borderRadius={"16px"}
            />
            <InputGroup flexGrow={1} borderColor={"text.black"}>
              <InputLeftElement h={"50px"} pointerEvents="none">
                <Image src={Icons.ProfileFullName} alt="" />
              </InputLeftElement>
              <Input
                value={data.name}
                name="name"
                onChange={handleChange}
                h={"50px"}
                type="text"
                placeholder="Full Name"
                color={"text.black"}
                _placeholder={{ color: "text.black" }}
                borderRadius={"16px"}
              />
            </InputGroup>
          </Flex>
        </Flex>

        {/* Company name */}
        <InputGroup flexGrow={1} borderColor={"text.black"}>
          <InputLeftElement h={"50px"} pointerEvents="none">
            <Image src={Icons.ProfileCompanyName} alt="" />
          </InputLeftElement>
          <Input
            disabled={true}
            value={data.companyName}
            name="companyName"
            onChange={handleChange}
            h={"50px"}
            type="text"
            placeholder="Company Name"
            color={"text.black"}
            _placeholder={{ color: "text.black" }}
            borderRadius={"16px"}
          />
        </InputGroup>

        {/* Designation */}
        <InputGroup flexGrow={1} borderColor={"text.black"}>
          <InputLeftElement h={"50px"} pointerEvents="none">
            <Image src={Icons.ProfileDesignation} alt="" />
          </InputLeftElement>
          <Input
            value={data.designation}
            name="designation"
            onChange={handleChange}
            h={"50px"}
            type="text"
            placeholder="Designation"
            color={"text.black"}
            _placeholder={{ color: "text.black" }}
            borderRadius={"16px"}
          />
        </InputGroup>

        {/* Work Email */}
        <InputGroup flexGrow={1} borderColor={"text.black"}>
          <InputLeftElement h={"50px"} pointerEvents="none">
            <Image src={Icons.ProfileWorkEmail} alt="" />
          </InputLeftElement>
          <Input
            disabled={true}
            value={data.workEmail}
            name="workEmail"
            onChange={handleChange}
            h={"50px"}
            type="text"
            placeholder="Work Email"
            color={"text.black"}
            _placeholder={{ color: "text.black" }}
            borderRadius={"16px"}
          />
        </InputGroup>

        {/* Social Email */}
        {/* <InputGroup flexGrow={1} borderColor={'text.black'} >
                    <InputLeftElement h={'50px'} pointerEvents='none'>
                        <Image src={Icons.ProfileWorkEmail} alt="" />
                    </InputLeftElement>
                    <Input h={'50px'} type='text' placeholder='Social Email' color={'text.black'} _placeholder={{ color: 'text.black' }} borderRadius={'16px'} />
                </InputGroup> */}

        {/* Phone Number */}
        <InputGroup flexGrow={1} borderColor={"text.black"}>
          <InputLeftElement h={"50px"} pointerEvents="none">
            <Image src={Icons.ProfilePhoneNumber} alt="" />
          </InputLeftElement>
          <Input
            value={data?.phoneNumber}
            name="phoneNumber"
            onChange={handleChange}
            h={"50px"}
            type="text"
            placeholder="Phone Number"
            color={"text.black"}
            _placeholder={{ color: "text.black" }}
            borderRadius={"16px"}
          />
        </InputGroup>

        {/* Alternate Phone Number */}
        {/* <InputGroup flexGrow={1} borderColor={'text.black'} >
                    <InputLeftElement h={'50px'} pointerEvents='none'>
                        <Image src={Icons.ProfilePhoneNumber} alt="" />
                    </InputLeftElement>
                    <Input h={'50px'} type='text' placeholder='Alternate Phone Number' color={'text.black'} _placeholder={{ color: 'text.black' }} borderRadius={'16px'} />
                </InputGroup> */}

        {/* Company Website */}
        <InputGroup flexGrow={1} borderColor={"text.black"}>
          <InputLeftElement h={"50px"} pointerEvents="none">
            <Image src={Icons.ProfileCompanyWebsite} alt="" />
          </InputLeftElement>
          <Input
            value={data?.companyWebsite}
            name="companyWebsite"
            onChange={handleChange}
            h={"50px"}
            type="text"
            placeholder="Company Website"
            color={"text.black"}
            _placeholder={{ color: "text.black" }}
            borderRadius={"16px"}
          />
        </InputGroup>
      </Flex>

      {/* Buttons */}
      <Flex w={"100%"} justifyContent={"space-between"} mt={"40px"}>
        <Button
          onClick={handleUpdate}
          w={flag ? "45%" : "100%"}
          size={"sm"}
          background={"primary.main"}
          color={"white"}
          h={"40px"}
          borderRadius={"14px"}
        >
          Update
        </Button>
        {flag ? (
          <Button
            w={"45%"}
            size={"sm"}
            background={"secondary.main"}
            color={"white"}
            h={"40px"}
            borderRadius={"14px"}
          >
            Skip for now
          </Button>
        ) : null}
      </Flex>
      <Button
        background={"transparent"}
        color={"button.red"}
        w={"100%"}
        h={"40px"}
        mt={"12px"}
      >
        Delete My Account
      </Button>
    </Box>
  );
};

export default Profile;
