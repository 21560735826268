import { createSlice } from "@reduxjs/toolkit";

const visitorMenuSlice = createSlice({
  name: "visitorMenu",

  initialState: {
    activeMenu: ""
  },

  reducers: {
    setActiveMenu: (state, action) => {
      state.activeMenu = action.payload.activeMenu
    },
  },
});

export const { setActiveMenu } = visitorMenuSlice.actions;

export const visitorMenuReducer = visitorMenuSlice.reducer;
