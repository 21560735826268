import { Box, Flex, Text, VStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

//// Icons
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { BsFillPersonFill, BsStars } from "react-icons/bs";
import { BiSolidLockAlt } from "react-icons/bi";
import { FaBoxOpen, FaHandshake } from "react-icons/fa";
import { IoLogOut } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { handleLogout } from "../../Utils/logout";
import { errorHandler } from "../../Utils/handler";
import { VisitorAPI } from "../../Apis/visitorAPI";
import { useToastWrapper } from "../../Wrapper/toastWrapper";
import { Modal } from "antd";
import LogoutWarningModal from "../../Components/Modals/LogoutWarningModal";
import { useDispatch } from "react-redux";
import actions from "../../redux/Actions";
import SupportModal from "../../Components/Modals/SupportModal";
import { MdOutlineSupportAgent } from "react-icons/md";
import { VisitorScreens } from "../../Constants/data";

const Account = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState<any>();

  const { success, error } = useToastWrapper();

  const dispatch = useDispatch();

  const [logoutModal, setLogoutModal] = useState(false);

  const handleLogoutUser = () => {
    dispatch(actions.user.logout());
    setLogoutModal(false);
    handleLogout();
    navigate("/login");
  };

  const handleCloseLogoutModal = () => {
    setLogoutModal(false);
  };

  const handleOpenLogoutModal = () => {
    setLogoutModal(true);
  };

  const [supportModal, setSupportModal] = useState(false);

  const handleOpenSupportModal = () => {
    setSupportModal(true);
  };

  const handleCloseSupportModal = () => {
    setSupportModal(false);
  };

  const getData = async () => {
    setLoading(true);
    try {
      const res = await VisitorAPI.getVisitorProfile();
      if (res) {
        console.log(res);
        const obj = {
          name: res.data.data?.visitorPersonName,
          companyName: res.data.data?.visitorCompanyName,
          designation: res.data.data?.visitorDesignation,
          workEmail: res.data.data?.visitorOfficialEmail,
          // socialEmail: res.data.data?.personEmail,
          phoneNumber: res.data.data?.visitorPhoneNumber,
          // companyWebsite: res.data.data?.companyUrl,
        };
        setData(obj);
      }
    } catch (err) {
      console.log(err);
      error(errorHandler(err));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
    dispatch(
      actions.visitorMenu.set({
        activeMenu: VisitorScreens.PROFILE
      })
    )
  }, []);

  return (
    <Box
      w={"100%"}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      minH={"100vh"}
      maxH={"100vh"}
    >
      <Modal
        closeIcon={null}
        footer={null}
        title={null}
        open={logoutModal}
        onOk={handleCloseLogoutModal}
        onCancel={handleCloseLogoutModal}
      >
        <LogoutWarningModal
          handleCloseModal={handleCloseLogoutModal}
          handleSubmit={handleLogoutUser}
        />
      </Modal>
      <Modal
        closeIcon={null}
        footer={null}
        title={null}
        open={supportModal}
        onOk={handleCloseSupportModal}
        onCancel={handleCloseSupportModal}
      >
        <SupportModal handleCloseModal={handleCloseSupportModal} />
      </Modal>
      <Flex mt={"30px"} alignItems={"center"} gap={4}>
        <Text fontSize={"lg"} fontWeight={500}>
          {data?.companyName || "Visitor Company Name"}
        </Text>
      </Flex>

      <Box mt={"auto"} w={"100%"}>
        <Text
          textAlign={"center"}
          fontSize={"lg"}
          fontWeight={500}
          color={"gray.700"}
          mt={2}
        >
          Account Info
        </Text>

        <Box
          mt={"20px"}
          w={"100%"}
          bg={"linear.100"}
          pt={"30px"}
          pb={"100px"}
          borderTopRadius={"40px"}
          px={"30px"}
        >
          <VStack spacing={0}>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"start"}
              py={"20px"}
              gap={4}
              w={"100%"}
              borderBottom={"1px solid #E2E8F0"}
              onClick={() => {
                navigate("/visitor/profile");
              }}
            >
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                h={"50px"}
                w={"50px"}
                bg={"#ffffff1a"}
                rounded={"15px"}
              >
                <BsFillPersonFill size={24} color={"white"} />
              </Box>
              <Text fontSize={"lg"} fontWeight={500} color={"white"}>
                My Profile
              </Text>
            </Box>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"start"}
              py={"20px"}
              gap={4}
              w={"100%"}
              borderBottom={"1px solid #E2E8F0"}
              onClick={() => {
                navigate("/visitor/rewards");
              }}
            >
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                h={"50px"}
                w={"50px"}
                bg={"#ffffff1a"}
                rounded={"15px"}
              >
                <BsStars size={24} color={"white"} />
              </Box>
              <Text fontSize={"lg"} fontWeight={500} color={"white"}>
                Rewards
              </Text>
            </Box>
            {/* <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"start"}
              py={"20px"}
              gap={4}
              w={"100%"}
              borderBottom={"1px solid #E2E8F0"}
              onClick={() => {
                navigate("/meetings");
              }}
            >
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                h={"50px"}
                w={"50px"}
                bg={"#ffffff1a"}
                rounded={"15px"}
              >
                <FaHandshake size={24} color={"white"} />
              </Box>
              <Text fontSize={"lg"} fontWeight={500} color={"white"}>
                Meetings
              </Text>
            </Box> */}

            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"start"}
              py={"20px"}
              gap={4}
              w={"100%"}
              borderBottom={"1px solid #E2E8F0"}
              onClick={() => {
                navigate("/reset-password");
              }}
            >
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                h={"50px"}
                w={"50px"}
                bg={"#ffffff1a"}
                rounded={"15px"}
              >
                <BiSolidLockAlt size={24} color={"white"} />
              </Box>
              <Text fontSize={"lg"} fontWeight={500} color={"white"}>
                Change Password
              </Text>
            </Box>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"start"}
              py={"20px"}
              gap={4}
              w={"100%"}
              borderBottom={"1px solid #E2E8F0"}
              onClick={() => {
                handleOpenSupportModal();
              }}
              //   borderBottom={"1px solid #E2E8F0"}
            >
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                h={"50px"}
                w={"50px"}
                bg={"#ffffff1a"}
                rounded={"15px"}
              >
                <MdOutlineSupportAgent size={24} color={"white"} />
              </Box>
              <Text fontSize={"lg"} fontWeight={500} color={"white"}>
                Support
              </Text>
            </Box>

            {/* <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"start"}
              py={"20px"}
              gap={4}
              w={"100%"}
              borderBottom={"1px solid #E2E8F0"}
            >
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                h={"50px"}
                w={"50px"}
                bg={"#ffffff1a"}
                rounded={"15px"}
              >
                <FaBoxOpen size={24} color={"white"} />
              </Box>
              <Text fontSize={"lg"} fontWeight={500} color={"white"}>
                Events
              </Text>
            </Box> */}

            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"start"}
              py={"20px"}
              gap={4}
              w={"100%"}
              //   borderBottom={"1px solid #E2E8F0"}
              onClick={() => handleOpenLogoutModal()}
            >
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                h={"50px"}
                w={"50px"}
                bg={"#ffffff1a"}
                rounded={"15px"}
                onClick={() => {
                  handleLogout();
                  navigate("/");
                }}
              >
                <IoLogOut size={24} color={"white"} />
              </Box>
              <Text fontSize={"lg"} fontWeight={500} color={"white"}>
                Logout
              </Text>
            </Box>
          </VStack>
        </Box>
      </Box>
    </Box>
  );
};

export default Account;
