import { Box, Button, Flex, Image, Text } from "@chakra-ui/react";
import React from "react";
import { Icons } from "../../Assets/icons";

type Props = {
  handleCloseModal: () => void;
  handleSubmit: () => void;
};

const LogoutWarningModal = (props: Props) => {
  return (
    <Box w={"100%"} h={"100%"}>
      <Box
        position={"relative"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        h={"60px"}
        w={"100%"}
      >
        {/* <Text
          fontSize={"20px"}
          fontWeight={700}
          textAlign={"center"}
          color={"black"}
        >
          Logout ?
        </Text> */}
        <Image
          onClick={() => {
            props.handleCloseModal();
          }}
          position={"absolute"}
          src={Icons.cross}
          right={0}
          top={0}
        />
      </Box>

      <Box>
        <Text fontSize={"md"} textAlign={"center"} fontWeight={"700"}>
          Logout from Connect ?
        </Text>
        <Text fontSize={"sm"} textAlign={"center"}>
          You will be redirected to the login page.
        </Text>
        <Flex alignItems={"center"} gap={3} mt={"25px"}>
          <Button
            onClick={() => {
              props.handleSubmit();
            }}
            flex={1}
            size={"md"}
            p={0}
            color={"gray.600"}
            bg={"transparent"}
            fontWeight={500}
          >
            Yes / Logout
          </Button>
          <Button
            flex={1}
            size={"md"}
            p={0}
            bg={"gray.100"}
            color={"gray.600"}
            onClick={() => {
              props.handleCloseModal();
            }}
          >
            No
          </Button>
        </Flex>
      </Box>
    </Box>
  );
};

export default LogoutWarningModal;
