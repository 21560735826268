import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { Icons } from "../../Assets/icons";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { AuthAPI } from "../../Apis/authAPI";
import Loader from "../../Components/Loader/Loader";
import { useToastWrapper } from "../../Wrapper/toastWrapper";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../redux/Actions";
import { Images } from "../../Assets/images";

type Props = {};

const Login = (props: Props) => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const [loading, setLoading] = React.useState(false);

  const { success, error } = useToastWrapper();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const user = useSelector((state: any) => state.user);

  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);

    try {
      const response = await AuthAPI.postLogin({
        email,
        password,
        role: "Admin",
      });
      console.log(response);
      const data = {
        token: response.data.token,
        user: response.data.data._id,
        role: response.data.data.role,
      };
      dispatch(actions.user.set(data));
      navigate("/admin");
      if (response.status) {
        success("login Successfull", "");
      }
    } catch (err) {
      console.log(err);
      error("Invalid login or password", "");
    } finally {
      setLoading(false);
    }
  };

  const submitRef = useRef<any>(null);

  useEffect(() => {
    const listener = (event: any) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        submitRef.current.click();
      }
    };
    document.addEventListener("keydown", listener);
    if (user && user?.token) {
      navigate(-1);
    }
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);
  return (
    <Flex
      w={"100vw"}
      h={"100vh"}
      justifyContent={"center"}
      alignItems={"center"}
      direction={"column"}
    >
      {loading ? <Loader /> : null}
      <Box width={"400px"} shadow={"md"} p={10}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Image src={Images.Logo} w={"200px"} alt="" />
          <Text
            fontWeight={"bold"}
            color={"primary.main"}
            fontSize={"32px"}
            textAlign={"center"}
          >
            Login
          </Text>
        </Box>
        <Box mt={"32px"}>
          <FormControl>
            <FormLabel>Email</FormLabel>
            <InputGroup>
              <InputLeftElement h={"50px"} pointerEvents="none">
                <Image src={Icons.LoginMail} alt="" />
              </InputLeftElement>
              <Input
                h={"50px"}
                type="email"
                placeholder="Enter Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </InputGroup>
          </FormControl>
          <FormControl mt={"20px"}>
            <FormLabel>password</FormLabel>
            <InputGroup>
              <InputLeftElement h={"50px"} pointerEvents="none">
                <Image src={Icons.LoginPassword} alt="" />
              </InputLeftElement>
              <Input
                h={"50px"}
                type={showPassword ? "text" : "password"}
                placeholder="Enter Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <InputRightElement h={"50px"}>
                {showPassword ? (
                  <AiFillEyeInvisible
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setShowPassword(false);
                    }}
                  />
                ) : (
                  <AiFillEye
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setShowPassword(true);
                    }}
                  />
                )}
              </InputRightElement>
            </InputGroup>
          </FormControl>
          <Flex alignItems={"center"} mt={"80px"} direction={"column"}>
            <Text>
              I accept these{" "}
              <Text
                as={"span"}
                cursor={"pointer"}
                onClick={() => {
                  // window.location.href =
                  //   "https://prontocommerce.com/eula-after";
                  window.open(
                    "https://prontocommerce.com/eula-after",
                    "_blank"
                  );
                }}
                color={"blue"}
              >
                terms and conditions
              </Text>
            </Text>
            <Button
              ref={submitRef}
              onClick={handleSubmit}
              mt={4}
              width={"full"}
              h={"60px"}
              alignItems={"center"}
              justifyContent={"center"}
              borderRadius={"16px"}
              display={"flex"}
              background={"primary.main"}
              color={"white"}
              _hover={{ background: "primary.main" }}
            >
              Sign In
            </Button>
          </Flex>
        </Box>
      </Box>
    </Flex>
  );
};

export default Login;
