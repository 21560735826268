import React from "react";
import { Pagination as Pag } from "antd";

type Props = {
  total: number;
  current: number;
  onChange: (page: number) => void;
  pageSize: number;
};

const Pagination = (props: Props) => {
  return (
    <Pag
      showSizeChanger={false}
      current={props.current}
      onChange={props.onChange}
      total={props.total}
      pageSize={props.pageSize}
    />
  );
};

export default Pagination;
