import { createSlice } from "@reduxjs/toolkit";

const exhibitorAssetSlice = createSlice({
  name: "exhibitorAsset",

  initialState: {
    links: [],
    link:'',
    title:''
  },

  reducers: {
    setAssets: (state, action) => {
        state.links = action.payload.links;
    },
    setLink: (state, action) => {
      state.link = action.payload.link;
    },    
    setTitle: (state, action) => {
      state.title = action.payload.title;
    },resetForm: (state, action) => {
      state.link = ''
      state.title = ''
    }
    },
});

export const { setAssets, setLink, setTitle, resetForm } = exhibitorAssetSlice.actions;

export const exhibitorAssetReducer = exhibitorAssetSlice.reducer;
