import { Box, Button, Flex, FormControl, FormLabel, Image, Input, InputGroup, InputLeftElement, InputRightElement, Text } from "@chakra-ui/react";
import React from "react";
import Header from "../../Components/Common/Header";
import { Icons } from "../../Assets/icons";
import { Images } from "../../Assets/images";

type Props = {};

const CreateMeeting = (props: Props) => {
  return (
    <Box width={"100%"} minH={"100vh"} display={'flex'} p={6} flexDirection={'column'}>
      <Box width={"100%"} mt={"30px"}>
        <Header backButton>Create Meeting Request</Header>
      </Box>
      <Flex flexGrow={'1'} direction={'column'} justifyContent={'center'}>
        <Flex justifyContent={'center'} >
            <Image src={Images.schedule} alt="schedule" width={'80px'}/>
        </Flex>
        <Text
            fontSize={"18px"}
            fontWeight={"semibold"}
            mt={3}
            textAlign={"center"}
        >
            meeting request to Company Name
        </Text>
        <FormControl mt={10}>
            <FormLabel>Date</FormLabel>
            <InputGroup>
            
            <Input
                h={"50px"}
                type="date"
                placeholder="Choose Date"
            />
            </InputGroup>
        </FormControl>
        <FormControl mt={5}>
            <FormLabel>Time</FormLabel>
            <InputGroup>
            
            <Input
                h={"50px"}
                type="time"
                placeholder="Choose Date"
            />
            </InputGroup>
        </FormControl>
        <Flex alignItems={'center'} gap={3} mt={'25px'}>
                <Button  flex={1} size={'md'} p={0} bg={'secondary.light'} color={'secondary.main'}>
                    Send
                </Button>
                <Button flex={1} size={'md'} p={0} bg={'gray.100'} color={'gray.600'}>
                    Cancel
                </Button>
        </Flex>
      </Flex>
    </Box>
  );
};

export default CreateMeeting;
