import { Box, Button, Flex, Input, Text, VStack } from "@chakra-ui/react";
import React, { useEffect } from "react";

import { Select, Tooltip, notification } from "antd";

//// Icons

import { FaAngleLeft } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import NoNotifications from "../../Components/Common/NoNotifications";
import { useSelector } from "react-redux";
import moment from "moment";
import { CommonAPI } from "../../Apis/commonApi";
import Loader from "../../Components/Loader/Loader";
import { CiCircleQuestion } from "react-icons/ci";

const Notification = () => {
  const navigate = useNavigate();

  const [data, setData] = React.useState<any>([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);

  const {
    notification: { notifications },
  } = useSelector((state: any) => state);
  const { loading } = useSelector((state: any) => state.notification);

  const markNotificationAsRead = async () => {
    try {
      const res = await CommonAPI.markAllNotificationsAsRead();
      if (res.success) {
        console.log("notification marked as read");
      }
    } catch (err) {
      console.log("Error marking notification as read", err);
    }
  };

  useEffect(() => {
    markNotificationAsRead();
  }, []);

  return (
    <Box
      w={"100%"}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      //   bg={"linear.100"}
      minH={"100vh"}
      maxH={"100vh"}
    >
      {/* {
        loading?<Loader/>:null
      } */}
      <VStack w={"100%"} px={"30px"}>
        <Flex
          mt={"30px"}
          alignItems={"center"}
          justifyContent={"space-between"}
          w={"100%"}
          gap={"20px"}
        >
          <Button
            w={"40px"}
            h={"40px"}
            borderRadius={"10px"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            bg={"transparent"}
            border={"1px solid black"}
            fontSize={"20px"}
            onClick={() => {
              navigate(-1);
            }}
          >
            <span>
              <FaAngleLeft size={22} color="black" />
            </span>
          </Button>
          <VStack w={"100%"} textAlign={"left"} alignItems={"flex-start"}>
            <Text fontSize={"lg"} fontWeight={600} color={"black"}>
              Notifications
            </Text>
          </VStack>
        </Flex>
      </VStack>

      <Box
        w={"100%"}
        bg={"white"}
        pt={"30px"}
        // marginTop={"auto"}
        h={"100%"}
        flex={1}
        marginTop={"30px"}
        minH={"100%"}
        overflowY={"auto"}
        overflowX={"hidden"}
        pb={"100px"}
        borderTopRadius={"40px"}
        px={"30px"}
      >
        <VStack w={"100%"} spacing={4}>
          {notifications?.length > 0 &&
            [...notifications].map((item) => (
              <Box
                w={"100%"}
                // border={"1px solid #E2E8F0"}
                p={"15px 20px"}
                borderRadius={"10px"}
                shadow={"md"}
                display={"flex"}
                flexDirection={"column"}
                _hover={{
                  shadow: "lg",
                }}
                position={"relative"}
                onClick={() => {
                  const status =
                    item?.meetingRequestId == item?.userId
                      ? item?.meetingRequesterStatus
                      : item?.meetingResponderStatus;
                  if (item?.type === "meeting-request") {
                    navigate("/meetings?status=" + status);
                  }
                }}
              >
                <Text fontWeight={600}>{item.title}</Text>
                {/* <Flex>
                  <Text fontSize={"sm"}>You have recieved a message</Text>
                </Flex> */}
                <Flex w={"100%"} justifyContent={"flex-end"} mt={"10px"}>
                  <Box onClick={(e) => e.stopPropagation()}>
                    <Tooltip
                      title={
                        "Notification received on " +
                        moment(item.createdAt).format("MMMM Do YYYY, HH:mm a")
                      }
                    >
                      <CiCircleQuestion
                        style={{ height: "16px", width: "16px" }}
                        cursor={"pointer"}
                      />
                    </Tooltip>
                  </Box>
                </Flex>
              </Box>
            ))}

          {/* No Notification screen */}

          {data?.length === 0 && <NoNotifications />}
        </VStack>
      </Box>
    </Box>
  );
};

export default Notification;
