import { Box, Text, VStack } from "@chakra-ui/react";
import React from "react";
import Header from "../../Components/Common/Header";

type Props = {};

const RewardsStatement = (props: Props) => {
  return (
    <Box flexGrow={1} p={6}>
      <Header backButton>Rewards Statement</Header>
      <VStack>
        {[0, 0, 0, 0, 0, 0].map(() => (
          <Box
            w={"100%"}
            bg={"white"}
            p={3}
            rounded={"18px"}
            mt={3}
            px={5}
            py={4}
            display={"flex"}
            gap={4}
            justifyContent={"space-between"}
            alignItems={"start"}
            shadow={"md"}
            minH={"95px"}
          >
            <Box>
              <Text
                fontSize={"15px"}
                fontWeight={"semibold"}
                color={"gray.600"}
              >
                12 Dec 2023
              </Text>
              <Text fontSize={"12px"} mt={2} maxW={"200px"}>
                Rewards points Redeemed FAS788FUS345
              </Text>
            </Box>
            <Text fontSize={"14px"} color={"red"}>
              -100
            </Text>
          </Box>
        ))}
      </VStack>
    </Box>
  );
};

export default RewardsStatement;
