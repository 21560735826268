import { Box, Button, Flex, Input, Text, VStack } from "@chakra-ui/react";
import React from "react";

import { Select } from "antd";

//// Icons

import { BsSendFill } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import Header from "../../Components/Common/Header";

const Chat = () => {
  return (
    <Box
      w={"100%"}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      bg={"linear.100"}
      minH={"100vh"}
      maxH={"100vh"}
    >
      <VStack w={"100%"} px={"30px"}>
        {/* <Flex
          mt={"30px"}
          alignItems={"center"}
          justifyContent={"space-between"}
          w={"100%"}
          gap={"20px"}
        >
          <VStack w={"100%"} textAlign={"center"} alignItems={"center"}>
            <Text fontSize={"lg"} fontWeight={600} color={"#fff"}>
              Company Name
            </Text>
          </VStack>
        </Flex> */}
        <Box mt={"30px"} w={"100%"}>
          <Header backButton isWhite>
          Company Name
          </Header>
        </Box>

        <Box
          w={"100%"}
          bg={"white"}
          mt={"30px"}
          // border={"1px solid #E2E8F0"}
          p={"15px 20px"}
          borderRadius={"10px"}
          shadow={"md"}
          display={"flex"}
          flexDirection={"column"}
          _hover={{
            shadow: "lg",
          }}
          position={"relative"}
        >
          <Text fontWeight={600}>Employee Name</Text>
          <Flex>
            <Text fontSize={"sm"}>12.03.2023 19:03</Text>
          </Flex>
          <Flex>
            <Text fontSize={"sm"}>Divisional Manager</Text>
          </Flex>

          <Button
            position={"absolute"}
            top={0}
            right={0}
            colorScheme="transparent"
          >
            <span>
              <FiEdit size={22} color="#000" />
            </span>
          </Button>
        </Box>
      </VStack>

      <Box
        w={"100%"}
        bg={"white"}
        h={"100%"}
        flex={1}
        marginTop={"30px"}
        minH={"100%"}
        maxH={"100%"}
        overflowY={"auto"}
        position={"relative"}
        borderTopRadius={"40px"}
        px={"30px"}
      >
        <Box
          bg={"white"}
          pt={"20px"}
          pb={"15px"}
          zIndex={200}
          borderBottom={"2px solid #E2E8F0"}
          position={"sticky"}
          top={0}
          left={0}
          w={"100%"}
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Text fontSize={"lg"} fontWeight={600}>
            Conversations
          </Text>
          <Button
            p={"10px 15px"}
            bg={"#F5793D1a"}
            color={"#F5793D"}
            rounded={"12px"}
          >
            Close / Reconnect
          </Button>
        </Box>

        <VStack pb={"100px"} pt={"20px"} px={"5px"} spacing={4}>
          {[1, 2, 3, 4, , 5, 6, 7, 8]?.map((item: any) => {
            return item % 2 == 0 ? (
              <Box
                w={"80%"}
                mr={"auto"}
                bg={"gray.100"}
                p={"15px 20px"}
                display={"flex"}
                flexDirection={"column"}
                _hover={{
                  shadow: "lg",
                }}
                position={"relative"}
              >
                <Text fontSize={"sm"} fontWeight={400} color={"gray.500"}>
                  05/04/2023 08:50
                </Text>
                <Text mt={"10px"} fontSize={"md"}>
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Aliquam voluptas labore laborum explicabo ipsum iusto ratione
                  quisquam eveniet id optio veritatis, animi voluptatibus quis
                  aperiam assumenda hic eos laudantium facere.
                </Text>
              </Box>
            ) : (
              <Box
                display={"flex"}
                flexDirection={"column"}
                gap={"10px"}
                w={"50%"}
                ml={"auto"}
                alignItems={"flex-end"}
              >
                <Text fontSize={"sm"} fontWeight={400} color={"gray.500"}>
                  05/04/2023 08:50
                </Text>

                <Box
                  bg={"#7C66FF"}
                  p={"10px 15px"}
                  borderBottomRadius={"20px"}
                  borderTopLeftRadius={"20px"}
                  borderTopRightRadius={"4px"}
                >
                  <Text color={"#fff"}>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  </Text>
                </Box>
              </Box>
            );
          })}
        </VStack>

        <Box
          bg={"gray.200"}
          py={"10px"}
          zIndex={200}
          borderBottom={"1px solid #E2E8F0"}
          position={"fixed"}
          bottom={0}
          left={0}
          w={"100%"}
          px={"30px"}
        >
          <Flex alignItems={"center"} justifyContent={"space-between"}>
            <Input
              placeholder={"Type a message"}
              border={"0px"}
              outline={"none"}
              _focus={{ outline: "none", border: "0px" }}
              // bg={"white"}
              h={"50px"}
              w={"100%"}
              px={2}
              _placeholder={{
                color: "gray.500",
                fontWeight: "400",
                fontSize: "16px",
              }}
            />

            <Button
              bg={"gray.300"}
              h={"50px"}
              w={"50px"}
              rounded={"50%"}
              shadow={"md"}
            >
              <span>
                <BsSendFill size={22} color={"black"} />
              </span>
            </Button>
          </Flex>
        </Box>
      </Box>
    </Box>
  );
};

export default Chat;
