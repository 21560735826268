import { Box, Text } from "@chakra-ui/react";
import Header from "../../Components/Common/Header";
import { useNavigate, useParams } from "react-router-dom";

type Props = {};

const RewardsRedemption = (props: Props) => {
  const navigate = useNavigate();
  const { redemptionId } = useParams();
  const onClosePopUp = () => {
    navigate(`/visitor/rewards`);
  }
  return (
    <Box flexGrow={1} p={6} w={"100%"}>
      <Header backButton onClosePopUp={onClosePopUp}>{""}</Header>
      <Text
        textAlign={"center"}
        fontSize={"16px"}
        fontWeight={"bold"}
        mt={10}
        color={"black"}
      >
        Your rewards redemption request is received.
      </Text>
      <Text
        textAlign={"center"}
        fontSize={"16px"}
        fontWeight={"bold"}
        mt={"130px"}
        color={"black"}
      >
        Your request redemption Id is {redemptionId}
      </Text>
      <Text
        textAlign={"center"}
        fontSize={"12px"}
        fontWeight={"medium"}
        mt={2}
        color={"black"}
      >
        For any queries, email{" "}
        <a
          style={{ color: "#F5793D" }}
          href="mailto:connect@prontocommerce.com"
        >
          connect@prontocommerce.com
        </a>
      </Text>
    </Box>
  );
};

export default RewardsRedemption;
