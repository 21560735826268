import { Box, Flex, IconButton, Image } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import MobileLayout from "../MobileLayout/MobileLayout";

import { BiBarcodeReader, BiSolidHome } from "react-icons/bi";
import { HiOutlineDocumentDuplicate } from "react-icons/hi";
import { BsFillPersonFill } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";
import { Icons } from "../../../Assets/icons";
import { useDispatch, useSelector } from "react-redux";
import { useToastWrapper } from "../../../Wrapper/toastWrapper";
import { CommonAPI } from "../../../Apis/commonApi";
import actions from "../../../redux/Actions";
import { act } from "react-dom/test-utils";
import CustomIconButton from "../../Common/CustomIconButton";
import { VisitorScreens } from "../../../Constants/data";

import {
  MdFormatListBulleted,
  MdOutlinePerson3,
  MdPerson3,
  MdOutlineQrCodeScanner,
  MdOutlineCalendarMonth,
  MdCalendarMonth,
  MdOutlineFormatListBulleted,
  MdQrCodeScanner,
} from "react-icons/md";
import { GoHome, GoHomeFill } from "react-icons/go";

type Props = {
  children: React.ReactNode;
};

const noFooter = [
  "/visitor/company",
  "/visitor/chat",
  "/visitor/notifications",
];

const VisitorLayout = ({ children }: Props) => {
  const location = useLocation();

  const navigate = useNavigate();

  const { visitorMenu } = useSelector((state: any) => state);

  const { user } = useSelector((state: any) => state);
  const { error, info } = useToastWrapper();

  const [authState, setAuthState] = useState(false);

  useEffect(() => {
    if (user && user?.role === "Visitor") {
      setAuthState(true);
    } else {
      navigate("/login");
    }
  }, [user]);

  const fetchNotification = async () => {
    try {
      // dispatch(actions.notification.setLoading(true));
      const res = await CommonAPI.getNotifications();
      if (res.success) {
        const { notifications, unread } = res.data.data;

        dispatch(
          actions.notification.get({
            notifications,
            unreadNotifications: unread,
          })
        );
      }
    } catch (err) {
      console.log("Error getting notification", err);
    } finally {
      // dispatch(actions.notification.setLoading(false));
    }
  };

  const dispatch = useDispatch();

  useEffect(() => {
    const interval = setInterval(() => {
      fetchNotification();
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const MenuItemClick = (screenName: string) => {
    dispatch(
      actions.visitorMenu.set({
        activeMenu: screenName,
      })
    );
  };

  return (
    <>
      <Flex
        maxW={"600px"}
        w="100%"
        position={"relative"}
        flexDirection="column"
        alignSelf={"center"}
        mx={"auto"}
        display={"flex"}
        minH={"100vh"}
        maxH={"100vh"}
        overflow={"auto"}
      >
        <Box
          flexGrow={1}
          overflow={"auto"}
          pb={"20px"}
          display={"flex"}
          mb={"65px"}
        >
          {authState && children}
        </Box>
        {noFooter.includes(location.pathname) ? null : (
          // <Box
          //   w={"100%"}
          //   bg={"white"}
          //   py={"30px"}
          //   shadow={"0px 0px 10px rgba(0, 0, 0, 0.1)"}
          //   border={"1px solid #E2E8F0"}
          //   // borderTopRadius={"40px"}
          // >
          //   <Flex
          //     maxW={"400px"}
          //     mx={"auto"}
          //     justifyContent={"space-between"}
          //     px={"50px"}
          //   >
          //     <IconButton
          //       aria-label="Home"
          //       icon={<BiSolidHome size={30} color={"black"} />}
          //       onClick={() => {
          //         navigate("/visitor");
          //       }}
          //       colorScheme="transparent"
          //     />

          //     <IconButton
          //       aria-label="Home"
          //       icon={
          //         <BiBarcodeReader
          //           style={{ color: "green", width: "30px", height: "30px" }}
          //         />
          //       }
          //       onClick={() => {
          //         navigate("/visitor/scan");
          //       }}
          //       colorScheme="transparent"
          //     />

          //     {/* <IconButton
          //       aria-label="Home"
          //       icon={
          //         <HiOutlineDocumentDuplicate
          //           style={{ color: "black", width: "30px", height: "30px" }}
          //         />
          //       }
          //       onClick={() => {
          //         navigate("/visitor/rfp");
          //       }}
          //       colorScheme="transparent"
          //     /> */}

          //     <IconButton
          //       aria-label="Profile"
          //       icon={<BsFillPersonFill size={30} color={"black"} />}
          //       onClick={() => {
          //         navigate("/visitor/myaccount");
          //       }}
          //       colorScheme="transparent"
          //     />
          //   </Flex>
          // </Box>
          <Box
            // w={"100%"}
            bg={"white"}
            shadow={"0px 0px 10px rgba(0, 0, 0, 0.1)"}
            border={"1px solid #E2E8F0"}
            // borderTopRadius={"40px"}
            style={{
              position: "fixed",
              bottom: 0,
              width: "100%",
              maxWidth: "600px",
            }}
          >
            <Flex h={"65px"}>
              <CustomIconButton
                buttonBg={
                  visitorMenu?.activeMenu == VisitorScreens.HOME
                    ? "primary.main"
                    : "transparent"
                }
                icon={
                  visitorMenu?.activeMenu == VisitorScreens.HOME ? (
                    <GoHomeFill
                      size={30}
                      color={"#FF5F12"}
                      fontWeight={"light"}
                    />
                  ) : (
                    <GoHome size={30} color={"#1C1B1F"} fontWeight={"light"} />
                  )
                }
                onClick={() => {
                  MenuItemClick(VisitorScreens.HOME);
                  navigate("/visitor");
                }}
              />
              <CustomIconButton
                buttonBg={
                  visitorMenu?.activeMenu == VisitorScreens.DETAILS
                    ? "primary.main"
                    : "transparent"
                }
                icon={
                  visitorMenu?.activeMenu == VisitorScreens.DETAILS ? (
                    <MdFormatListBulleted
                      size={30}
                      color={"#FF5F12"}
                      fontWeight={"light"}
                    />
                  ) : (
                    <MdOutlineFormatListBulleted
                      size={30}
                      color={"#1C1B1F"}
                      fontWeight={"light"}
                    />
                  )
                }
                onClick={() => {
                  MenuItemClick(VisitorScreens.DETAILS);
                  navigate("/visitor/list");
                }}
              />
              <CustomIconButton
                buttonBg={
                  visitorMenu?.activeMenu == VisitorScreens.CALENDAR
                    ? "primary.main"
                    : "transparent"
                }
                icon={
                  visitorMenu?.activeMenu == VisitorScreens.CALENDAR ? (
                    <MdCalendarMonth
                      size={30}
                      color={"#FF5F12"}
                      fontWeight={"light"}
                    />
                  ) : (
                    <MdOutlineCalendarMonth
                      size={30}
                      color={"#1C1B1F"}
                      fontWeight={"light"}
                    />
                  )
                }
                onClick={() => {
                  MenuItemClick(VisitorScreens.CALENDAR);
                  navigate("/visitor/calendar");
                  // info("Feature coming soon!","");
                }}
              />
              <CustomIconButton
                buttonBg={
                  visitorMenu?.activeMenu == VisitorScreens.SCAN
                    ? "primary.main"
                    : "transparent"
                }
                icon={
                  visitorMenu?.activeMenu == VisitorScreens.SCAN ? (
                    <MdQrCodeScanner
                      size={30}
                      color={"#FF5F12"}
                      fontWeight={"light"}
                    />
                  ) : (
                    <MdOutlineQrCodeScanner
                      size={30}
                      color={"#1C1B1F"}
                      fontWeight={"light"}
                    />
                  )
                }
                onClick={() => {
                  MenuItemClick(VisitorScreens.SCAN);
                  navigate("/visitor/scan");
                }}
              />
              <CustomIconButton
                buttonBg={
                  visitorMenu?.activeMenu == VisitorScreens.PROFILE
                    ? "primary.main"
                    : "transparent"
                }
                icon={
                  visitorMenu?.activeMenu == VisitorScreens.PROFILE ? (
                    <MdPerson3
                      size={30}
                      color={"#FF5F12"}
                      fontWeight={"light"}
                    />
                  ) : (
                    <MdOutlinePerson3
                      size={30}
                      color={"#1C1B1F"}
                      fontWeight={"light"}
                    />
                  )
                }
                onClick={() => {
                  MenuItemClick(VisitorScreens.PROFILE);
                  navigate("/visitor/myaccount");
                }}
              />
            </Flex>
          </Box>
        )}
      </Flex>
    </>
  );
};

export default VisitorLayout;
