import { Box } from "@chakra-ui/react"
import React, { MouseEvent } from "react";

type Props = {
    onClick: any;
    icon: React.ReactNode;
    buttonBg: string;
}
const CustomIconButton = (props: Props) => {
    return (
        <Box 
            w={"20%"}
            display={"flex"} 
            alignItems={"center"} 
            justifyContent={"center"} 
            // _hover={{ backgroundColor: "icon.hover" }} 
            onClick={props.onClick}
            cursor={"pointer"}
            bg={props.buttonBg}
        >
            {props.icon}
        </Box>
    )
}

export default CustomIconButton