import { Box, Button, Flex, Input, Text, VStack } from "@chakra-ui/react";
import React, { useState } from "react";

import { Modal, Select } from "antd";

//// Icons
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { IoMdNotificationsOutline } from "react-icons/io";

import { TfiWorld } from "react-icons/tfi";
import { BiSearch, BiSort } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import SortModal from "../../Components/Modals/SortModal";

const OrganizerHome = () => {
  const navigate = useNavigate();

  const [sortModal, setSortModal] = useState(false);
  const handleOpenSortModal = () => setSortModal(true);
  const handleCloseSortModal = () => setSortModal(false); 
  return (
    <Box
      w={"100%"}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      minH={"100vh"}
      maxH={"100vh"}
      px={"30px"}
    >

       {/* Modal for sort */}
       <Modal closeIcon={null} footer={null} title={null} open={sortModal} onOk={handleCloseSortModal} onCancel={handleCloseSortModal}>
        {/* <SortModal handleCloseModal={handleCloseSortModal}/> */}
      </Modal>


      <Flex
        mt={"30px"}
        alignItems={"center"}
        justifyContent={"space-between"}
        w={"100%"}
      >
        <VStack w={"100%"} textAlign={"left"} alignItems={"flex-start"}>
          <Text fontSize={"sm"} fontWeight={400}>
            Welcome Back
          </Text>
          <Text fontSize={"lg"} fontWeight={500}>
            Hello! Organizer Name 👋🏻
          </Text>
        </VStack>
        <Button
          w={"40px"}
          h={"40px"}
          borderRadius={"10px"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          bg={"gray.100"}
          fontSize={"20px"}
          onClick={() => {
            navigate("/organizer/notifications");
          }}
        >
          <span>
            <IoMdNotificationsOutline size={22} />
          </span>
        </Button>
      </Flex>

      <VStack w={"100%"} mt={"30px"} spacing={2}>
        <Flex
          border={"1px solid #E2E8F0"}
          borderRadius={"10px"}
          padding={"0 15px"}
          w={"100%"}
          alignItems={"center"}
        >
          <TfiWorld size={22} />
          <Select
            className="custom-select-noborder"
            showSearch
            style={{
              width: "100%",
              border: "0px",
              outline: "none",
              height: "50px",
            }}
            placeholder="Event Name"
            optionFilterProp="children"
            options={[]}
          />
        </Flex>

        <Flex
          border={"1px solid #E2E8F0"}
          borderRadius={"10px"}
          padding={"0 15px"}
          w={"100%"}
          alignItems={"center"}
        >
          <BiSearch size={22} />
          <Input
            placeholder="Company Name"
            type="text"
            _placeholder={{
              color: "gray.500",
              fontWeight: "400",
              fontSize: "16px",
            }}
            border={"0px"}
            outline={"none"}
            _focus={{ outline: "none", border: "0px" }}
            h={"50px"}
            w={"100%"}
            px={2}
            borderColor={"gray.300"}
          />
        </Flex>
      </VStack>

      <Flex
        mt={"30px"}
        w={"100%"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Text fontSize={"md"} fontWeight={500}>
          1998 companies found
        </Text>
        <BiSort size={22} onClick={handleOpenSortModal}/>
      </Flex>

      <Box
        w={"100%"}
        mt={"30px"}
        h={"100%"}
        overflowY={"auto"}
        overflowX={"hidden"}
        display={"flex"}
        flexDirection={"column"}
        gap={"20px"}
        pb={"100px"}
      >
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, index) => (
          <Box
            p={"15px"}
            border={"1px solid #E2E8F0"}
            borderRadius={"15px"}
            shadow={"sm"}
            cursor={"pointer"}
            onClick={() => {
              navigate("/organizer/company");
            }}
          >
            <Text>Company Name {index}</Text>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default OrganizerHome;
