import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",

  initialState: {
    user: null,
    role: null,
    token: null,
    isLoggedIn: false,
  },

  reducers: {
    setUser: (state, action) => {
      state.user = action.payload.user;
      state.role = action.payload.role;
      state.token = action.payload.token;
      state.isLoggedIn = true;
    },
    logout: (state: any) => {
      state.user = null;
      state.role = null;
      state.token = null;
      state.isLoggedIn = false;
    },
    changeToken: (state, action) => {
      state.token = action.payload.token;
    },
  },
});

export const { setUser, logout, changeToken } = userSlice.actions;

export const reducer = userSlice.reducer;
