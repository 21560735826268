import { errorResponseHandler, responseHandler } from "../Utils/handler.ts";
import { api } from "./config/axiosConfig.js";
import { defineCancelApiObject } from "./config/axiosUtils.js";
import { store } from "../redux/Store.ts";

export const AuthAPI = {
  postRegister: async (register, cancel = false) => {
    const response = await api.request({
      url: "/auth/register/",
      method: "POST",
      data: register,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },

  postLogin: async (login, cancel = false) => {
    const response = await api.request({
      url: "/auth/login/",
      method: "POST",
      data: login,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    if (response.data?.code == 200) {
      response.data.success = true;
    }

    return response.data;
  },

  postForgetPassword: async (data, cancel = false) => {
    const response = await api.request({
      url: "/auth/forgot-password",
      method: "POST",
      data: data,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },

  createPassword: async (id, data, cancel = false) => {
    const response = await api.request({
      url: "/auth/reset-password/" + id,
      method: "POST",
      data: data,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },

  patchRefreshToken: async (token, cancel = false) => {
    try {
      const response = await api.request({
        url: "/auth/refresh-token",
        method: "PATCH",
        headers: { Authorization: `Bearer ${token}` },
        signal: cancel
          ? cancelApiObject[this.get.name].handleRequestCancellation().signal
          : undefined,
      });

      return responseHandler(response);
    } catch (err) {
      return errorResponseHandler(err);
    }
  },

  postUpdatePassword: async (data, cancel = false) => {
    const { token } = await store.getState().user;
    const response = await api.request({
      url: "/auth/password/update",
      method: "POST",
      data: data,
      headers: { Authorization: `Bearer ${token}` },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
};

const cancelApiObject = defineCancelApiObject(AuthAPI);
