import { Box, Button, Flex, Image, Text, VStack } from "@chakra-ui/react";
import React, { useState } from "react";
import { Icons } from "../../Assets/icons";
import { relevanceFields } from "../../Constants/data";

type Props = {
  handleCloseModal: () => void;
  orderSort?: string;
  setOrderSort: (val: string) => void;
};

const SortModal = (props: Props) => {
  const [scanTIme, setScanTime] = useState(1)

  

  console.log(scanTIme);
  
  return (
    <Box w={"100%"} h={"100%"}>
      <Box
        position={"relative"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        h={"60px"}
      >
        <Text
          fontSize={"20px"}
          fontWeight={700}
          textAlign={"center"}
          color={"black"}
        >
          Sort By
        </Text>
        <Image
          onClick={() => {
            props.handleCloseModal();
          }}
          position={"absolute"}
          src={Icons.cross}
          left={0}
          top={0}
        />
      </Box>

      <VStack gap={"18px"}>
        <Box px={2} py={3} bg={"gray.50"} borderRadius={"10px"} w={"100%"}>
          <Text fontWeight={600} fontSize={"md"}>
            Company Name
          </Text>
          <Flex gap={"30px"} mt={"10px"}>
            <Button
              bg={props.orderSort=='a-to-z'?'primary.main':"white"}
              color={props.orderSort=='a-to-z'?"white":"primary.main"}
              border={"1px solid"}
              borderColor={"primary.main"}
              size={"sm"}
              onClick={()=>{
                if(props.orderSort=='a-to-z'){
                  // props.setOrderSort('')
                }
                else{
                  props.setOrderSort('a-to-z')
                  props.handleCloseModal()
                }
              }}
            >
              A to Z
            </Button>
            <Button
              bg={props.orderSort=='z-to-a'?'primary.main':"white"}
              color={props.orderSort=='z-to-a'?"white":"primary.main"}
              border={"1px solid"}
              borderColor={"primary.main"}
              size={"sm"}
              onClick={()=>{
                if(props.orderSort=='z-to-a'){
                  // props.setOrderSort('')
                }
                else{
                  props.setOrderSort('z-to-a')
                  props.handleCloseModal()
                }
              }}
            >
              Z to A
            </Button>
          </Flex>
        </Box>
        <Box px={2} py={3} bg={"gray.50"} borderRadius={"10px"} w={"100%"}>
          <Text fontWeight={600} fontSize={"md"}>
            Scan Time
          </Text>
          <Flex gap={"30px"} mt={"10px"}>
            <Button
              bg={scanTIme===1?'primary.main':"white"}
              color={scanTIme===1?"white":"primary.main"}
              border={"1px solid"}
              borderColor={"primary.main"}
              size={"sm"}
              onClick={()=>{
                console.log('clicked 1');
                if(scanTIme===2){
                  setScanTime(1)
                }
              }}
            >
              Most Recent
            </Button>
            <Button
             bg={scanTIme===2?'primary.main':"white"}
             color={scanTIme===2?"white":"primary.main"}
             border={"1px solid"}
             borderColor={"primary.main"}
             size={"sm"}
             onClick={()=>{
              console.log('clicked 2');
              
               if(scanTIme===1){
                 setScanTime(2)
               }
             }}
            >
              Oldest
            </Button>
          </Flex>
        </Box>
        <Box px={2} py={3} bg={"gray.50"} borderRadius={"10px"} w={"100%"}>
            <Flex alignItems={'center'} gap={1}>

          <Text fontWeight={600} fontSize={"md"}>
            Connect
          </Text>
          <Text fontSize={'sm'}>
            (Most Recent on Top)
          </Text>
            </Flex>
        </Box>
      </VStack>
    </Box>
  );
};

export default SortModal;
