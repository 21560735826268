import { Box, Button, Flex, Image, Input, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Icons } from "../../Assets/icons";
import { Images } from "../../Assets/images";
import Loader from "../../Components/Loader/Loader";
import { ExhibitorAPI } from "../../Apis/exhibitorAPI";
import { useToastWrapper } from "../../Wrapper/toastWrapper";
import { errorHandler } from "../../Utils/handler";
import { useDispatch, useSelector } from "react-redux";
import { ExhibitorScreens, relevanceFields } from "../../Constants/data";
import actions from "../../redux/Actions";
import { timeElapsed } from "../../Utils/commonUtilFunctions";

type Props = {};

const Announcements = (props: Props) => {
  const navigate = useNavigate();

  const [inputs, setInputs] = useState({
    announcement: "",
  });

  const { user } = useSelector((state: any) => state.user);

  const dispatch = useDispatch();

  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(false);

  const { success, error } = useToastWrapper();

  const [sendTo, setSendTo] = useState<String[]>([]);

  const getData = async () => {
    try {
      setLoading(true);
      const res = await ExhibitorAPI.GetAnnouncement();
      if (res) {
        setData(res.data.data);
      }
    } catch (err) {
      error(errorHandler(err), "");
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    if (!inputs.announcement) return error("Please enter announcement", "");
    if (!sendTo.length) return error("Please select atleast one Relevance", "");

    try {
      setLoading(true);
      const obj = {
        text: inputs.announcement,
        sendTo,
      };

      console.log(obj);

      const res = await ExhibitorAPI.PostAnnouncement(obj);
      if (res) {
        success("Announcement Sent", "");
        setInputs({ announcement: "" });
        setSendTo([]);
        getData();
      }
    } catch (err) {
      error(errorHandler(err), "");
    } finally {
      setLoading(false);
    }
  };

  const navigateBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    getData();
    dispatch(
      actions.exhibitorMenu.set({
        activeMenu: ExhibitorScreens.ANNOUNCEMENTS,
      })
    );
  }, []);
  return (
    <Box
      w={"100%"}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      bg={"#FBFBFB"}
      minH={"100vh"}
      p={6}
      maxH={"100vh"}
    >
      {loading ? <Loader /> : null}
      {/* Header */}
      <Box w={"100%"} mt={"30px"}>
        <Box
          position={"relative"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Flex
            justifyContent={"center"}
            alignItems={"center"}
            direction={"column"}
          >
            <Image width={"60px"} src={Images.Annoucements} />
            <Text
              fontSize={"20px"}
              fontWeight={700}
              textAlign={"center"}
              color={"black"}
            >
              Announcements
            </Text>
          </Flex>

          <Image
            onClick={navigateBack}
            position={"absolute"}
            src={Icons.BackButton}
            left={0}
            top={0}
          />
        </Box>
      </Box>

      {/* Input Card */}
      <Box
        background={"white"}
        borderRadius={"32px"}
        px={"24px"}
        py={"30px"}
        w={"100%"}
        mt={"20px"}
      >
        <Input
          placeholder="Announcement text goes here ....."
          p={3}
          h={"70px"}
          w={"100%"}
          value={inputs.announcement}
          onChange={(e) => {
            setInputs({ ...inputs, announcement: e.target.value });
          }}
        />

        <Flex
          alignItems={"center"}
          justifyContent={"center"}
          gap={4}
          mt={"18px"}
        >
          <Text>Send to :</Text>
          <Flex gap={"3"}>
            {relevanceFields.map((field) => {
              if (field.status !== "Not_Relevant") {
                return (
                  <Box
                    h={"32px"}
                    w={"32px"}
                    border={"1px solid"}
                    borderColor={
                      sendTo.some((s) => s == field.status)
                        ? field.color
                        : "gray.200"
                    }
                    onClick={() => {
                      if (sendTo.some((s) => s == field.status)) {
                        setSendTo(sendTo.filter((s) => s != field.status));
                      } else {
                        const arr = [...sendTo];
                        arr.push(field.status);
                        setSendTo(arr);
                      }
                    }}
                    borderRadius={"10px"}
                    p={"6px"}
                  >
                    <Box
                      w={"100%"}
                      h={"100%"}
                      backgroundColor={field.color}
                      borderRadius={"50%"}
                    ></Box>
                  </Box>
                );
              }
            })}
          </Flex>
        </Flex>

        <Flex alignItems={"center"} gap={3} mt={"25px"}>
          <Button
            onClick={() => {
              handleSubmit();
            }}
            flex={1}
            size={"md"}
            p={0}
            bg={"secondary.light"}
            color={"secondary.main"}
          >
            Send
          </Button>
          <Button flex={1} size={"md"} p={0} bg={"gray.100"} color={"gray.600"}>
            Cancel
          </Button>
        </Flex>
      </Box>

      <Flex mt={"30px"} direction={"column"} gap={"24px"} w={"100%"}>
        {data?.map((item: any, index: number) => (
          <Box key={index} rounded={"2xl"} bg={"white"} px={5} py={4}>
            <Text color={"gray.300"}>{timeElapsed(item.createdAt)}</Text>
            <Flex justifyContent={"space-between"} mt={3}>
              <Text>{item.text}</Text>
              {/* <Image src={Images.Stats} ml={"auto"} w={"30px"} h={"30px"} /> */}
            </Flex>
          </Box>
        ))}
      </Flex>
    </Box>
  );
};

export default Announcements;
