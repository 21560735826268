import { extendTheme, ThemeConfig } from "@chakra-ui/react";
import { colors } from "./colors";
import '@fontsource-variable/inter';
// import { fonts } from "./fonts";

const config: ThemeConfig = {
  useSystemColorMode: false,
  initialColorMode: "light",
};

const styles = {
  body: {
    bg: '#F7F7F7',
  },
}


const fonts = {
  heading:`"Inter Variable", sans-serif`,
  body:`"Inter Variable", sans-serif`
}

export const theme = extendTheme({ colors, config, styles, fonts });
