import { Box, Button, Flex, Image, Input, Text } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Icons } from "../../Assets/icons";
import { Images } from "../../Assets/images";

type Props = {};

const RFP = (props: Props) => {
  const navigate = useNavigate();

  const navigateBack = () => {
    navigate(-1);
  };
  return (
    <Box
      w={"100%"}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      bg={"#FBFBFB"}
      minH={"100vh"}
      p={6}
      maxH={"100vh"}
    >
      {/* Header */}
      <Box w={"100%"} mt={"30px"}>
        <Box
          position={"relative"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Flex
            justifyContent={"center"}
            alignItems={"center"}
            direction={"column"}
          >
            <Image width={"60px"} src={Images.rfpDoc} />
            <Text
              fontSize={"20px"}
              fontWeight={700}
              textAlign={"center"}
              color={"black"}
            >
              RFP
            </Text>
          </Flex>

          <Image
            onClick={navigateBack}
            position={"absolute"}
            src={Icons.BackButton}
            left={0}
            top={0}
          />
        </Box>
      </Box>

      {/* Input Card */}
      <Box
        background={"white"}
        borderRadius={"32px"}
        px={"24px"}
        py={"30px"}
        w={"100%"}
        mt={"20px"}
      >
        <Input
          placeholder="Brief intro to the RFP is typed here."
          p={3}
          h={"70px"}
          w={"100%"}
        />

        <Flex alignItems={"center"} justifyContent={'center'} gap={4} mt={'18px'}>
          <Text>Send to :</Text>
          <Flex gap={'3'}>
            {
                [
                    "primary.main",
                    "button.red",
                    "button.green",
                    "button.yellow",
                  ].map((color) => (
            <Box
              h={"32px"}
              w={"32px"}
              border={"1px solid"}
              borderColor={"gray.200"}
              borderRadius={"10px"}
              p={"6px"}
            >
              <Box
                w={"100%"}
                h={"100%"}
                backgroundColor={color}
                borderRadius={"50%"}
              ></Box>
            </Box>
            ))
        }
          </Flex>
        </Flex>

        <Flex alignItems={'center'} gap={3} mt={'25px'}>
            <Button flex={1} size={'md'} p={0} bg={'secondary.light'} color={'secondary.main'}>
                Send
            </Button>
            <Button flex={1} size={'md'} p={0} bg={'gray.100'} color={'gray.600'}>
                Cancel
            </Button>
        </Flex>

       
      </Box>

      <Flex mt={'30px'} direction={'column'} gap={'24px'} w={'100%'}>
            {
                Array(3).fill(0).map((item, index)=>(
                    <Box rounded={'2xl'} bg={'white'} px={5} py={4}>
                        <Text color={'gray.300'}>
                            3 hours ago
                        </Text>
                        <Flex justifyContent={'space-between'} mt={3}>
                            <Text>
                                RFP #3
                            </Text>
                            <Image src={Images.Stats} ml={'auto'} w={'30px'} />
                        </Flex>
                    </Box>
                ))
            }
        </Flex>
    </Box>
  );
};

export default RFP;
