import {
  Badge,
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Input,
  Switch,
  Text,
} from "@chakra-ui/react";
import { Modal, Table, DatePicker, Select } from "antd";
import React, { useEffect, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { useToastWrapper } from "../../Wrapper/toastWrapper";
import {
  AdminAddEvent,
  AdminAddOrganiser,
  SubscriptionPlan,
} from "../../Interface/AdminInterfaces";
import { AdminAPI } from "../../Apis/adminAPI";
import Loader from "../../Components/Loader/Loader";
import Pagination from "../../Components/Pagination/Pagination";
import { errorHandler } from "../../Utils/handler";
import { validateEmail } from "../../Utils/Validator";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  resetForm,
  setClosedMeetingsLimit,
  setDigitalAssetsLimit,
  setEmailSearch,
  setExhibitorTagsLimit,
  setIsAnnouncementsAllowed,
  setIsChatAllowed,
  setIsPlanActive,
  setNotesFeature,
  setPendingMeetingsLimit,
  setPlanName,
  setPromoteTopListing,
  setRelevanceMapping,
  setSubscriptions,
} from "../../redux/slices/subscriptionSlice";

type Props = {};

interface Stats {
  status: string;
  count: Number | undefined;
}

const mandatoryFields = [
  "organizerId",
  "eventName",
  "eventVenue",
  "startDate",
  "endDate",
  "eventCity",
  "eventState",
  "eventCountry",
  // "eventIndustryTags",
];

const mandatoryFieldsSubscriptions = [
  "planName",
  // "emailSearch",
  // "relevanceMapping",
  // "notesFeature",
  // "exhibitorTagsLimit",
  // "pendingMeetingsLimit",
  // "digitalAssetsLimit",
  // "closedMeetingsLimit",
  // "promoteTopListing",
  // "isChatAllowed",
  // "isAnnouncementsAllowed",
];

const Events = (props: Props) => {
  const PAGESIZE = 10;

  const { subscriptions, ...planDetails } = useSelector(
    (state: any) => state.subscription
  );

  const dispatch = useDispatch();

  const [isPlanModalVisible, setIsPlanModalVisible] = useState(false);

  const showPlanModal = () => setIsPlanModalVisible(true);

  const handlePlanOk = () => {
    setPlanEdit(false);
    setIsPlanModalVisible(false);
    dispatch(resetForm());
  };

  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [total, setTotal] = useState(0);
  const [organiserId, setOrganiserId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const showModal = () => setIsModalVisible(true);

  const handleOk = () => {
    setPlanEdit(false);
    setIsModalVisible(false);
  };

  //Delete
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const showDeleteModal = () => setIsDeleteModalOpen(true);

  const handleDeleteModalOk = () => setIsDeleteModalOpen(false);

  //Stats
  const [isStatsModalOpen, setIsStatsModalOpen] = useState(false);

  const showStatsModal = () => setIsStatsModalOpen(true);

  const handleStatsModalOk = () => setIsStatsModalOpen(false);

  const [meetingStats, setMeetingStats] = useState<Stats[]>([]);

  const [deleteid, setDeleteId] = useState("");

  const [formData, setFormData] = useState<AdminAddEvent>({
    organizerId: "",
    eventName: "",
    eventVenue: "",
    startDate: "",
    endDate: "",
    eventCity: "",
    eventState: "",
    eventCountry: "",
    eventUrl: "",
    eventIndustryTags: [],
  });

  const [edit, setEdit] = useState(false);
  const [planEdit, setPlanEdit] = useState(false);
  const [planIdForEdit, setPlanIdForEdit] = useState("");
  const [editId, setEditId] = useState("");

  const [eventOrganisers, setEventOrganisers] = useState<any[]>([]);

  const [data, setData] = useState<AdminAddEvent[]>([]);

  const [singleData, setSingleData] = useState<any>({});

  const getEventSubscriptions = async (eventId: string) => {
    setLoading(true);
    try {
      const res = await AdminAPI.getSubscriptions(eventId);
      console.log(res);
      dispatch(setSubscriptions(res.data.data.subscriptions));
      if (res.success) {
      }
    } catch (err) {
      error(errorHandler(err));
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (key: any) => {
    setLoading(true);
    try {
      const res = await AdminAPI.deleteEvent(key);
      if (res) success("Event deleted successfully", "");
      getEventData();
    } catch (err) {
      error(errorHandler(err));
    } finally {
      setLoading(false);
    }
  };

  const meetingColumns = [
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Count",
      dataIndex: "count",
      key: "count",
    },
  ];

  const columns = [
    {
      title: "Organizer Company Name",
      dataIndex: "organizerId",
      key: "organizerId",
      render: (organizerId: any) => (
        <>
          {
            eventOrganisers.find(
              (organiser: any) => organiser.value === organizerId
            )?.label
          }
        </>
      ),
    },
    {
      title: "Event Name",
      dataIndex: "eventName",
      key: "eventName",
    },
    {
      title: "Venue",
      dataIndex: "eventVenue",
      key: "eventVenue",
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
    },
    {
      title: "City",
      dataIndex: "eventCity",
      key: "eventCity",
    },
    {
      title: "State",
      dataIndex: "eventState",
      key: "eventState",
    },
    {
      title: "Country",
      dataIndex: "eventCountry",
      key: "eventCountry",
    },
    {
      title: "URL",
      dataIndex: "eventUrl",
      key: "eventUrl",
    },
    {
      title: "Industry Tags",
      dataIndex: "eventIndustryTags",
      key: "eventIndustryTags",
      render: (eventIndustryTags: any) => (
        <Flex width={"150px"} flexWrap={"wrap"}>
          {eventIndustryTags.map((tag: any) => (
            <Badge mr={2}>{tag}</Badge>
          ))}
        </Flex>
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_: any, object: any) => (
        <>
          <Flex gap={1}>
            <Button
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              h={"100%"}
              background={"primary.main"}
              color={"white"}
              px={3}
              py={1}
              onClick={() => {
                setFormData({
                  organizerId: object.organizerId,
                  eventName: object.eventName,
                  eventVenue: object.eventVenue,
                  startDate: object.startDate,
                  endDate: object.endDate,
                  eventCity: object.eventCity,
                  eventState: object.eventState,
                  eventCountry: object.eventCountry,
                  eventUrl: object.eventUrl,
                  eventIndustryTags: object.eventIndustryTags,
                });
                setEdit(true);
                setEditId(object._id);
                showModal();
                getEventSubscriptions(object._id);
              }}
            >
              Edit
            </Button>
            <Button
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              h={"100%"}
              background={"primary.main"}
              color={"white"}
              px={3}
              py={1}
              onClick={() => {
                setFormData({
                  organizerId: object.organizerId,
                  eventName: object.eventName,
                  eventVenue: object.eventVenue,
                  startDate: object.startDate,
                  endDate: object.endDate,
                  eventCity: object.eventCity,
                  eventState: object.eventState,
                  eventCountry: object.eventCountry,
                  eventUrl: object.eventUrl,
                  eventIndustryTags: object.eventIndustryTags,
                });
                // setEdit(true);
                setEditId(object._id);
                showPlanModal();
              }}
            >
              Subscriptions
            </Button>
            <Button
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              h={"100%"}
              background={"secondary.light"}
              color={"secondary.main"}
              px={3}
              py={1}
              onClick={() => {
                showDeleteModal();
                setDeleteId(object._id);
              }}
            >
              Delete
            </Button>
            <Button
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              h={"100%"}
              background={"secondary.light"}
              color={"secondary.main"}
              px={3}
              py={1}
              onClick={async () => {
                let singleEvent = await getEventDataByEventId(object._id);
                let data = [];

                let open = { status: "Open", count: singleEvent.openMeetings },
                  confirmed = {
                    status: "Confirmed",
                    count: singleEvent.confirmedMeetings,
                  },
                  closed = {
                    status: "Closed",
                    count: singleEvent.closedMeetings,
                  },
                  canEx = {
                    status: "Cancelled/Expired",
                    count: singleEvent.canExMeetings,
                  },
                  total = { status: "Total", count: singleEvent.totalMeetings };

                data.push(open);
                data.push(confirmed);
                data.push(closed);
                data.push(canEx);
                data.push(total);

                // console.log("this is final data..", data);
                setMeetingStats(data);
                showStatsModal();
              }}
            >
              Stats
            </Button>
          </Flex>
        </>
      ),
    },
  ];

  const handleChange = (e: any) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const clearData = () => {
    setFormData({
      organizerId: "",
      eventName: "",
      eventVenue: "",
      startDate: "",
      endDate: "",
      eventCity: "",
      eventState: "",
      eventCountry: "",
      eventUrl: "",
      eventIndustryTags: [],
    });
  };

  const { error, success } = useToastWrapper();

  const validate = () => {
    console.log(
      mandatoryFields.map((field) => formData[field as keyof AdminAddEvent])
    );
    if (
      mandatoryFields.some((field) => !formData[field as keyof AdminAddEvent])
    ) {
      error("Please fill all the mandatory fields", "");
      return false;
    }
    if (formData.eventIndustryTags.length === 0) {
      error("Please fill all the mandatory fields", "");
      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    if (!validate()) return;
    setLoading(true);

    if (edit) {
      try {
        const response = await AdminAPI.putEvent(editId, formData);
        success("Event Edited Successfully", "");
        handleOk();
        clearData();
        getEventData();
      } catch (err) {
        error(errorHandler(err));
      } finally {
        setLoading(false);
      }
    } else {
      try {
        const response = await AdminAPI.postEvent(formData);
        success("Event Added Successfully", "");
        handleOk();
        clearData();
        getEventData();
      } catch (err) {
        error(errorHandler(err));
      } finally {
        setLoading(false);
      }
    }
  };

  const getData = async () => {
    setLoading(true);
    try {
      const [data1] = await Promise.all([
        AdminAPI.getOrganizer(),
        // AdminAPI.getEvent({limit:10}),
      ]);
      if (data1 && data1.data?.data?.length > 0) {
        setEventOrganisers(
          data1.data.data.map((organiser: any) => ({
            label: organiser.organizerCompanyName,
            value: organiser._id,
          }))
        );
      }
      // if (data2) {
      //   setData(data2.data.data);
      //   setTotal(data2.data.totalCount);
      // }
    } catch (err) {
      error(errorHandler(err));
    } finally {
      setLoading(false);
    }
  };

  const getEventData = async () => {
    setLoading(true);
    try {
      const data = await AdminAPI.getEvent({
        organizerId: organiserId,
        limit: 10,
        page: currentPage,
      });
      console.log(data);

      setData(data.data.data);
      setTotal(data.data.totalCount);
      if (data) {
      }
    } catch (err) {
      error(errorHandler(err));
    } finally {
      setLoading(false);
    }
  };

  const getEventDataByEventId = async (eventId: string) => {
    setLoading(true);
    try {
      const data = await AdminAPI.getEventById(eventId);
      console.log(data);
      // here
      // setSingleData(data.data.data);
      return data.data.data;
      if (data) {
      }
    } catch (err) {
      error(errorHandler(err));
    } finally {
      setLoading(false);
    }
  };

  const validatePlan = () => {
    if (
      mandatoryFieldsSubscriptions.some(
        (field) => !planDetails[field as keyof SubscriptionPlan]
      )
    ) {
      error("Please fill all the mandatory fields", "");
      return false;
    }

    return true;
  };

  const handlePlanModalSubmit = async () => {
    if (!validatePlan()) return;
    setLoading(true);
    console.log("plandetails..", planDetails);
    if (planEdit) {
      try {
        console.log(data);
        const res = await AdminAPI.updateSubscriptionPlan(planIdForEdit, {
          eventId: editId,
          ...planDetails,
        });
        console.log(res);
        dispatch(resetForm());
        handlePlanOk();
        getData();
        success("Plan Edited Successfully", "");
        // setEditId("");
        setPlanEdit(false);
        getEventSubscriptions(editId);
        if (res.success) {
          console.log(data);
        }
      } catch (err) {
        error(errorHandler(err));
        console.log(err);
      } finally {
        setLoading(false);
      }
    } else {
      try {
        //     console.log(data);

        const res = await AdminAPI.addSubscriptionPlan({
          ...planDetails,
          eventId: editId,
          closedMeetingsLimit: parseInt(planDetails.closedMeetingsLimit),
          digitalAssetsLimit: parseInt(planDetails.digitalAssetsLimit),
          exhibitorTagsLimit: parseInt(planDetails.exhibitorTagsLimit),
          pendingMeetingsLimit: parseInt(planDetails.pendingMeetingsLimit),
        });
        console.log(res);
        dispatch(resetForm());
        handlePlanOk();
        getData();
        // setEditId("");
        setPlanEdit(false);
        success("Plan Added Successfully", "");

        if (res.success) {
          console.log(data);
        }
      } catch (err) {
        error(errorHandler(err));
        console.log(err);
      } finally {
        setLoading(false);
      }
    }
  };

  const setActivePlanEdit = (planId: string) => {
    const plan = subscriptions.find((item: any) => item._id === planId);
    if (plan) {
      setPlanIdForEdit(planId);
      dispatch(setPlanName(plan.planName));
      dispatch(setEmailSearch(plan.emailSearch));
      dispatch(setRelevanceMapping(plan.relevanceMapping));
      dispatch(setNotesFeature(plan.notesFeature));
      dispatch(setExhibitorTagsLimit(plan.exhibitorTagsLimit));
      dispatch(setPendingMeetingsLimit(plan.pendingMeetingsLimit));
      dispatch(setDigitalAssetsLimit(plan.digitalAssetsLimit));
      dispatch(setClosedMeetingsLimit(plan.closedMeetingsLimit));
      dispatch(setPromoteTopListing(plan.promoteTopListing));
      dispatch(setIsChatAllowed(plan.isChatAllowed));
      dispatch(setIsAnnouncementsAllowed(plan.isAnnouncementsAllowed));
      dispatch(setIsPlanActive(plan.isPlanActive));
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getEventData();
  }, [organiserId, currentPage]);
  return (
    <div className="p-4">
      {loading ? <Loader /> : null}
      <Modal
        footer={null}
        title={planEdit ? "Edit Plan" : "Add Plan"}
        width={"30%"}
        open={isPlanModalVisible}
        onCancel={handlePlanOk}
        onOk={handlePlanOk}
        zIndex={9999}
      >
        <Box padding={2}>
          <Grid gap={4} templateColumns="repeat(1, 1fr)">
            <GridItem colSpan={1}>
              <Box
                display={"flex"}
                flexDirection={"row"}
                gap={"10px"}
                justifyContent={"space-between"}
              >
                <Text fontSize={"sm"} fontWeight={"500"}>
                  Plan Name <span style={{ color: "red" }}>*</span>
                </Text>
                <Input
                  width={"50%"}
                  height="40px"
                  placeholder="Enter Plan Name"
                  name="planName"
                  value={planDetails.planName}
                  onChange={(e) => {
                    dispatch(setPlanName(e.target.value));
                  }}
                />
              </Box>
            </GridItem>
            <GridItem colSpan={1}>
              <Box
                display={"flex"}
                flexDirection={"row"}
                gap={"10px"}
                justifyContent={"space-between"}
              >
                <Text fontSize={"sm"} fontWeight={"500"}>
                  Search by Email <span style={{ color: "red" }}></span>
                </Text>
                <Switch
                  size="lg"
                  value={planDetails.emailSearch}
                  isChecked={planDetails.emailSearch}
                  onChange={() => {
                    dispatch(setEmailSearch(!planDetails.emailSearch));
                  }}
                />
              </Box>
            </GridItem>
            <GridItem colSpan={1}>
              <Box
                display={"flex"}
                flexDirection={"row"}
                gap={"10px"}
                justifyContent={"space-between"}
              >
                <Text fontSize={"sm"} fontWeight={"500"}>
                  Relevance Mapping <span style={{ color: "red" }}></span>
                </Text>
                <Switch
                  size="lg"
                  value={planDetails.relevanceMapping}
                  isChecked={planDetails.relevanceMapping}
                  onChange={() => {
                    dispatch(
                      setRelevanceMapping(!planDetails.relevanceMapping)
                    );
                  }}
                />
              </Box>
            </GridItem>
            <GridItem colSpan={1}>
              <Box
                display={"flex"}
                flexDirection={"row"}
                gap={"10px"}
                justifyContent={"space-between"}
              >
                <Text fontSize={"sm"} fontWeight={"500"}>
                  Meeting Type <span style={{ color: "red" }}>*</span>
                </Text>
                <Input
                  width={"50%"}
                  height="40px"
                  placeholder="Enter Meeting Type"
                  value={planDetails.meetingType}
                  disabled={true}
                />
              </Box>
            </GridItem>
            <GridItem colSpan={1}>
              <Box
                display={"flex"}
                flexDirection={"row"}
                gap={"10px"}
                justifyContent={"space-between"}
              >
                <Text fontSize={"sm"} fontWeight={"500"}>
                  Notes <span style={{ color: "red" }}></span>
                </Text>
                <Switch
                  size="lg"
                  value={planDetails.notesFeature}
                  isChecked={planDetails.notesFeature}
                  onChange={() => {
                    dispatch(setNotesFeature(!planDetails.notesFeature));
                  }}
                />
              </Box>
            </GridItem>
            <GridItem colSpan={1}>
              <Box
                display={"flex"}
                flexDirection={"row"}
                gap={"10px"}
                justifyContent={"space-between"}
              >
                <Text fontSize={"sm"} fontWeight={"500"}>
                  Pending Status Meetings{" "}
                  <span style={{ color: "red" }}>*</span>
                </Text>
                <Input
                  width={"50%"}
                  height="40px"
                  placeholder="Enter Pending meetings limit"
                  name="pendingMeetingsLimit"
                  value={planDetails.pendingMeetingsLimit}
                  onChange={(e) => {
                    dispatch(setPendingMeetingsLimit(e.target.value));
                  }}
                  type="number"
                />
              </Box>
            </GridItem>
            <GridItem colSpan={1}>
              <Box
                display={"flex"}
                flexDirection={"row"}
                gap={"10px"}
                justifyContent={"space-between"}
              >
                <Text fontSize={"sm"} fontWeight={"500"}>
                  Exhibitor Tags <span style={{ color: "red" }}>*</span>
                </Text>
                <Input
                  width={"50%"}
                  height="40px"
                  placeholder="Enter Exhibitor Tags limit"
                  name="exhibitorTagsLimit"
                  value={planDetails.exhibitorTagsLimit}
                  onChange={(e) => {
                    dispatch(setExhibitorTagsLimit(e.target.value));
                  }}
                  type="number"
                />
              </Box>
            </GridItem>
            <GridItem colSpan={1}>
              <Box
                display={"flex"}
                flexDirection={"row"}
                gap={"10px"}
                justifyContent={"space-between"}
              >
                <Text fontSize={"sm"} fontWeight={"500"}>
                  Digital Assets <span style={{ color: "red" }}>*</span>
                </Text>
                <Input
                  width={"50%"}
                  height="40px"
                  placeholder="Enter Digital Assets limit"
                  name="digitalAssetsLimit"
                  value={planDetails.digitalAssetsLimit}
                  onChange={(e) => {
                    dispatch(setDigitalAssetsLimit(e.target.value));
                  }}
                  type="number"
                />
              </Box>
            </GridItem>
            <GridItem colSpan={1}>
              <Box
                display={"flex"}
                flexDirection={"row"}
                gap={"10px"}
                justifyContent={"space-between"}
              >
                <Text fontSize={"sm"} fontWeight={"500"}>
                  Closed Meetings <span style={{ color: "red" }}>*</span>
                </Text>
                <Input
                  width={"50%"}
                  height="40px"
                  placeholder="Enter Closed Meetings Limit"
                  name="closedMeetingsLimit"
                  value={planDetails.closedMeetingsLimit}
                  onChange={(e) => {
                    dispatch(setClosedMeetingsLimit(e.target.value));
                  }}
                  type="number"
                />
              </Box>
            </GridItem>
            <GridItem colSpan={1}>
              <Box
                display={"flex"}
                flexDirection={"row"}
                gap={"10px"}
                justifyContent={"space-between"}
              >
                <Text fontSize={"sm"} fontWeight={"500"}>
                  Premium Listing <span style={{ color: "red" }}></span>
                </Text>
                <Switch
                  size="lg"
                  value={planDetails.promoteTopListing}
                  isChecked={planDetails.promoteTopListing}
                  onChange={() => {
                    dispatch(
                      setPromoteTopListing(!planDetails.promoteTopListing)
                    );
                  }}
                />
              </Box>
            </GridItem>
            <GridItem colSpan={1}>
              <Box
                display={"flex"}
                flexDirection={"row"}
                gap={"10px"}
                justifyContent={"space-between"}
              >
                <Text fontSize={"sm"} fontWeight={"500"}>
                  Chat <span style={{ color: "red" }}></span>
                </Text>
                <Switch
                  size="lg"
                  value={planDetails.isChatAllowed}
                  isChecked={planDetails.isChatAllowed}
                  onChange={() => {
                    dispatch(setIsChatAllowed(!planDetails.isChatAllowed));
                  }}
                />
              </Box>
            </GridItem>
            <GridItem colSpan={1}>
              <Box
                display={"flex"}
                flexDirection={"row"}
                gap={"10px"}
                justifyContent={"space-between"}
              >
                <Text fontSize={"sm"} fontWeight={"500"}>
                  Announcements <span style={{ color: "red" }}></span>
                </Text>
                <Switch
                  size="lg"
                  value={planDetails.isAnnouncementsAllowed}
                  isChecked={planDetails.isAnnouncementsAllowed}
                  onChange={() => {
                    dispatch(
                      setIsAnnouncementsAllowed(
                        !planDetails.isAnnouncementsAllowed
                      )
                    );
                  }}
                />
              </Box>
            </GridItem>
            <GridItem colSpan={1}>
              <Box
                display={"flex"}
                flexDirection={"row"}
                gap={"10px"}
                justifyContent={"space-between"}
              >
                <Text fontSize={"sm"} fontWeight={"500"}>
                  Active Status <span style={{ color: "red" }}></span>
                </Text>
                <Switch
                  size="lg"
                  value={planDetails.isPlanActive}
                  isChecked={planDetails.isPlanActive}
                  onChange={() => {
                    dispatch(setIsPlanActive(!planDetails.isPlanActive));
                  }}
                />
              </Box>
            </GridItem>
          </Grid>

          <Box mt={10}>
            <Flex justifyContent={"end"}>
              <Button
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                h={"40px"}
                background={"primary.main"}
                color={"white"}
                px={3}
                gap={2}
                _hover={{ background: "primary.dark" }}
                onClick={handlePlanModalSubmit}
              >
                Submit
              </Button>
            </Flex>
          </Box>
        </Box>
      </Modal>
      {/* Delete Prompt */}
      <Modal
        title="Alert"
        footer={null}
        onCancel={handleDeleteModalOk}
        open={isDeleteModalOpen}
      >
        <Text my={5} mt={8}>
          Deleting this event will permanently remove all exhibitor and visitor
          data linked to this event. Are you sure ?
        </Text>
        <Flex justifyContent={"space-between"}>
          <Button
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            h={"100%"}
            background={"primary.main"}
            color={"white"}
            px={3}
            py={1}
            onClick={() => {
              handleDeleteModalOk();
            }}
          >
            No
          </Button>
          <Button
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            h={"100%"}
            background={"secondary.light"}
            color={"secondary.main"}
            px={3}
            py={1}
            onClick={() => {
              handleDelete(deleteid);
              handleDeleteModalOk();
            }}
          >
            Yes
          </Button>
        </Flex>
      </Modal>

      <Modal
        title="Meeting Stats"
        footer={null}
        onCancel={handleStatsModalOk}
        open={isStatsModalOpen}
      >
        <Table
          dataSource={meetingStats}
          columns={meetingColumns}
          bordered
          pagination={false}
        />
      </Modal>

      <Modal
        footer={null}
        title={edit ? "Edit Event" : "Add Event"}
        width={"80%"}
        open={isModalVisible}
        onCancel={handleOk}
        onOk={handleOk}
      >
        <Box padding={2}>
          <Grid gap={4} templateColumns="repeat(3, 1fr)">
            <GridItem colSpan={1}>
              <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                <Text fontSize={"sm"} fontWeight={"500"}>
                  Event Organizer <span style={{ color: "red" }}>*</span>
                </Text>
                <Select
                  style={{
                    height: "40px",
                  }}
                  value={formData.organizerId}
                  placeholder="Select  Organizer"
                  onChange={(value) => {
                    console.log(value);

                    setFormData({
                      ...formData,
                      organizerId: value,
                    });
                  }}
                  options={eventOrganisers}
                />
              </Box>
            </GridItem>
            <GridItem colSpan={1}>
              <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                <Text fontSize={"sm"} fontWeight={"500"}>
                  Event Name <span style={{ color: "red" }}>*</span>
                </Text>
                <Input
                  height="40px"
                  placeholder="Enter Event Name"
                  name="eventName"
                  value={formData.eventName}
                  onChange={handleChange}
                />
              </Box>
            </GridItem>
            <GridItem colSpan={1}>
              <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                <Text fontSize={"sm"} fontWeight={"500"}>
                  Event Venue <span style={{ color: "red" }}>*</span>
                </Text>
                <Input
                  height="40px"
                  placeholder="Enter Event Venue"
                  name="eventVenue"
                  value={formData.eventVenue}
                  onChange={handleChange}
                />
              </Box>
            </GridItem>
            <GridItem colSpan={1}>
              <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                <Text fontSize={"sm"} fontWeight={"500"}>
                  Date <span style={{ color: "red" }}>*</span>
                </Text>
                <div>
                  <DatePicker.RangePicker
                    className="h-[40px] w-full"
                    value={
                      formData.startDate && formData.endDate
                        ? [dayjs(formData.startDate), dayjs(formData.endDate)]
                        : null
                    }
                    onChange={(date, dateString) => {
                      setFormData({
                        ...formData,
                        startDate: dateString[0],
                        endDate: dateString[1],
                      });
                    }}
                  />
                </div>
              </Box>
            </GridItem>
            <GridItem colSpan={1}>
              <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                <Text fontSize={"sm"} fontWeight={"500"}>
                  Event City <span style={{ color: "red" }}>*</span>
                </Text>
                <Input
                  height="40px"
                  placeholder="Enter Event City"
                  name="eventCity"
                  value={formData.eventCity}
                  onChange={handleChange}
                />
              </Box>
            </GridItem>
            <GridItem colSpan={1}>
              <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                <Text fontSize={"sm"} fontWeight={"500"}>
                  Event State <span style={{ color: "red" }}>*</span>
                </Text>
                <Input
                  height="40px"
                  placeholder="Enter Event State"
                  name="eventState"
                  value={formData.eventState}
                  onChange={handleChange}
                />
              </Box>
            </GridItem>
            <GridItem colSpan={1}>
              <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                <Text fontSize={"sm"} fontWeight={"500"}>
                  Event Country <span style={{ color: "red" }}>*</span>
                </Text>
                <Input
                  height="40px"
                  placeholder="Enter Event Country"
                  name="eventCountry"
                  value={formData.eventCountry}
                  onChange={handleChange}
                />
              </Box>
            </GridItem>
            <GridItem colSpan={1}>
              <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                <Text fontSize={"sm"} fontWeight={"500"}>
                  Event URL
                </Text>
                <Input
                  height="40px"
                  placeholder="Enter Event URL"
                  name="eventUrl"
                  value={formData.eventUrl}
                  onChange={handleChange}
                />
              </Box>
            </GridItem>
            <GridItem colSpan={1}>
              <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                <Text fontSize={"sm"} fontWeight={"500"}>
                  Event Industry Tags <span style={{ color: "red" }}>*</span>
                </Text>
                <Select
                  mode="tags"
                  style={{
                    height: "40px",
                  }}
                  placeholder="Enter Event Industry Tags"
                  onChange={(value) => {
                    setFormData({
                      ...formData,
                      eventIndustryTags: value,
                    });
                  }}
                  value={formData.eventIndustryTags}
                  options={[]}
                />
              </Box>
            </GridItem>
            <GridItem colSpan={1}>
              <Flex gap={2} mb={"2px"}>
                <Text fontSize={"sm"} fontWeight={"500"}>
                  Subscription Plans <span style={{ color: "red" }}></span>
                </Text>
                <Text
                  display={"flex"}
                  flexWrap={"wrap"}
                  alignItems={"center"}
                  gap={2}
                  cursor={"pointer"}
                  onClick={() => {
                    showPlanModal();
                  }}
                >
                  {subscriptions
                    // ?.filter((v: any, index: number) => index < 2)
                    ?.map((plan: any) => (
                      // <span className="p-1 bg-gray-400 text-white rounded-md whitespace-nowrap">
                      <span
                        style={{
                          padding: "3px 7px",
                          color: "#000000",
                          fontFamily: `"Inter Variable", sans-serif`,
                          fontWeight: "normal",
                          backgroundColor: "#FFD2CD",
                          borderRadius: "3px",
                          height: "18px",
                          fontSize: "10px",
                        }}
                        className="whitespace-nowrap"
                        onClick={() => {
                          setActivePlanEdit(plan._id);
                          setPlanEdit(true);
                          showPlanModal();
                        }}
                      >
                        {plan.planName}
                      </span>
                    ))}
                </Text>
              </Flex>
            </GridItem>
          </Grid>

          <Box mt={10}>
            <Flex justifyContent={"end"}>
              <Button
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                h={"40px"}
                background={"primary.main"}
                color={"white"}
                px={3}
                gap={2}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Flex>
          </Box>
        </Box>
      </Modal>
      <Flex justifyContent={"space-between"} mt={"10px"}>
        <Text size={"lg"} fontWeight={600}>
          Events
        </Text>
        <Flex gap={3}>
          <Input
            placeholder="Search by name"
            type="text"
            border={"1px solid"}
            outline={"none"}
            _focus={{ outline: "none", border: "1px solid" }}
            h={"35px"}
            w={"200px"}
            px={2}
            borderColor={"gray.300"}
          />
          <Button
            display={"flex"}
            onClick={() => {
              setEdit(false);
              clearData();
              setEditId("");
              showModal();
            }}
            justifyContent={"center"}
            alignItems={"center"}
            h={"100%"}
            background={"primary.main"}
            color={"white"}
            px={3}
            gap={2}
          >
            <AiOutlinePlus /> Event
          </Button>
          {/* <Button
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            h={"100%"}
            background={"primary.main"}
            color={"white"}
            px={3}
          >
            Import
          </Button> */}
        </Flex>
      </Flex>

      <Box
        my={"50px"}
        width={"30%"}
        display={"flex"}
        flexDirection={"column"}
        gap={"10px"}
      >
        <Text fontSize={"sm"} fontWeight={"500"}>
          Select Organizer
        </Text>
        <Select
          style={{
            height: "40px",
          }}
          placeholder="Select  Organizer"
          onChange={(value) => {
            setOrganiserId(value);
          }}
          options={[
            {
              label: "All",
              value: "",
            },
            ...eventOrganisers,
          ]}
          value={organiserId}
        />
      </Box>

      <Box>
        {total === 0 ? (
          <Text>No Events Found</Text>
        ) : (
          <Text>Total Events: {total}</Text>
        )}
      </Box>

      <Box mt={5}>
        <Table
          dataSource={data}
          columns={columns}
          bordered
          pagination={false}
        />
        <Flex justifyContent={"end"}>
          <Pagination
            current={currentPage}
            total={total}
            onChange={(number) => setCurrentPage(number)}
            pageSize={PAGESIZE}
          />
        </Flex>
      </Box>
    </div>
  );
};

export default Events;
