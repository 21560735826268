import { Box, Flex, Image, Text } from '@chakra-ui/react'
import React from 'react'
import Header from '../../Components/Common/Header'
import { color } from 'framer-motion'
import { Icons } from '../../Assets/icons'
import { relevanceFields } from '../../Constants/data'

type Props = {}

const Relevance = (props: Props) => {

    return (
        <Box background={'primary.main'} flexGrow={1} display={'flex'} flexDirection={'column'}>
            <Box p={6}>
                <Header backButton isWhite={true}>
                    Relevance
                </Header>
                <Text fontSize={'18px'} fontWeight={'bold'} color={'white'} mt={6}>
                    Is this company relevant to you?
                </Text>
                <Text fontSize={'12px'} fontWeight={500} opacity={0.6} color={'white'} mt={1.5}>
                    Color indicator will appear next to the company name
                </Text>
            </Box>
            <Box flexGrow={1} bg={'#FAFAFA'} borderTopRadius={'40px'} mt={12} pt={'52px'} px={'24px'}>
                <Flex direction={'column'} gap={'26px'}>
                    {
                        relevanceFields.map((field, index) => (
                            <Flex p={4} background={'white'} borderRadius={'18px'} fontSize={'15px'} fontWeight={'600'} justifyContent={'space-between'} alignItems={'center'}>
                                <Flex alignItems={'center'} gap={'12px'}>
                                    <Box h={'32px'} w={'32px'} border={'1px solid'} borderColor={'gray.200'} borderRadius={'10px'} p={'6px'}>
                                        <Box w={'100%'} h={'100%'} backgroundColor={field.color} borderRadius={'50%'}>

                                        </Box>
                                    </Box>
                                    {
                                        field.title
                                    }
                                </Flex>

                                <Box>
                                    <Image src={field.check?Icons.CheckFilled:Icons.CheckOutline}/>
                                </Box>

                            </Flex>
                        ))
                    }
                </Flex>
            </Box>
        </Box>
    )
}

export default Relevance