import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Input,
  Text,
} from "@chakra-ui/react";
import { Modal, Table, DatePicker } from "antd";
import React, { useEffect, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { AdminAddOrganiser } from "../../Interface/AdminInterfaces";
import { useToastWrapper } from "../../Wrapper/toastWrapper";
import { AdminAPI } from "../../Apis/adminAPI";
import { get } from "http";
import Loader from "../../Components/Loader/Loader";
import Pagination from "../../Components/Pagination/Pagination";
import { errorHandler } from "../../Utils/handler";
import { validateEmail } from "../../Utils/Validator";

type Props = {};

const mandatoryFields = [
  "organizerPersonName",
  "organizerCompanyName",
  "organizerPersonEmail",
];

const Organiser = (props: Props) => {
  const PAGESIZE = 10;

  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => setIsModalVisible(true);

  const handleOk = () => setIsModalVisible(false);

  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  //Delete
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const showDeleteModal = () => setIsDeleteModalOpen(true);

  const handleDeleteModalOk = () => setIsDeleteModalOpen(false);

  const [deleteid, setDeleteId] = useState("");

  const [newData, setNewData] = useState<AdminAddOrganiser>({
    organizerPersonName: "",
    organizerCompanyName: "",
    organizerPersonEmail: "",
    organizerPersonPhoneNumber: "",
  });

  const [edit, setEdit] = useState(false);
  const [editId, setEditId] = useState("");

  const handleChange = (e: any) => {
    setNewData({
      ...newData,
      [e.target.name]: e.target.value,
    });
  };

  const [data, setData] = useState<AdminAddOrganiser[]>([]);

  const handleDelete = async (key: any) => {
    setLoading(true);
    try {
      const res = await AdminAPI.deleteOrganizer(key);
      if (res) success("Organiser deleted successfully", "");
      getData();
    } catch (err) {
      error(errorHandler(err));
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "Company Name",
      dataIndex: "organizerCompanyName",
      key: "organizerCompanyName",
    },
    {
      title: "Name",
      dataIndex: "organizerPersonName",
      key: "organizerPersonName",
    },
    {
      title: "Email",
      dataIndex: "organizerPersonEmail",
      key: "organizerPersonEmail",
    },
    {
      title: "Phone",
      dataIndex: "organizerPersonPhoneNumber",
      key: "organizerPersonPhoneNumber",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_: any, object: any) => (
        <>
          <Flex gap={1}>
            <Button
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              h={"100%"}
              background={"primary.main"}
              color={"white"}
              px={3}
              py={1}
              onClick={() => {
                setNewData({
                  organizerPersonName: object.organizerPersonName,
                  organizerCompanyName: object.organizerCompanyName,
                  organizerPersonEmail: object.organizerPersonEmail,
                  organizerPersonPhoneNumber: object.organizerPersonPhoneNumber,
                });
                setEdit(true);
                setEditId(object._id);
                showModal();
              }}
            >
              Edit
            </Button>
            <Button
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              h={"100%"}
              background={"secondary.light"}
              color={"secondary.main"}
              px={3}
              py={1}
              onClick={() => {
                showDeleteModal();
                setDeleteId(object._id);
                // handleDelete(object._id);
              }}
            >
              Delete
            </Button>
          </Flex>
        </>
      ),
    },
  ];

  const { error, success } = useToastWrapper();

  const validate = () => {
    if (
      mandatoryFields.some(
        (field) => !newData[field as keyof AdminAddOrganiser]
      )
    ) {
      error("Please fill all the mandatory fields", "");
      return false;
    }
    if (!validateEmail(newData.organizerPersonEmail)) {
      error("Please enter a valid email", "");
      return false;
    }

    return true;
  };

  const clearData = () => {
    setNewData({
      organizerPersonName: "",
      organizerCompanyName: "",
      organizerPersonEmail: "",
      organizerPersonPhoneNumber: "",
    });
  };

  const getData = async () => {
    setLoading(true);
    try {
      const res = await AdminAPI.getOrganizer({ limit: 10, page: currentPage });
      console.log(res);
      setTotal(res.data.totalCount);
      setData(res.data.data);
      if (res.success) {
      }
    } catch (err) {
      error(errorHandler(err));
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    if (!validate()) return;
    setLoading(true);
    if (edit) {
      try {
        console.log(data);

        const res = await AdminAPI.putOrganizer(editId, newData);
        console.log(res);
        clearData();
        handleOk();
        getData();
        success("Organizer Edited Successfully", "");
        setEdit(false);
        setEditId("");

        if (res.success) {
          console.log(data);
        }
      } catch (err) {
        error(errorHandler(err));
        console.log(err);
      } finally {
        setLoading(false);
      }
    } else {
      try {
        console.log(data);

        const res = await AdminAPI.postOrganizer(newData);
        console.log(res);
        clearData();
        handleOk();
        getData();

        if (res.success) {
          console.log(data);
          success("Organizer Added Successfully", "");
        }
      } catch (err) {
        error(errorHandler(err));
        console.log(err);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getData();
  }, [currentPage]);

  return (
    <div className="p-4">
      {loading ? <Loader /> : null}
      {/* Delete Prompt */}
      <Modal
        title="Alert"
        footer={null}
        onCancel={handleDeleteModalOk}
        open={isDeleteModalOpen}
      >
        <Text my={5} mt={8}>
          Deleting an organizer will also remove all events (and their
          exhibitors and visitors) linked to that organizer. Are you sure ?
        </Text>
        <Flex justifyContent={"space-between"}>
          <Button
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            h={"100%"}
            background={"primary.main"}
            color={"white"}
            px={3}
            py={1}
            onClick={() => {
              handleDeleteModalOk();
            }}
          >
            No
          </Button>
          <Button
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            h={"100%"}
            background={"secondary.light"}
            color={"secondary.main"}
            px={3}
            py={1}
            onClick={() => {
              handleDelete(deleteid);
              handleDeleteModalOk();
            }}
          >
            Yes
          </Button>
        </Flex>
      </Modal>

      <Modal
        footer={null}
        title={edit ? "Edit Organizer" : "Add Organizer"}
        width={"60%"}
        open={isModalVisible}
        onCancel={handleOk}
        onOk={handleSubmit}
      >
        <Box padding={2}>
          <Grid gap={4} templateColumns="repeat(2, 1fr)">
            <GridItem colSpan={1}>
              <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                <Text fontSize={"sm"} fontWeight={"500"}>
                  Company Name <span style={{ color: "red" }}>*</span>
                </Text>
                <Input
                  height="40px"
                  placeholder="Enter Company Name"
                  name="organizerCompanyName"
                  value={newData.organizerCompanyName}
                  onChange={handleChange}
                />
              </Box>
            </GridItem>
            <GridItem colSpan={1}>
              <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                <Text fontSize={"sm"} fontWeight={"500"}>
                  Organizer Name <span style={{ color: "red" }}>*</span>
                </Text>
                <Input
                  height="40px"
                  placeholder="Name of Primary Contact"
                  name="organizerPersonName"
                  value={newData.organizerPersonName}
                  onChange={handleChange}
                />
              </Box>
            </GridItem>
            <GridItem colSpan={1}>
              <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                <Text fontSize={"sm"} fontWeight={"500"}>
                  Email <span style={{ color: "red" }}>*</span>
                </Text>
                <Input
                  height="40px"
                  placeholder="Eg:. example@company.com"
                  name="organizerPersonEmail"
                  value={newData.organizerPersonEmail}
                  onChange={handleChange}
                />
              </Box>
            </GridItem>
            <GridItem colSpan={1}>
              <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                <Text fontSize={"sm"} fontWeight={"500"}>
                  Phone
                </Text>
                <Input
                  height="40px"
                  placeholder="Eg:. +918904079022"
                  name="organizerPersonPhoneNumber"
                  value={newData.organizerPersonPhoneNumber}
                  onChange={handleChange}
                />
              </Box>
            </GridItem>
          </Grid>

          <Box mt={10}>
            <Flex justifyContent={"end"}>
              <Button
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                h={"40px"}
                background={"primary.main"}
                color={"white"}
                px={3}
                gap={2}
                _hover={{ background: "primary.dark" }}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Flex>
          </Box>
        </Box>
      </Modal>
      <Flex justifyContent={"space-between"} mt={"10px"}>
        <Text size={"lg"} fontWeight={600}>
          Organizers
        </Text>
        <Flex gap={3}>
          <Input
            placeholder="Search Organiser"
            type="text"
            border={"1px solid"}
            outline={"none"}
            _focus={{ outline: "none", border: "1px solid" }}
            h={"35px"}
            w={"200px"}
            px={2}
            borderColor={"gray.300"}
          />
          <Button
            display={"flex"}
            onClick={() => {
              setEdit(false);
              clearData();
              setEditId("");
              showModal();
            }}
            justifyContent={"center"}
            alignItems={"center"}
            h={"100%"}
            background={"primary.main"}
            color={"white"}
            px={3}
            gap={2}
            _hover={{ background: "primary.dark" }}
          >
            <AiOutlinePlus /> Organizer
          </Button>
          {/* <Button
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            h={"100%"}
            background={"primary.main"}
            color={"white"}
            px={3}
          >
            Import
          </Button> */}
        </Flex>
      </Flex>
      <Box>
        {total === 0 ? (
          <Text>No Organiser Companies Found</Text>
        ) : (
          <Text>Total Organiser Companies: {total}</Text>
        )}
      </Box>
      <Box mt={5}>
        <Table
          dataSource={data}
          bordered
          columns={columns}
          pagination={false}
        />
        <Flex justifyContent={"end"}>
          <Pagination
            current={currentPage}
            total={total}
            onChange={(number) => {
              setCurrentPage(number);
            }}
            pageSize={PAGESIZE}
          />
        </Flex>
      </Box>
    </div>
  );
};

export default Organiser;
