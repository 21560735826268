import { Box, Flex, Text } from "@chakra-ui/react"
import { RelevanceFieldsNew } from "../../Constants/data"

type Props = {
    color : string;
    title: string;
    count: string;
    rounded?: boolean;
    width: string;
}

const CountDisplay = (props: Props) => {
    return (
        <Box
            w={props.width}
            bg={props.color}
            rounded={props.rounded ? "full" : ""}
            py={"5px"}
        >
            <Flex direction={"column"} alignItems={"center"}>
                <Text fontSize={"xs"} fontWeight={"normal"} color={"white"}>
                    {props.title}
                </Text>
                <Text fontSize={"small"} fontWeight={"normal"} color={"white"}>
                    {`(${props.count || 0})`}
                </Text>

            </Flex>
        </Box>
    )
}


export default CountDisplay;