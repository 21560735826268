import { Box, Button, Flex, Input, Text, VStack } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";

import { Modal, Select, message } from "antd";

import moment from "moment";

//// Icons

import { BsSendFill } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import Header from "../../Components/Common/Header";
import { useLocation, useSearchParams } from "react-router-dom";
import { errorHandler } from "../../Utils/handler";
import { useToastWrapper } from "../../Wrapper/toastWrapper";
import { CommonAPI } from "../../Apis/commonApi";
import Loader from "../../Components/Loader/Loader";
import NotesModal from "../../Components/Modals/NotesModal";
import { BiRefresh } from "react-icons/bi";

const formatChat = (chats: any, receiverId: string) => {
  return chats?.map((message: any, index: number) => {
    return {
      ...message,
      sent: receiverId === message.receiverId ? "me" : "other",
    };
  });
};

const Chat = () => {
  const { state } = useLocation();

  console.log('state', state);

  const lastChatRef = useRef<HTMLDivElement>(null)
  

  const [user, setUser] = useState<any>([]);

  const [text, setText] = useState<string>("");

  const [searchParams, setSearchParams] = useSearchParams();

  const [receiverId, setReceiverId] = useState<string>("");

  const [loading, setLoading] = useState(false);

  const [chat, setChat] = useState([]);

  const { error } = useToastWrapper();

  const [notesModal, setNotesModal] = useState(false);
  const handleOpenNotesModal = () => setNotesModal(true);
  const handleCloseNotesModal = () => setNotesModal(false);
  const [notesData, setNotesData] = useState<any>({
    forUser: "",
    eventId: ""
  });

  useEffect(() => {
    if (state?.visitor) {
      const visitor = state?.visitor;
      setUser({
        name: visitor?.visitorPersonName,
        company: visitor?.visitorCompanyName,
        designation: visitor?.visitorDesignation,
        role: "Visitor",
      });
    } else if (state?.exhibitor) {
      const exhibitor = state?.exhibitor;
      setUser({
        name: exhibitor?.personName,
        company: exhibitor?.companyName,
        designation: exhibitor?.personDesignation,
        role: "Exhibitor",
      });
    }
  }, [state]);

  useEffect(() => {
    if (searchParams?.get("user")) {
      setReceiverId(searchParams?.get("user") || "");
      // setNotesData({
      //   ...notesData,
      //   forUser: searchParams?.get("user") || ""
      // })
      getChats(searchParams?.get("user") || "");
    }
    if(searchParams?.get("event")){
      setNotesData((prev:any)=>({
        ...prev,
        eventId: searchParams?.get("event") || ""
      }))
    }
    if(searchParams?.get("m")){
      setNotesData((prev:any)=>({
        ...prev,
        forUser: searchParams?.get("m") || ""
      }))
    }
  }, [searchParams]);

  const getChats = async (recieverId: string) => {
    try {
      setLoading(true);

      const data = await CommonAPI.getChat(recieverId);

      if (data?.success) {
        console.log(data.data);
        // setUser()
        setChat(formatChat(data?.data?.data, recieverId)?.reverse());
        const reciever = data?.data?.receiver;
        // setUser({
        //   name: reciever?.name,
        //   company: reciever?.company,
        //   designation: reciever?.designation,
        //   role: reciever?.role,
        // });
      }
    } catch (err) {
      console.log(err);
      const errMsg = errorHandler(err);
      error(errMsg);
    } finally {
      setLoading(false);
    }
  };

  useEffect(()=>{
    if(chat.length > 0 && lastChatRef.current){
      setTimeout(() => {
        lastChatRef?.current?.scrollIntoView({ behavior: 'smooth' });
      }, 500);
    }
  }, [chat, lastChatRef.current])

  const sendMessage = async () => {
    if (!text) {
      return error("Please enter message");
    }
    try {
      setLoading(true);

      const data = await CommonAPI.sendChat(receiverId, { text });

      console.log(data);
      if (data?.success) {
        setText("");
        getChats(receiverId);
      }
    } catch (err) {
      console.log(err);
      const errMsg = errorHandler(err);
      error(errMsg);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      w={"100%"}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      bg={"linear.100"}
      minH={"100vh"}
      maxH={"100vh"}
    >
      <Modal
        closeIcon={null}
        footer={null}
        title={null}
        open={notesModal}
        onOk={handleCloseNotesModal}
        onCancel={handleCloseNotesModal}
      >
        <NotesModal eventId={notesData.eventId} forUser={notesData.forUser} handleCloseModal={handleCloseNotesModal} />
      </Modal>
      {loading && <Loader />}
      <VStack w={"100%"} px={"30px"}>
        {/* <Flex
          mt={"30px"}
          alignItems={"center"}
          justifyContent={"space-between"}
          w={"100%"}
          gap={"20px"}
        >
          <VStack w={"100%"} textAlign={"center"} alignItems={"center"}>
            <Text fontSize={"lg"} fontWeight={600} color={"#fff"}>
              Company Name
            </Text>
          </VStack>
        </Flex> */}

        <Box mt={"30px"} w={"100%"}>
          <Header backButton isWhite>
            {user?.company}
          </Header>
        </Box>

        <Box
          w={"100%"}
          bg={"white"}
          mt={"30px"}
          // border={"1px solid #E2E8F0"}
          p={"15px 20px"}
          borderRadius={"10px"}
          shadow={"md"}
          display={"flex"}
          flexDirection={"column"}
          _hover={{
            shadow: "lg",
          }}
          position={"relative"}
        >
          <Text fontWeight={600}>{user?.name}</Text>
          {/* <Flex>
            <Text fontSize={"sm"}>12.03.2023 19:03</Text>
          </Flex> */}
          <Flex>
            <Text fontSize={"sm"}>{user?.designation}</Text>
          </Flex>

          <Button
            position={"absolute"}
            top={0}
            right={0}
            colorScheme="transparent"
          >
            <span>
              <FiEdit onClick={()=>{
                handleOpenNotesModal()
              }} size={22} color="#000" />
            </span>
          </Button>
        </Box>
      </VStack>

      <Box
        w={"100%"}
        bg={"white"}
        h={"100%"}
        flex={1}
        marginTop={"30px"}
        minH={"100%"}
        maxH={"100%"}
        overflowY={"auto"}
        position={"relative"}
        borderTopRadius={"40px"}
        px={"30px"}
      >
        <Box
          bg={"white"}
          pt={"20px"}
          pb={"15px"}
          zIndex={200}
          borderBottom={"2px solid #E2E8F0"}
          position={"sticky"}
          top={0}
          left={0}
          w={"100%"}
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Text fontSize={"lg"} fontWeight={600}>
            Conversations
          </Text>
          {/* <Button
            p={"10px 15px"}
            bg={"#F5793D1a"}
            color={"#F5793D"}
            rounded={"12px"}
          > */}
            <BiRefresh style={{height:'30px', width:'30px'}} onClick={()=>getChats(receiverId)} cursor={'pointer'} />
          {/* </Button> */}
        </Box>

        <VStack pb={"100px"} pt={"20px"} px={"5px"} spacing={4}>
          {[...chat]?.map((item: any, index) => {
            return item?.sent === "other" ? (
              <Box
                ref={index === chat.length - 1 ? lastChatRef : null}
                w={"80%"}
                mr={"auto"}
                bg={"gray.100"}
                p={"15px 20px"}
                display={"flex"}
                flexDirection={"column"}
                _hover={{
                  shadow: "lg",
                }}
                position={"relative"}
                key={"chat" + index}
              >
                <Text fontSize={"sm"} fontWeight={400} color={"gray.500"}>
                {moment(item?.timestamp)?.format("DD/MM/YYYY hh:mm")}
                </Text>
                <Text mt={"10px"} fontSize={"md"}>
                  {item?.text}
                </Text>
              </Box>
            ) : (
              <Box
                display={"flex"}
                ref={index === chat.length - 1 ? lastChatRef : null}
                flexDirection={"column"}
                gap={"10px"}
                w={"50%"}
                ml={"auto"}
                alignItems={"flex-end"}
              >
                <Text fontSize={"sm"} fontWeight={400} color={"gray.500"}>
                  {moment(item?.timestamp)?.format("DD/MM/YYYY hh:mm A")}
                </Text>

                <Box
                  bg={"#7C66FF"}
                  p={"10px 15px"}
                  borderBottomRadius={"20px"}
                  borderTopLeftRadius={"20px"}
                  borderTopRightRadius={"4px"}
                >
                  <Text color={"#fff"}>{item?.text}</Text>
                </Box>
              </Box>
            );
          })}
        </VStack>

        <Box
          bg={"white"}
          borderTop={'3px solid'}
          borderColor={'gray.100'}
          py={"10px"}
          zIndex={200}
          borderBottom={"1px solid #E2E8F0"}
          position={"fixed"}
          bottom={0}
          left={0}
          w={"100%"}
          px={"30px"}
        >
          <Flex alignItems={"center"} justifyContent={"space-between"}>
            <Input
              placeholder={"Type a message"}
              border={"0px"}
              outline={"none"}
              _focus={{ outline: "none", border: "0px" }}
              // bg={"white"}
              h={"50px"}
              w={"100%"}
              px={2}
              value={text}
              _placeholder={{
                color: "gray.500",
                fontWeight: "400",
                fontSize: "16px",
              }}
              onChange={(e) => setText(e.target.value)}
            />

            <Button
              bg={"gray.300"}
              h={"50px"}
              w={"50px"}
              rounded={"50%"}
              shadow={"md"}
              onClick={() => sendMessage()}
            >
              <span>
                <BsSendFill size={22} color={"black"} />
              </span>
            </Button>
          </Flex>
        </Box>
      </Box>
    </Box>
  );
};

export default Chat;
