import { Box, Flex, Grid, GridItem, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { BiGitMerge } from 'react-icons/bi'
import { BsFillPersonFill, BsPersonWorkspace } from 'react-icons/bs'
import { MdEmojiEvents } from 'react-icons/md'
import { DashboardAPI } from '../../Apis/dashboardApi'
import { useToastWrapper } from '../../Wrapper/toastWrapper'
import { errorHandler } from '../../Utils/handler'

type Props = {}

const Dashboard = (props: Props) => {

  const [data, setData] = useState<any>({})

  const {error, success} = useToastWrapper()

  const getData = async() => {
    try{
      const res = await DashboardAPI.getDashboardData();
      if(res){
        setData(res.data.data)
      }
    }
    catch(err){
      error(errorHandler(err))
    }
  }

    useEffect(()=>{
      getData()
    },[])
  return (
    <Box p={4} bg={'#F5F5F5'} minH={'100%'}>
          <Text>Dashboard</Text>
          <Grid
            templateColumns="repeat(4, 1fr)"
            gap={4}
            mt={4}
            w="100%"
            h="100%"
          >
            <GridItem colSpan={1}>
              <Flex
                w="100%"
                h="100%"
                bg="white"
                borderRadius="md"
                alignItems={'center'}
                p={4}
                gap={6}
              > 
                <Box p={2} borderRadius={'50%'} bg={'#FDEDE5'}>
                  <BiGitMerge style={{width:"30px", height:'30px', color:'#F5793D'}}/>
                </Box>
                <Box flexGrow={'1'}>
                  <Text>
                    {
                      data?.eventOrganizers || 0
                    }
                  </Text>
                  <Text
                    fontSize={'xs'}
                    color={'gray.500'}
                  >
                    Total Organising Companies
                  </Text>
                </Box>
              </Flex>
            </GridItem>
            <GridItem colSpan={1}>
              <Flex
                w="100%"
                h="100%"
                bg="white"
                borderRadius="md"
                alignItems={'center'}
                p={4}
                gap={6}
              > 
                <Box p={2} borderRadius={'50%'} bg={'#FDEDE5'}>
                  <MdEmojiEvents style={{width:"30px", height:'30px', color:'#F5793D'}}/>
                </Box>
                <Box flexGrow={'1'}>
                  <Text>
                    {
                      data?.events || 0
                    }
                  </Text>
                  <Text
                    fontSize={'xs'}
                    color={'gray.500'}
                  >
                    Total Events
                  </Text>
                </Box>
              </Flex>
            </GridItem>
            <GridItem colSpan={1}>
              <Flex
                w="100%"
                h="100%"
                bg="white"
                borderRadius="md"
                alignItems={'center'}
                p={4}
                gap={6}
              > 
                <Box p={2} borderRadius={'50%'} bg={'#FDEDE5'}>
                  <BsPersonWorkspace style={{width:"30px", height:'30px', color:'#F5793D'}}/>
                </Box>
                <Box flexGrow={'1'}>
                  <Text>
                    {
                      data?.exhibitors || 0
                    }
                  </Text>
                  <Text
                    fontSize={'xs'}
                    color={'gray.500'}
                  >
                    Total Exhibitors
                  </Text>
                </Box>
              </Flex>
            </GridItem>
            <GridItem colSpan={1}>
              <Flex
                w="100%"
                h="100%"
                bg="white"
                borderRadius="md"
                alignItems={'center'}
                p={4}
                gap={6}
              > 
                <Box p={2} borderRadius={'50%'} bg={'#FDEDE5'}>
                  <BsFillPersonFill style={{width:"30px", height:'30px', color:'#F5793D'}}/>
                </Box>
                <Box flexGrow={'1'}>
                  <Text>
                    {
                      data?.visitors || 0
                    }
                  </Text>
                  <Text
                    fontSize={'xs'}
                    color={'gray.500'}
                  >
                    Total Visitors
                  </Text>
                </Box>
              </Flex>
            </GridItem>
          </Grid>
    </Box>
  )
}

export default Dashboard