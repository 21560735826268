import { Box, HStack, Text, VStack, useMediaQuery } from "@chakra-ui/react"
import { MdOutlineLocationOn, MdOutlineCalendarMonth, MdOutlineHandshake } from "react-icons/md";
import moment from "moment"

type Props = {
    eventName: string;
    startDate: string;
    endDate: string;
    eventData: {
        eventVenue: string;
        eventCity: string;
    };
    orgCompanyName: string;
}

const EventListItem = (props: Props) => {

    const [isScreenWidthSufficient] = useMediaQuery(['(min-width: 490px)'])

    return (
        <Box
            mt={"10px"}
            p={"20px"}
            _hover={{
                shadow: "md",
            }}
            border={"1px solid #D2D2D2"}
            borderRadius={"15px"}
            shadow={"sm"}
            backgroundColor={'body.cardBg'}
        >
            {isScreenWidthSufficient ? (<HStack justifyContent={"space-between"} mb={"8px"}>

                <Text fontSize={"md"} fontWeight={"semibold"}>
                    {props.eventName}
                </Text>

                <HStack gap={"0 .25rem"}>
                    <MdOutlineCalendarMonth color={"#1C1B1F"} size={18} fontWeight={"light"} />
                    <Text fontSize={"sm"} fontWeight={"light"}>
                        {moment(
                            props.startDate
                        )?.format("DD MMM")}{" "}
                        -{" "}
                        {moment(
                            props.endDate
                        )?.format("DD MMM YYYY")}
                    </Text>
                </HStack>
            </HStack>) : (<VStack alignItems={'flex-start'}>
                <Text fontSize={"md"} fontWeight={"semibold"} mb={'8px'}>
                    {props.eventName}
                </Text>

                <HStack gap={"0 .25rem"} mb={'8px'}>
                    <MdOutlineCalendarMonth color={"#1C1B1F"} size={18} fontWeight={"light"} />
                    <Text fontSize={"sm"} fontWeight={"light"}>
                        {moment(
                            props.startDate
                        )?.format("DD MMM")}{" "}
                        -{" "}
                        {moment(
                            props.endDate
                        )?.format("DD MMM YYYY")}
                    </Text>
                </HStack>
            </VStack>) }
            <HStack gap={"0 .25rem"} mb={"8px"}>
                <MdOutlineLocationOn color={"#1C1B1F"} size={15} fontWeight={"light"} />
                <Text fontSize={"sm"} fontWeight={"light"}>
                    {props.eventData.eventVenue},{" "}
                    {props.eventData.eventCity}
                </Text>
            </HStack>
            <HStack gap={"0 .25rem"} mb={"8px"}>
                <MdOutlineHandshake color={"#1C1B1F"} size={15} fontWeight={"light"} />
                <Text fontSize={"sm"} fontWeight={"light"}>
                    {
                        props.orgCompanyName
                    }
                </Text>
            </HStack>
        </Box>
    );
}

export default EventListItem