import React from "react";
import { Box, Button, Flex, Text, VStack, Image, HStack } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { IoMdNotificationsOutline } from "react-icons/io";
import { Images } from "../../Assets/images";

type Props = {
    personName: string;
    companyName: string;
    unreadNotifications: string;
    role: string;
    navigateUrl: string;
};

const Jumbotron = (props: Props) => {
    const navigate = useNavigate();

    return (
        <Flex
            alignItems={"center"}
            w={"100%"}
            bg={"primary.main"}
            bgImage={Images.headerElements}
            backgroundSize="cover"
            direction={"column"}
            // backgroundRepeat="no-repeat"
            // backgroundPosition="center"
            py={"40px"}
            px={"30px"}
        >
            <HStack w={"100%"} justify={"end"}>
                
            <Button
                w={"40px"}
                h={"40px"}
                borderRadius={"10px"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                bg={"secondary.orange"}
                fontSize={"20px"}
                position={"relative"}
                _hover={{ backgroundColor: "secondary.lightHover" }}
                onClick={() => {
                    navigate(props.navigateUrl);
                }}
            >
                <span>
                    <IoMdNotificationsOutline size={22} color={"white"} />
                </span>
                <Box
                    position={"absolute"}
                    top={-2}
                    right={-2}
                    h={"14px"}
                    w={"14px"}
                    rounded={"full"}
                    bg={props.unreadNotifications && props.unreadNotifications != "0" ? "button.green" : "gray.500"}
                    fontSize={8}
                    color={"white"}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                >
                    {props.unreadNotifications || 0}
                </Box>
            </Button>
            </HStack>
            <VStack w={"100%"} textAlign={"left"} alignItems={"center"} justifyContent={"center"}>
                <Box 
                    bg={"white"}
                    px={"30px"}
                    py={"5px"}
                    borderRadius={"30px"}
                >
                <Text fontSize={"lg"} fontWeight={400} color={"secondary.orange"}>
                    {props.role}
                </Text>
                </Box>
                <Text fontSize={"2xl"} fontWeight={500} color={"text.yellow"}>
                    Welcome !
                </Text>
                <Text fontSize={"xl"} fontWeight={"light"} color={"white"}>
                    {props.companyName}
                </Text>
            </VStack>
        </Flex>
    );
}

export default Jumbotron;