import {
  AbsoluteCenter,
  Box,
  Button,
  HStack,
  Link,
  Text,
  Textarea,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Header from "../../Components/Common/Header";
import { Modal, Select } from "antd";
import { ExhibitorAPI } from "../../Apis/exhibitorAPI";
import { useToastWrapper } from "../../Wrapper/toastWrapper";
import { errorHandler } from "../../Utils/handler";
import { MdAddLink, MdDeleteOutline } from "react-icons/md";
import AddUrlModal from "../../Components/Modals/AddUrlModal";
import ConfirmationModal from "../../Components/Modals/ConfirmationModal";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../redux/Actions";

type Props = {};

type LinkItemProps = {
  id: string;
  title: string;
  url: string;
};

const ProductsAndServices = (props: Props) => {
  const exhibitorAsset = useSelector((state: any) => state.exhibitorAsset);
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.user);

  const [userPlan, setUserPlan] = useState<any>({});

  const [tags, setTags] = useState<string[]>([]);
  const [exhibitorId, setExhibitorId] = useState<string>("");
  const [assetDeleteId, setAssetDeleteId] = useState<string>("");
  const [deleteConfirm, setDeleteConfirm] = useState<boolean>(false);
  const handleOpenDeleteConfirm = () => setDeleteConfirm(true);
  const handleCloseDeleteConfirm = () => {
    setAssetDeleteId("");
    setDeleteConfirm(false);
  };

  const { error, success } = useToastWrapper();

  // add url Modal
  const [addUrl, setAddUrl] = useState(false);
  const handleOpenAddUrlModal = () => setAddUrl(true);
  const handleCloseAddUrlModal = () => {
    dispatch(actions.exhibitorAssets.resetForm({}));
    setAddUrl(false);
  };

  const getPlanDetails = async () => {
    try {
      const userId = user.user;
      const [planDetails] = await Promise.all([
        ExhibitorAPI.getEventExhibitorProfile(userId),
      ]);

      if (planDetails) {
        setUserPlan(planDetails?.data?.data);
      }
    } catch (err) {
      error("Unable to get subscription details!");
    }
  };

  const LinkItem: React.FC<LinkItemProps> = ({ id, title, url }) => {
    return (
      <Box
        key={id}
        width={"100%"}
        pt={4}
        pb={4}
        pl={2}
        pr={2}
        mb={2}
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        border={"1px"}
        borderColor={"gray.200"}
      >
        <Link href={url} textColor={"card.borderBlue"} isExternal>
          {title}
        </Link>
        <Box
          cursor={"pointer"}
          onClick={() => {
            setAssetDeleteId(id);
            handleOpenDeleteConfirm();
          }}
        >
          <MdDeleteOutline size={22} color="black" />
        </Box>
      </Box>
    );
  };

  const getData = async () => {
    try {
      const res = await ExhibitorAPI.getExhibitorProfile();
      if (res) {
        setTags(res.data.data?.productTypeTags);
        setExhibitorId(res.data.data?.userId);
      }
    } catch (err) {
      console.log(err);
      error(errorHandler(err));
    }
  };

  const getAssets = async () => {
    try {
      const res = await ExhibitorAPI.getAssets();
      if (res) {
        console.log("this is res inside add url..", res);

        dispatch(actions.exhibitorAssets.setAssets({ links: res.data.data }));
      }
    } catch (err) {
      console.log(err);
      error(errorHandler(err));
    }
  };

  const handleUpdate = async () => {
    if (tags.length === 0) {
      error("Please add atleast one tag", "");
      return;
    }
    if (tags.length > userPlan.exhibitorTagsLimit) {
      error(
        "Max number of tags that can be created is " +
          userPlan.exhibitorTagsLimit,
        ""
      );
      return;
    }
    try {
      const res = await ExhibitorAPI.updateExhibitorProfile({
        productTypeTags: tags,
      });
      if (res) {
        success("Updated Successfully");
      }
    } catch (err) {
      console.log(err);
      error(errorHandler(err));
    }
  };

  const deleteExhibitorAsset = async (assetId: string) => {
    try {
      const res = await ExhibitorAPI.deleteAsset(assetId);
      if (res) {
        getAssets();
        success("Asset deleted", "");
      }
    } catch (err) {
      console.log(err);
      error(errorHandler(err));
    }
  };

  useEffect(() => {
    getData();
    getAssets();
    getPlanDetails();
  }, []);

  return (
    <Box
      w={"100%"}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      //   bg={"linear.100"}
      minH={"100vh"}
      p={6}
      maxH={"100vh"}
    >
      <Modal
        closeIcon={null}
        footer={null}
        title={null}
        open={addUrl}
        onOk={handleCloseAddUrlModal}
        onCancel={handleCloseAddUrlModal}
      >
        <AddUrlModal
          addUrl
          handleCloseModal={handleCloseAddUrlModal}
          exhibitorId={exhibitorId}
        />
      </Modal>

      <Modal
        closeIcon={null}
        footer={null}
        title={null}
        open={deleteConfirm}
        onOk={handleCloseDeleteConfirm}
        onCancel={handleCloseDeleteConfirm}
      >
        <ConfirmationModal
          handleCloseModal={handleCloseDeleteConfirm}
          handleSubmit={() => {
            deleteExhibitorAsset(assetDeleteId);
            handleCloseDeleteConfirm();
          }}
          title={"Delete URL"}
          message={
            "Are you sure you want to delete this URL? This action cannot be undone."
          }
          confirmBtnText={"Yes, Delete"}
          rejectButtonText={"No"}
        />
      </Modal>

      <Box w={"100%"}>
        <Header backButton>Products & Services</Header>
      </Box>

      <Text mt={"28px"} size={"14px"}>
        Add your list of product / service types and names. Visitors will be
        able to search by these values.
      </Text>

      <Box w={"100%"}>
        <Select
          className="product-and-services"
          mode="tags"
          value={tags}
          style={{ width: "100%" }}
          onChange={(e) => {
            setTags(e);
          }}
        />
      </Box>

      <Button
        onClick={handleUpdate}
        bg={"primary.main"}
        color={"white"}
        size={"lg"}
        w={"100%"}
        mt={"24px"}
        fontSize={"md"}
        borderRadius={"16px"}
      >
        Save
      </Button>
      <Box
        w={"100%"}
        display={"flex"}
        justifyContent={"space-between"}
        mt={6}
        mb={2}
      >
        <Text fontSize={"18px"} fontWeight={700}>
          Digital Assets
        </Text>
        <Box
          cursor={"pointer"}
          onClick={() => {
            console.log(
              "exhibitorAsset.links.length..",
              exhibitorAsset.links.length
            );
            console.log(
              "userPlan.digitalAssetsLimit..",
              userPlan.digitalAssetsLimit
            );
            if (exhibitorAsset.links.length >= userPlan.digitalAssetsLimit) {
              return error(
                "Max number of digital assets that can be created is " +
                  userPlan.digitalAssetsLimit
              );
            } else {
              handleOpenAddUrlModal();
            }
          }}
        >
          <MdAddLink size={32} color={"black"} />
        </Box>
      </Box>
      {typeof exhibitorAsset.links != "string" &&
      exhibitorAsset.links.length > 0 ? (
        exhibitorAsset.links.map((link: any) => (
          <LinkItem id={link._id} title={link.assetTitle} url={link.assetUrl} />
        ))
      ) : (
        <Text fontSize={"14px"} fontWeight={"light"}>
          No assets attached
        </Text>
      )}
    </Box>
  );
};

export default ProductsAndServices;
