export const colors = {
  primary: {
    main: "#0E122F",
    blue: "#0E122F",
  },
  secondary: {
    main: "#F5793D",
    orange: "#F5793D",
    light: "#FEF2EC",
    lightHover: "#F9AB86",

  },
  accordian_bg:{
    main: '#f1edd0'
  },
  acc_dark: {
    main: "#0E122F",
  },
  alternate: {
    main: "#FF5300",
  },
  heading: {
    main: "#333333",
  },
  button: {
    yellow: "#FEBA08",
    green: "#4CAF50",
    red: "#FF3D00",
  },
  text: {
    black: "#121927",
    yellow: "#FFC727",
    black2: "#1A202C",
    caption: "#9b9b9b"
  },
  visitors:{
    review:"#FF5F12",
    relevant:"#159300",
    existing:"#0E122F",
    notRelevant:"#9B9B9B"
  },
  meetings:{
    confirmed:"#006CFF",
    received:"#FF975C",
    sent:"#FFC727",
    completed:"#0013BC"
  },
  icon:{
    main: "#1C1B1F",
    hover: "#EEECEC"
  },
  card:{
    border: "#D2D2D2",
    borderDark: "#D3D3D3",
    borderBlue: "#006CFF",
    borderLight: "#D9D9D9",
    bgPink: "#FFD2CD"
  },
  body:{
    bg:"#F7F7F7",
    cardBg: '#FFFFFF'
  },
  linear: {
    100: "linear-gradient(180deg, #050A30 0%, rgba(5, 10, 48, 0.00) 444.05%)",
    200: "linear-gradient(180deg, #FFEBDC 0%, rgba(255, 235, 220, 0.4) 100%)",
  },
  radial: {
    100: "radial-gradient(220.05% 132.6% at -47.33% -13.5%, #FFDB87 0.27%, #FFDB85 43.46%, #FFB6B0 65.44%, #FFB3B4 100%)",
  },
};
