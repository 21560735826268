import { useSelector } from "react-redux";
import { api } from "./config/axiosConfig.js";
import { defineCancelApiObject } from "./config/axiosUtils.js";
import { store } from "../redux/Store.ts";
import { errorResponseHandler, responseHandler } from "../Utils/handler.ts";

export const ExhibitorAPI = {
  /////////////////////////
  // EVENTS
  ////////////////////////

  getEvents: async (params, cancel = false) => {
    const { token } = await store.getState().user;

    try {
      const response = await api.request({
        url: "/exhibitors/events/",
        method: "GET",
        params: params,
        headers: { Authorization: `Bearer ${token}` },
        signal: cancel
          ? cancelApiObject[this.get.name].handleRequestCancellation().signal
          : undefined,
      });
      return responseHandler(response);
    } catch (err) {
      throw errorResponseHandler(err);
    }
  },

  getVisitorCompanies: async (params, cancel = false) => {
    const { token } = await store.getState().user;

    const response = await api.request({
      url: "exhibitors/visitor/companies",
      method: "GET",
      params: params,
      headers: { Authorization: `Bearer ${token}` },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return responseHandler(response);
  },

  getVisitorCompaniesV2: async (params, cancel = false) => {
    const { token } = await store.getState().user;

    const response = await api.request({
      url: "exhibitors/v2/visitor/companies",
      method: "GET",
      params: params,
      headers: { Authorization: `Bearer ${token}` },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return responseHandler(response);
  },

  checkEmailExists: async (params, cancel = false) => {
    const { token } = await store.getState().user;

    const response = await api.request({
      url: "exhibitors/visitors",
      method: "GET",
      params: params,
      headers: { Authorization: `Bearer ${token}` },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return responseHandler(response);
  },

  addNewVisitorToList: async (data, cancel = false) => {
    const { token } = await store.getState().user;

    const response = await api.request({
      url: "exhibitors/visitors",
      method: "POST",
      data: data,
      headers: { Authorization: `Bearer ${token}` },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return responseHandler(response);
  },

  getVisitors: async (eventId, params, cancel = false) => {
    const { token } = await store.getState().user;

    const response = await api.request({
      url: "exhibitors/v2/" + eventId,
      method: "GET",
      params: params,
      headers: { Authorization: `Bearer ${token}` },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return responseHandler(response);
  },

  getExhibitorProfile: async (cancel = false) => {
    const { token } = await store.getState().user;

    const response = await api.request({
      url: "exhibitors",
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return responseHandler(response);
  },

  getEventExhibitorProfile: async (userId, cancel = false) => {
    const { token } = await store.getState().user;

    const response = await api.request({
      url: "exhibitors/event-exhibitor/" + userId,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return responseHandler(response);
  },

  updateExhibitorProfile: async (data, cancel = false) => {
    const { token } = await store.getState().user;

    const response = await api.request({
      url: "exhibitors",
      method: "PUT",
      data: data,
      headers: { Authorization: `Bearer ${token}` },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return responseHandler(response);
  },

  PostRelevanceVisitor: async (data, cancel = false) => {
    const { token } = await store.getState().user;

    const response = await api.request({
      url: "pre-qualify/visitor",
      method: "POST",
      data: data,
      headers: { Authorization: `Bearer ${token}` },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return responseHandler(response);
  },

  PostAnnouncement: async (data, cancel = false) => {
    const { token } = await store.getState().user;

    const response = await api.request({
      url: "announcements",
      method: "POST",
      data: data,
      headers: { Authorization: `Bearer ${token}` },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return responseHandler(response);
  },

  GetAnnouncement: async (cancel = false) => {
    const { token } = await store.getState().user;

    const response = await api.request({
      url: "announcements",
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return responseHandler(response);
  },

  GetDashboard: async (cancel = false) => {
    const { token } = await store.getState().user;

    const response = await api.request({
      url: "exhibitors/dashboard",
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return responseHandler(response);
  },

  getAssets: async (cancel = false) => {
    const { token } = await store.getState().user;

    const response = await api.request({
      url: "exhibitors/digital-assets",
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return responseHandler(response);
  },

  getAssetsByUserId: async (userId, cancel = false) => {
    const { token } = await store.getState().user;

    const response = await api.request({
      url: "exhibitors/digital-assets/" + userId,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return responseHandler(response);
  },

  addAsset: async (data, cancel = false) => {
    const { token } = await store.getState().user;

    const response = await api.request({
      url: "exhibitors/digital-assets",
      method: "POST",
      data: data,
      headers: { Authorization: `Bearer ${token}` },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return responseHandler(response);
  },

  deleteAsset: async (assetId, cancel = false) => {
    const { token } = await store.getState().user;

    const response = await api.request({
      url: "exhibitors/digital-assets/" + assetId,
      method: "DELETE",
      headers: { Authorization: `Bearer ${token}` },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return responseHandler(response);
  },
};

const cancelApiObject = defineCancelApiObject(ExhibitorAPI);
