import { Box, Button, Flex, FormControl, FormLabel, Image, Input, InputGroup, InputLeftElement } from '@chakra-ui/react'
import React from 'react'
import Header from '../../Components/Common/Header'
import { Icons } from '../../Assets/icons'
import { color } from 'framer-motion'

type Props = {}

const Profile = (props: Props) => {
    return (
        <Box flexGrow={1} p={6}>
            <Header backButton>
                My Profile
            </Header>
            <Flex direction={'column'} w={'100%'} mt={'20px'} gap={'30px'}>

                {/* Full Name */}
                <Flex w={'100%'}>
                    <Flex display={'flex'} gap={'8px'} w={'100%'}>
                        <Input h={'50px'} type='text' placeholder='Mr' w={'60px'} borderColor={'text.black'} color={'text.black'} _placeholder={{ color: 'text.black' }} borderRadius={'16px'} />
                        <InputGroup flexGrow={1} borderColor={'text.black'} >
                            <InputLeftElement h={'50px'} pointerEvents='none'>
                                <Image src={Icons.ProfileFullName} alt="" />
                            </InputLeftElement>
                            <Input h={'50px'} type='text' placeholder='Full Name' color={'text.black'} _placeholder={{ color: 'text.black' }} borderRadius={'16px'} />
                        </InputGroup>
                    </Flex>
                </Flex>

                {/* Company name */}
                <InputGroup flexGrow={1} borderColor={'text.black'} >
                    <InputLeftElement h={'50px'} pointerEvents='none'>
                        <Image src={Icons.ProfileCompanyName} alt="" />
                    </InputLeftElement>
                    <Input h={'50px'} type='text' placeholder='Company Name' color={'text.black'} _placeholder={{ color: 'text.black' }} borderRadius={'16px'} />
                </InputGroup>

                {/* Designation */}
                <InputGroup flexGrow={1} borderColor={'text.black'} >
                    <InputLeftElement h={'50px'} pointerEvents='none'>
                        <Image src={Icons.ProfileDesignation} alt="" />
                    </InputLeftElement>
                    <Input h={'50px'} type='text' placeholder='Designation' color={'text.black'} _placeholder={{ color: 'text.black' }} borderRadius={'16px'} />
                </InputGroup>

                {/* Work Email */}
                <InputGroup flexGrow={1} borderColor={'text.black'} >
                    <InputLeftElement h={'50px'} pointerEvents='none'>
                        <Image src={Icons.ProfileWorkEmail} alt="" />
                    </InputLeftElement>
                    <Input h={'50px'} type='text' placeholder='Work Email' color={'text.black'} _placeholder={{ color: 'text.black' }} borderRadius={'16px'} />
                </InputGroup>

                {/* Social Email */}
                <InputGroup flexGrow={1} borderColor={'text.black'} >
                    <InputLeftElement h={'50px'} pointerEvents='none'>
                        <Image src={Icons.ProfileWorkEmail} alt="" />
                    </InputLeftElement>
                    <Input h={'50px'} type='text' placeholder='Social Email' color={'text.black'} _placeholder={{ color: 'text.black' }} borderRadius={'16px'} />
                </InputGroup>

                {/* Phone Number */}
                <InputGroup flexGrow={1} borderColor={'text.black'} >
                    <InputLeftElement h={'50px'} pointerEvents='none'>
                        <Image src={Icons.ProfilePhoneNumber} alt="" />
                    </InputLeftElement>
                    <Input h={'50px'} type='text' placeholder='Phone Number' color={'text.black'} _placeholder={{ color: 'text.black' }} borderRadius={'16px'} />
                </InputGroup>

                {/* Alternate Phone Number */}
                <InputGroup flexGrow={1} borderColor={'text.black'} >
                    <InputLeftElement h={'50px'} pointerEvents='none'>
                        <Image src={Icons.ProfilePhoneNumber} alt="" />
                    </InputLeftElement>
                    <Input h={'50px'} type='text' placeholder='Alternate Phone Number' color={'text.black'} _placeholder={{ color: 'text.black' }} borderRadius={'16px'} />
                </InputGroup>

                {/* Company Website */}
                <InputGroup flexGrow={1} borderColor={'text.black'} >
                    <InputLeftElement h={'50px'} pointerEvents='none'>
                        <Image src={Icons.ProfileCompanyWebsite} alt="" />
                    </InputLeftElement>
                    <Input h={'50px'} type='text' placeholder='Company Website' color={'text.black'} _placeholder={{ color: 'text.black' }} borderRadius={'16px'} />
                </InputGroup>
            </Flex>

            {/* Buttons */}
            <Flex w={'100%'} justifyContent={'space-between'} mt={'40px'}>
                <Button w={'45%'} size={'sm'} background={'primary.main'} color={'white'} h={'40px'} borderRadius={'14px'}>
                    Update
                </Button>
                <Button w={'45%'} size={'sm'} background={'secondary.main'} color={'white'} h={'40px'} borderRadius={'14px'}>
                    Skip for now
                </Button>
            </Flex>
            <Button background={'transparent'} color={'button.red'} w={'100%'} h={'40px'} mt={'12px'}>
                Delete My Account
            </Button>
        </Box>
    )
}

export default Profile