import { Box, Flex, Text, VStack } from "@chakra-ui/react";
import React from "react";
import Header from "../../Components/Common/Header";

type Props = {};

const SubscriptionStatement = (props: Props) => {
  return (
    <Box flexGrow={1} p={6} w={"100%"}>
      <Header backButton>Subscription Statement</Header>
      <VStack>
        {[0, 0, 0, 0, 0, 0].map(() => (
          <Box
            w={"100%"}
            bg={"white"}
            p={3}
            rounded={"18px"}
            mt={3}
            px={5}
            py={4}
            display={"flex"}
            gap={5}
            justifyContent={"space-between"}
            alignItems={"start"}
            flexDir={"column"}
            shadow={"md"}
            minH={"95px"}
          >
            <Box>
              <Text
                fontSize={"15px"}
                fontWeight={"semibold"}
                color={"gray.600"}
              >
                12 Dec 2023
              </Text>
              <Text fontSize={"12px"} mt={2} maxW={"200px"}>
                Connects Used
              </Text>
            </Box>
            <Flex
              justifyContent={"space-between"}
              alignItems={"center"}
              width={"100%"}
            >
              <Flex alignItems={"center"} gap={2}>
                <Text fontSize={"14px"}>value:</Text>
                <Text fontSize={"14px"} color={"red"}>
                  -100
                </Text>
              </Flex>
              <Flex alignItems={"center"} gap={2}>
                <Text fontSize={"14px"} fontWeight={"semibold"}>
                  Balance:
                </Text>
                <Text fontSize={"14px"} fontWeight={"semibold"}>
                  30
                </Text>
              </Flex>
            </Flex>
          </Box>
        ))}
      </VStack>
    </Box>
  );
};

export default SubscriptionStatement;
