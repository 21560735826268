import { Flex, Box, Text, Button } from "@chakra-ui/react"
import moment from "moment"

type Props = {
    companyName: string;
    proposedDate: string;
    proposedTime: string;
    joinButtonClick: () => void;
    disableButton: boolean;
}

const MeetingListItem = ( props: Props) => {
    return (
        <Flex alignItems={"center"} justifyContent={"space-between"}>
            <Box>
                <Flex alignItems={"center"} gap={3}>
                    <Text fontSize={"sm"} fontWeight={"medium"}>
                        {props.companyName}
                    </Text>
                </Flex>
                <Text mt={"6px"} fontSize={"xs"}>
                    {moment(props.proposedDate)?.format("DD MMM")}{" "}
                    | {props.proposedTime}
                </Text>
            </Box>
            <Button
                                onClick={props.joinButtonClick}
                                colorScheme="transparent"
                                variant={"outline"}
                                borderColor={"secondary.main"}
                                w={"55px"}
                                color={"text.black2"}
                                _hover={{ background: "secondary.light" }}
                                h={"25px"}
                                isDisabled={props.disableButton ? true : false}
                            >
                                <Text fontSize={"small"} fontWeight={"light"}>Join</Text>
                            </Button>
        </Flex>
    );
}


export default MeetingListItem;