import { Box, Button, Flex, Input, Text, VStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import { Modal, Select } from "antd";

//// Icons

import { FaAngleLeft, FaRegCalendarAlt } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Header from "../../Components/Common/Header";
import { ExhibitorAPI } from "../../Apis/exhibitorAPI";
import { VisitorInterface } from "../../Interface/CommonInterface";
import { errorHandler } from "../../Utils/handler";
import { useToastWrapper } from "../../Wrapper/toastWrapper";
import Loader from "../../Components/Loader/Loader";
import NotesModal from "../../Components/Modals/NotesModal";
import { IoChatboxEllipsesOutline } from "react-icons/io5";
import CreateMeetModal from "../../Components/Modals/CreateMeetModal";

const Company = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState({
    company: "",
    event: "",
  });

  const [searchParams, setSearchParams] = useSearchParams();

  const [visitors, setVisitors] = useState<VisitorInterface[]>([]);
  const { success, error } = useToastWrapper();

    //Meet Modal
    const [meetModal, setMeetModal] = useState(false);
    const handleOpenMeetModal = () => setMeetModal(true);
    const handleCloseMeetModal = () => setMeetModal(false);
    const [meetResponder, setMeetResponder] = useState<any>();


  const [notesModal, setNotesModal] = useState(false);
  const handleOpenNotesModal = () => setNotesModal(true);
  const handleCloseNotesModal = () => setNotesModal(false);
  const [notesData, setNotesData] = useState<any>({
    forUser: "",
    eventId: "",
  });

  useEffect(() => {
    const obj = {
      company: "",
      event: "",
    };

    if (searchParams?.get("event")) {
      obj.event = searchParams?.get("event") || "";
    }

    if (searchParams?.get("company")) {
      obj.company = searchParams?.get("company") || "";
    }

    if (obj.event) {
      getVisitors(obj.event, obj?.company);
      setNotesData({
        ...notesData,
        eventId: obj.event,
      });
    }
    setDetails({ ...obj });
  }, [searchParams]);

  const getVisitors = async (eventId: string, company: string) => {
    try {
      setLoading(true);

      const data = await ExhibitorAPI.getVisitors(eventId, {
        companyName: company,
      });

      if (data.success) {
        setVisitors(data?.data?.data);
      }
    } catch (err: any) {
      const errMsg = errorHandler(err);
      error(errMsg);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      w={"100%"}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      bg={"linear.100"}
      minH={"100vh"}
      maxH={"100vh"}
    >

       {/* Modal for Meet */}
       <Modal
        closeIcon={null}
        footer={null}
        title={null}
        open={meetModal}
        onOk={handleCloseMeetModal}
        onCancel={handleCloseMeetModal}
      >
        <CreateMeetModal responderId={meetResponder?.userId} eventId={details.event} company={meetResponder?.visitorPersonName} handleCloseModal={handleCloseMeetModal}/>
      </Modal>


      <Modal
        closeIcon={null}
        footer={null}
        title={null}
        open={notesModal}
        onOk={handleCloseNotesModal}
        onCancel={handleCloseNotesModal}
      >
        <NotesModal
          eventId={notesData.eventId}
          forUser={notesData.forUser}
          handleCloseModal={handleCloseNotesModal}
        />
      </Modal>
      {loading && <Loader />}
      <VStack w={"100%"} px={"30px"}>
        {/* <Flex
          mt={"30px"}
          alignItems={"center"}
          justifyContent={"space-between"}
          w={"100%"}
          gap={"20px"}
        >
          <Button
            w={"40px"}
            h={"40px"}
            borderRadius={"10px"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            bg={"transparent"}
            border={"1px solid #ffffffB3"}
            fontSize={"20px"}
          >
            <span>
              <FaAngleLeft size={22} color="#ffffffB3" />
            </span>
          </Button>
          <VStack w={"100%"} textAlign={"left"} alignItems={"flex-start"}>
            <Text fontSize={"lg"} fontWeight={600} color={"#fff"}>
              Company Staff Details
            </Text>
          </VStack>
        </Flex> */}

        <Box width={"100%"} mt={"30px"}>
          <Header backButton isWhite>
            {details?.company}
          </Header>
        </Box>

        <Box
          p={"20px"}
          w={"100%"}
          mt={"30px"}
          bg={"white"}
          border={"1px solid #E2E8F0"}
          borderRadius={"15px"}
          shadow={"sm"}
        >
          <Text fontWeight={600}>{details?.company}</Text>
        </Box>
      </VStack>

      <Box
        w={"100%"}
        bg={"white"}
        pt={"30px"}
        // marginTop={"auto"}
        h={"100%"}
        flex={1}
        marginTop={"30px"}
        minH={"100%"}
        overflowY={"auto"}
        overflowX={"hidden"}
        pb={"100px"}
        borderTopRadius={"40px"}
        px={"30px"}
      >
        <Text fontWeight={"semibold"} mb={2}>
          Employees
        </Text>
        <VStack w={"100%"} spacing={4}>
          {[...visitors].map((item, index) => (
            <Box
              key={"visitor_" + index}
              w={"100%"}
              // border={"1px solid #E2E8F0"}
              p={"15px 20px"}
              borderRadius={"10px"}
              shadow={"md"}
              display={"flex"}
              flexDirection={"column"}
              _hover={{
                shadow: "lg",
              }}
              position={"relative"}
            >
              <Text fontWeight={600}>{item?.visitorPersonName}</Text>
              <Flex>{/* <Text fontSize={"sm"}>12.03.2023 19:03</Text> */}</Flex>
              <Flex>
                <Text fontSize={"sm"}>{item?.visitorDesignation}</Text>
              </Flex>

              <Flex justifyContent={"space-between"} mt={5}>
                <Button colorScheme="transparent">
                  <span>
                    <FaRegCalendarAlt
                      onClick={() => {
                        handleOpenMeetModal()
                        setMeetResponder(item)
                      }}
                      size={22}
                      color="#000"
                    />
                  </span>
                </Button>
                <Button colorScheme="transparent">
                  <span>
                    <IoChatboxEllipsesOutline
                      onClick={() => {
                        navigate("/exhibitor/chat?user=" + item?.userId+"&event="+ details.event+"&m="+item?._id, {
                          state: {
                            visitor: item,
                          },
                        });
                      }}
                      size={22}
                      color="#000"
                    />
                  </span>
                </Button>
                <Button colorScheme="transparent">
                  <span>
                    <FiEdit
                      onClick={() => {
                        handleOpenNotesModal();
                        setNotesData((prev: any) => ({
                          ...prev,
                          forUser: item?._id,
                        }));
                      }}
                      size={22}
                      color="#000"
                    />
                  </span>
                </Button>
              </Flex>

              {/* <Button
                mx={"auto"}
                p={"10px"}
                w={"200px"}
                bg={"#2869FE1a"}
                color={"#2869FE"}
                mt={"30px"}
                onClick={() => {
                  navigate("/exhibitor/chat?user=" + item?.userId+"&event="+ details.event+"&m="+item?._id, {
                    state: {
                      visitor: item,
                    },
                  });
                }}
              >
                Connect
              </Button>

              <Button
                position={"absolute"}
                top={0}
                right={0}
                colorScheme="transparent"
              >
                <span>
                  <FiEdit onClick={()=>{
                    handleOpenNotesModal()
                    setNotesData((prev:any)=>({
                      ...prev,
                      forUser: item?._id,
                    }))
                  }} size={22} color="#000" />
                </span>
              </Button> */}
            </Box>
          ))}
        </VStack>
      </Box>
    </Box>
  );
};

export default Company;
