import { Box, Image, List, OrderedList, Text } from "@chakra-ui/react";
import React from "react";
import { Icons } from "../../Assets/icons";

type Props = {
  handleCloseModal: () => void;
};

const FAQModal = (props: Props) => {
  return (
    <Box>
      <Box
        position={"relative"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        h={"60px"}
        w={"100%"}
      >
        <Text
          fontSize={"20px"}
          fontWeight={700}
          textAlign={"center"}
          color={"black"}
        >
          FAQ
        </Text>
        <Image
          onClick={() => {
            props.handleCloseModal();
          }}
          position={"absolute"}
          src={Icons.cross}
          right={0}
          top={0}
        />
      </Box>
      <Box>
        <Text fontSize={"20px"} fontWeight={"semibold"}>
          What is Rewards program?
        </Text>
        <Text fontSize={"14px"} mt={2}>
          Rewards program is a loyalty program that rewards you for shopping
          with us. You can earn points on every purchase and redeem them for
          exciting gifts and offers.
        </Text>
      </Box>
      <Box>
        <Text fontSize={"20px"} fontWeight={"semibold"} mt={5}>
          What is Rewards program?
        </Text>
        <Text fontSize={"14px"} mt={2}>
          Rewards program is a loyalty program that rewards you for shopping
          with us. You can earn points on every purchase and redeem them for
          exciting gifts and offers.
        </Text>
      </Box>
      <Box>
        <Text fontSize={"20px"} fontWeight={"semibold"} mt={5}>
          What is Rewards program?
        </Text>
        <Text fontSize={"14px"} mt={2}>
          Rewards program is a loyalty program that rewards you for shopping
          with us. You can earn points on every purchase and redeem them for
          exciting gifts and offers.
        </Text>
      </Box>
    </Box>
  );
};

export default FAQModal;
