import { errorResponseHandler, responseHandler } from "../Utils/handler";
import { store } from "../redux/Store";
import { api } from "./config/axiosConfig";
import { defineCancelApiObject } from "./config/axiosUtils";


export const DashboardAPI = {

    getDashboardData: async (params, cancel = false) => {
        const { token } = await store.getState().user;

        try {
            const response = await api.request({
                url: "admin/dashboard",
                method: "GET",
                params: params,
                headers: { 'Authorization': `Bearer ${token}` },
                signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
            });
            return responseHandler(response);
        } catch (err) {
            throw errorResponseHandler(err)
        }
    }

}

const cancelApiObject = defineCancelApiObject(DashboardAPI);
