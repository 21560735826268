import {
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Image,
  Link,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Header from "../../Components/Common/Header";
import { FiCopy } from "react-icons/fi";
import { Images } from "../../Assets/images";
import FAQModal from "../../Components/Modals/FAQModal";
import { Modal } from "antd";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { VisitorAPI } from "../../Apis/visitorAPI";
import { EventInterface, VisitorInterface } from "../../Interface/CommonInterface";
import moment from "moment";
import { useNavigate } from "react-router-dom";

type Props = {};

const RewardsRedeem = (props: Props) => {
  const navigate = useNavigate();

  const [isModal, setIsModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rewardStatements, setRewardStatements] = useState<any[]>([]);
  const [rewardRedemptions, setRewardRedemptions] = useState<any[]>([]);
  const [redemmablePoints, setRedemmablePoints] = useState<number>(0);
  const [event, setEvent] = useState<EventInterface>();
  const [visitor, setVisitor] = useState<VisitorInterface>();

  const getRewardsAndEvents = async () => {
    try {
      setLoading(true);
      const [res1, res2] = await Promise.all([
        VisitorAPI.getEvents(),
        VisitorAPI.getVisitorProfile(),
      ]);
      if (res1?.success) {
        const arr2 = [...res1.data.data].filter((s) =>
          moment(s.startDate).isBefore(moment())
        );
        const arr = [...arr2];
        setEvent(arr.reverse()[0]);
      }
      if (res2) {
        const params: any = {};
        params["visitorId"] = res2.data.data._id;
        setVisitor(res2.data.data)
        const [data1, data2] = await Promise.all([
          VisitorAPI.getRewardsByVisitorId(params),
          VisitorAPI.getRewardsRedemption(params),
        ]);
        if (data1) {
          setRewardStatements(data1.data.data.rewardsStatements);
        }
        if (data2) {
          setRewardRedemptions(data2.data.data.rewardsRedemption);
        }
      }
    } catch (err: any) {
      console.log(err);
    } finally {
      // setLoading(false);
    }
  };

  const handleOpenModal = () => setIsModal(true);
  const handleCloseModal = () => setIsModal(false);
  const getRedemmablePoints = () => {
    setRedemmablePoints( rewardStatements[0].closingBalance);
  };
  
  useEffect(() => {
    getRewardsAndEvents();
  }, []);
  useEffect(() => {
    if (rewardStatements.length > 0) {
      getRedemmablePoints();
    }
  }, [rewardRedemptions]);
  const FAQButton = () => {
    return (
      <AiOutlineQuestionCircle
        style={{
          position: "absolute",
          right: "0px",
          top: "0px",
          height: "36px",
          width: "36px",
        }}
        onClick={handleOpenModal}
      />
    );
  };
  const sendRedeemRequest = async () => {
    try {
      const res = await VisitorAPI.requestRewardsRedemption({
        eventId: event?._id,
        visitorId: visitor?._id,
      });
      if (res) {
        console.log(res);
        navigate(`/visitor/rewards-redemptiom/${res?.data?.data?.rewardsRedemption?.redemptionRequestId}`);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const onClosePopUp = () => {
    navigate(`/visitor/rewards`);
  }
  return (
    // <Box flexGrow={1} p={6}>
    //   <Header backButton>Rewards Redemption</Header>
    //   <Text
    //     textAlign={"center"}
    //     fontSize={"16px"}
    //     fontWeight={"bold"}
    //     mt={10}
    //     color={"black"}
    //   >
    //     We have received your request for redemption of 500 points.
    //   </Text>
    //   <Text
    //     textAlign={"center"}
    //     fontSize={"16px"}
    //     fontWeight={"bold"}
    //     mt={"54px"}
    //     color={"black"}
    //   >
    //     The reference number for <br />
    //     this request is
    //   </Text>
    //   <Flex justifyContent={"center"} alignItems={"center"} gap={2}>
    //     <Text
    //       textAlign={"center"}
    //       fontSize={"20px"}
    //       fontWeight={"bold"}
    //       color={"secondary.orange"}
    //     >
    //       RR1234567CPC
    //     </Text>
    //     <FiCopy color="#F5793D" />
    //   </Flex>
    //   <Text
    //     textAlign={"center"}
    //     fontSize={"16px"}
    //     fontWeight={"bold"}
    //     mt={"130px"}
    //     color={"black"}
    //   >
    //     Your gift voucher will be emailed to your registered email address.
    //   </Text>
    //   <Text
    //     textAlign={"center"}
    //     fontSize={"12px"}
    //     fontWeight={"medium"}
    //     mt={2}
    //     color={"black"}
    //   >
    //     For any queries, email{" "}
    //     <a
    //       style={{ color: "#F5793D" }}
    //       href="mailto:connect@prontocommerce.com"
    //     >
    //       connect@prontocommerce.com
    //     </a>{" "}
    //     stating the reference number in the subject line.
    //   </Text>
    // </Box>
    <Box p={6} display={"flex"} flexDir={"column"}>
      <Modal
        footer={null}
        title={null}
        open={isModal}
        onCancel={handleCloseModal}
        closeIcon={null}
        onOk={handleCloseModal}
      >
        <FAQModal handleCloseModal={handleCloseModal} />
      </Modal>
      <Header backButton righticon={FAQButton} onClosePopUp={onClosePopUp}>
        Redemption
      </Header>
      <Divider
        mt={5}
        borderWidth="6px"
        opacity={"1"}
        borderColor={"primary.main"}
      />
      <Box p={6} maxW="800px" mx="auto">
        <VStack spacing={6} align="flex-start">
          <Text fontSize={"sm"}>
            The rewards program is created by the event organizer. Reward points
            conversions and redemption are subject to the event organizer’s
            policies and terms and conditions.
          </Text>
          <Text fontSize={"sm"}>
            Reward points are redeemable against gift vouchers (issued via
            email).
          </Text>
          <Text fontSize={"sm"}>
            Once a redemption is initiated, a “Redemption Id” is generated to
            track status.
          </Text>
          <Text fontSize={"sm"}>
            Technical support on redemption related issues is available at
            <Link
              href="mailto:support@prontocommerce.com"
              isExternal
              textDecoration={"underline"}
            >
              {" "}
              support@prontocommerce.com
            </Link>
            .
          </Text>
        </VStack>
      </Box>
      <Box
        mt={5}
        py={10}
        // bg={"#22121927"}
        bg={"#0013BC"}
        bgImage={Images.RewardsBanner}
        rounded={"18px"}
      >
        {/* <Text
          fontSize={"15px"}
          color={"text.black"}
          textAlign={"center"}
          fontWeight={"semibold"}
        >
         You have recieved 
        </Text> */}
        <Text
          fontSize={"36px"}
          color={"white"}
          textAlign={"center"}
          fontWeight={"semibold"}
        >
          You will receive Gift Vouchers worth Rs.
          {redemmablePoints * (event?.rewardPointValue || 1)}
        </Text>
      </Box>
      <Box p={6} maxW="800px" mx="auto">
        <Text fontSize={"sm"}>I accept these Terms and Conditions</Text>
      </Box>
      <HStack justifySelf={"end"} margin={"0px auto"} gap={3}>
        <Button
          flex={1}
          size={"md"}
          p={"0px 20px"}
          bg={"rgb(255, 95, 18)"}
          color={"#fff"}
          onClick={sendRedeemRequest}
        >
          Redeem Now
        </Button>
        <Text
          onClick={() => {
            navigate(-1);
          }}
        >
          Do it Later.
        </Text>
      </HStack>
    </Box>
  );
};

export default RewardsRedeem;
