import { createSlice } from "@reduxjs/toolkit";

const exhibitorMenuSlice = createSlice({
  name: "exhibitorMenu",

  initialState: {
    activeMenu: ""
  },

  reducers: {
    setActiveMenu: (state, action) => {
      state.activeMenu = action.payload.activeMenu
    },
  },
});

export const { setActiveMenu } = exhibitorMenuSlice.actions;

export const exhibitorMenuReducer = exhibitorMenuSlice.reducer;
