import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import Header from "../../Components/Common/Header";
import { useNavigate } from "react-router-dom";

type Props = {};

const SubscriptionCart = (props: Props) => {
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  return (
    <Box
      flexGrow={1}
      p={6}
      minH={"100%"}
      display={"flex"}
      flexDirection={"column"}
      pb={0}
      w={"100%"}
    >
      <Header backButton>Subscription Cart</Header>
      <Box mt={10}>
        <FormControl>
          <FormLabel mb={1} mr={0} fontWeight={"semibold"}>
            Buy Connects
          </FormLabel>
          <Input
            type="number"
            defaultValue={0}
            value={value}
            onChange={(e) => setValue(parseInt(e.target.value))}
            placeholder="Enter minimun 1 connect"
            min={1}
            max={9999}
          />
        </FormControl>
      </Box>
      {value ? (
        <Box mt={"50px"}>
          <Flex justifyContent={"space-between"} color={"gray.600"}>
            <Text>
              {/* Buy {value} Connect{value == 1 ? "" : "s"}: */}
              &nbsp;
            </Text>
            <Text>INR {value} x 120</Text>
          </Flex>
          <Flex
            justifyContent={"end"}
            gap={2}
            fontWeight={"bold"}
            fontSize={"16px"}
          >
            <Text>Total:</Text>
            <Text>INR {value * 120}</Text>
          </Flex>
        </Box>
      ) : (
        <Text mt={"50px"}>Please enter a valid number of connects</Text>
      )}

      <HStack justifySelf={"end"} mt={"auto"}>
        <Button
          flex={1}
          size={"md"}
          p={0}
          bg={"secondary.light"}
          color={"secondary.main"}
        >
          Buy Online
        </Button>
        <Button
          flex={1}
          size={"md"}
          p={0}
          bg={"rgba(40,105,254,0.1)"}
          color={"#2869FE"}
          onClick={() => {
            navigate("/exhibitor/subscriptions-purchase");
          }}
        >
          Purchase Order
        </Button>
      </HStack>
      <Text fontSize={"11px"} mt={6}>
        Alternatively, you can get in touch with the event organizer to buy more
        Connects.
      </Text>
    </Box>
  );
};

export default SubscriptionCart;
