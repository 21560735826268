import React, { useState } from "react";
import { Button, Text } from "@chakra-ui/react";

type Props = {
  buttonText: string;
  onClick: () => void;
  buttonRef: React.LegacyRef<HTMLButtonElement>;
};

const FilterToggleButton = (props: Props) => {
  const [isActive, setIsActive] = useState(true);

  return (
    <Button
      ref={props.buttonRef as React.LegacyRef<HTMLButtonElement>}
      onClick={() => {
        setIsActive(!isActive);
        props.onClick();
      }}
      colorScheme="transparent"
      variant={"outline"}
      borderColor={isActive ? "primary.main" : "card.borderLight"}
      backgroundColor={isActive ? "primary.main" : "white"}
      // _hover={{ background: "secondary.light" }}
      h={"25px"}
    >
      <Text
        fontSize={"xs"}
        fontWeight={"regular"}
        color={isActive ? "secondary.main" : "text.black2"}
      >
        {props.buttonText}
      </Text>
    </Button>
  );
};

export default FilterToggleButton;
