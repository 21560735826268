import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { AiFillEye, AiFillEyeInvisible, AiFillMail } from "react-icons/ai";
import { Icons } from "../../Assets/icons";
import Header from "../../Components/Common/Header";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AuthAPI } from "../../Apis/authAPI";
import Loader from "../../Components/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../redux/Actions";
import { useToastWrapper } from "../../Wrapper/toastWrapper";

import { errorHandler } from "../../Utils/handler";

type Props = {};

const Login = (props: Props) => {
  const [serachParams] = useSearchParams();
  const role = serachParams.get("type");

  const navigate = useNavigate();

  const user = useSelector((state: any) => state.user);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { success, error } = useToastWrapper();

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async () => {
    if (!email || !password) return error("Please fill all the fields", "");
    try {
      setLoading(true);
      const response = await AuthAPI.postLogin({ email, password, role });
      if (response) {
        console.log(response);
        // success("Login Successfull", "");
        const data = {
          token: response.data.token,
          user: response.data.data._id,
          role: response.data.data.role,
        };
        dispatch(actions.user.set(data));
        if (response.data.data.role == "Visitor") navigate("/visitor");
        if (response.data.data.role == "Exhibitor") navigate("/exhibitor");
        if (response.data.data.role == "Event_Organizer")
          navigate("/organizer");
      }
    } catch (err: any) {
      console.log("error logging user :: ", err);
      const errMsg = errorHandler(err);
      error(errMsg, "");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user && user?.token && user?.role && user?.user) {
      console.log("here");

      if (user.role == "Visitor") return navigate("/visitor");
      if (user.role == "Exhibitor") return navigate("/exhibitor");
      if (user.role == "Event_Organizer") return navigate("/organizer");
    }
    if (!role) {
      console.log("Im here");

      navigate("/");
    }
    dispatch(actions.user.logout());
  }, []);

  const submitRef = useRef<any>(null);

  useEffect(() => {
    const listener = (event: any) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        submitRef.current.click();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  return (
    <Box height={"100%"} flexGrow={"grow"} p={6}>
      {loading ? <Loader /> : null}
      <Header>{role} Sign In</Header>
      {/* <Text fontSize={"14px"} textAlign={"center"} mt={6}>
        Sign in to your account
      </Text> */}
      <Box mt={"32px"}>
        <FormControl>
          <FormLabel>Email</FormLabel>
          <InputGroup>
            <InputLeftElement h={"50px"} pointerEvents="none">
              <Image src={Icons.LoginMail} alt="" />
            </InputLeftElement>
            <Input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              h={"50px"}
              type="email"
              placeholder="Enter Email"
            />
          </InputGroup>
        </FormControl>
        <FormControl mt={"20px"}>
          <FormLabel>Password</FormLabel>
          <InputGroup>
            <InputLeftElement h={"50px"} pointerEvents="none">
              <Image src={Icons.LoginPassword} alt="" />
            </InputLeftElement>
            <Input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              h={"50px"}
              type={showPassword ? "text" : "password"}
              placeholder="Enter Password"
            />
            <InputRightElement h={"50px"}>
              {showPassword ? (
                <AiFillEyeInvisible
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setShowPassword(false);
                  }}
                />
              ) : (
                <AiFillEye
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setShowPassword(true);
                  }}
                />
              )}
            </InputRightElement>
          </InputGroup>
        </FormControl>
        <Flex justifyContent={"end"}>
          <Text
            onClick={() => {
              navigate("/forgot-password");
            }}
            color={"blue"}
            mt={2}
            cursor={"pointer"}
          >
            Forgot Password?
          </Text>
        </Flex>
      </Box>

      <Flex alignItems={"center"} mt={"80px"} direction={"column"}>
        <Text mt={2}>
          I accept these{" "}
          <Text
            as={"span"}
            cursor={"pointer"}
            onClick={() => {
              // window.location.href = "https://prontocommerce.com/eula-after";
              window.open("https://prontocommerce.com/eula-after", "_blank");
            }}
            color={"blue"}
          >
            terms and conditions
          </Text>
        </Text>
        <Button
          ref={submitRef}
          onClick={handleSubmit}
          width={"full"}
          mt={4}
          h={"60px"}
          alignItems={"center"}
          justifyContent={"center"}
          borderRadius={"16px"}
          display={"flex"}
          background={"primary.main"}
          color={"white"}
        >
          Sign In
        </Button>
      </Flex>

      {/* <HStack mt={'34px'} justifyContent={'center'}>
                    <Text>
                    Don't have an account?
                    </Text>
                    <Text color={'#2869FE'}>
                    Sign Up
                    </Text>
                </HStack> */}
    </Box>
  );
};

export default Login;
