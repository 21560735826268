import { Box, Image, Text } from "@chakra-ui/react";
import React from "react";
import { Icons } from "../../Assets/icons";

type Props = {
  handleCloseModal: () => void;
};

const SupportModal = (props: Props) => {
  return (
    <Box>
      <Box
        position={"relative"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        h={"60px"}
        w={"100%"}
      >
        <Text
          fontSize={"20px"}
          fontWeight={700}
          textAlign={"center"}
          color={"black"}
        >
          Support
        </Text>
        <Image
          onClick={() => {
            props.handleCloseModal();
          }}
          position={"absolute"}
          src={Icons.cross}
          right={0}
          top={0}
        />
      </Box>
      <Text fontSize={"md"} textAlign={"center"} fontWeight={"700"}>
        For technical assistance: <br /> <br /> Email:{" "}
        <a
          style={{ color: "#F5793D" }}
          href="mailto:support@prontocommerce.com"
        >
          support@prontocommerce.com
        </a>{" "}
        <br /> or <br />
        Whatsapp:{" "}
        <a style={{ color: "#F5793D" }} href="https://wa.me/+919884800225">
          +91-9884800225
        </a>
      </Text>
    </Box>
  );
};

export default SupportModal;
