import { Box, Button, Flex, Input, Text, VStack } from "@chakra-ui/react";
import React from "react";

import { Select } from "antd";

//// Icons

import { FaAngleLeft } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import Header from "../../Components/Common/Header";

const Company = () => {
  const navigate = useNavigate();

  return (
    <Box
      w={"100%"}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      bg={"linear.100"}
      minH={"100vh"}
      maxH={"100vh"}
    >
      <VStack w={"100%"} px={"30px"}>
        {/* <Flex
          mt={"30px"}
          alignItems={"center"}
          justifyContent={"space-between"}
          w={"100%"}
          gap={"20px"}
        >
          <Button
            w={"40px"}
            h={"40px"}
            borderRadius={"10px"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            bg={"transparent"}
            border={"1px solid #ffffffB3"}
            fontSize={"20px"}
          >
            <span>
              <FaAngleLeft size={22} color="#ffffffB3" />
            </span>
          </Button>
          <VStack w={"100%"} textAlign={"left"} alignItems={"flex-start"}>
            <Text fontSize={"lg"} fontWeight={600} color={"#fff"}>
              Company Staff Details
            </Text>
          </VStack>
        </Flex> */}

        <Box width={"100%"} mt={"30px"}>
          <Header backButton isWhite>
            Company Staff Details
          </Header>
        </Box>

        <Box
          p={"20px"}
          w={"100%"}
          mt={"30px"}
          bg={"white"}
          border={"1px solid #E2E8F0"}
          borderRadius={"15px"}
          shadow={"sm"}
        >
          <Text fontWeight={600}>Company Name </Text>
        </Box>
      </VStack>

      <Box
        w={"100%"}
        bg={"white"}
        pt={"30px"}
        // marginTop={"auto"}
        h={"100%"}
        flex={1}
        marginTop={"30px"}
        minH={"100%"}
        overflowY={"auto"}
        overflowX={"hidden"}
        pb={"100px"}
        borderTopRadius={"40px"}
        px={"30px"}
      >
        <VStack w={"100%"} spacing={4}>
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => (
            <Box
              w={"100%"}
              // border={"1px solid #E2E8F0"}
              p={"15px 20px"}
              borderRadius={"10px"}
              shadow={"md"}
              display={"flex"}
              flexDirection={"column"}
              _hover={{
                shadow: "lg",
              }}
              position={"relative"}
            >
              <Text fontWeight={600}>Employee Name</Text>
              <Flex>
                <Text fontSize={"sm"}>12.03.2023 19:03</Text>
              </Flex>
              <Flex>
                <Text fontSize={"sm"}>Divisional Manager</Text>
              </Flex>

              <Button
                mx={"auto"}
                p={"10px"}
                w={"200px"}
                bg={"#2869FE1a"}
                color={"#2869FE"}
                mt={"30px"}
                onClick={() => {
                  navigate("/organizer/chat");
                }}
              >
                Connect
              </Button>

              <Button
                position={"absolute"}
                top={0}
                right={0}
                colorScheme="transparent"
              >
                <span>
                  <FiEdit size={22} color="#000" />
                </span>
              </Button>
            </Box>
          ))}
        </VStack>
      </Box>
    </Box>
  );
};

export default Company;
