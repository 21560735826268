import { Box, Flex, IconButton, Image } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import MobileLayout from "../MobileLayout/MobileLayout";

import { BiSolidHome } from "react-icons/bi";
import { BsFillPersonFill } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";
import { Icons } from "../../../Assets/icons";
import { useDispatch, useSelector } from "react-redux";
import { useToastWrapper } from "../../../Wrapper/toastWrapper";
import { CommonAPI } from "../../../Apis/commonApi";
import actions from "../../../redux/Actions";
import CustomIconButton from "../../Common/CustomIconButton";

import {
  MdFormatListBulleted,
  MdOutlinePerson3,
  MdPerson3,
  MdOutlineCampaign,
  MdCampaign,
  MdOutlineCalendarMonth,
  MdCalendarMonth,
  MdOutlineFormatListBulleted,
} from "react-icons/md";
import { GoHome, GoHomeFill } from "react-icons/go";
import { ExhibitorScreens } from "../../../Constants/data";

type Props = {
  children: React.ReactNode;
};

const noFooter = [
  "/exhibitor/company",
  "/exhibitor/announcements",
  "/exhibitor/chat",
  "/exhibitor/notifications",
];

const ExhibitorLayout = ({ children }: Props) => {
  const location = useLocation();

  const navigate = useNavigate();

  const { user } = useSelector((state: any) => state);
  const { exhibitorMenu } = useSelector((state: any) => state);

  const { error, info } = useToastWrapper();

  const [authState, setAuthState] = useState(false);

  useEffect(() => {
    if (user && user?.role === "Exhibitor") {
      setAuthState(true);
    } else {
      navigate("/login");
    }
  }, [user]);

  const dispatch = useDispatch();

  const fetchNotification = async () => {
    try {
      const res = await CommonAPI.getNotifications();
      if (res.success) {
        const { notifications, unread } = res.data.data;

        dispatch(
          actions.notification.get({
            notifications,
            unreadNotifications: unread,
          })
        );
      }
    } catch (err) {
      console.log("Error getting notification", err);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      fetchNotification();
    }, 5000);

    console.log("exhibitorMenu", exhibitorMenu);
    return () => clearInterval(interval);
  }, []);

  const MenuItemClick = (screenName: string) => {
    dispatch(
      actions.exhibitorMenu.set({
        activeMenu: screenName,
      })
    );
  };

  return (
    // <MobileLayout>
    <>
      <Flex
        maxW={"600px"}
        w="100%"
        position={"relative"}
        flexDirection="column"
        alignSelf={"center"}
        mx={"auto"}
        display={"flex"}
        minH={"100vh"}
        maxH={"100vh"}
        overflow={"auto"}
      >
        <Box
          flexGrow={1}
          overflow={"auto"}
          pb={"20px"}
          display={"flex"}
          marginBottom={"65px"}
        >
          {authState && children}
        </Box>
        {noFooter.includes(location.pathname) ? null : (
          // <Box
          //   w={"100%"}
          //   bg={"white"}
          //   py={"30px"}
          //   shadow={"0px 0px 10px rgba(0, 0, 0, 0.1)"}
          //   border={"1px solid #E2E8F0"}
          //   // borderTopRadius={"40px"}
          // >
          //   <Flex
          //     maxW={"400px"}
          //     mx={"auto"}
          //     justifyContent={"space-between"}
          //     px={"50px"}
          //   >
          //     <IconButton
          //       aria-label="Home"
          //       icon={<BiSolidHome size={30} color={"black"} />}
          //       onClick={() => {
          //         navigate("/exhibitor");
          //       }}
          //       colorScheme="transparent"
          //     />

          //     <IconButton
          //       aria-label="Home"
          //       icon={<Image width={"50px"} src={Icons.Promotion} />}
          //       onClick={() => {
          //         navigate("/exhibitor/announcements");
          //       }}
          //       colorScheme="transparent"
          //     />

          //     <IconButton
          //       aria-label="Profile"
          //       icon={<BsFillPersonFill size={30} color={"black"} />}
          //       onClick={() => {
          //         navigate("/exhibitor/myaccount");
          //       }}
          //       colorScheme="transparent"
          //     />
          //   </Flex>
          // </Box>
          <Box
            w={"100%"}
            bg={"white"}
            shadow={"0px 0px 10px rgba(0, 0, 0, 0.1)"}
            border={"1px solid #E2E8F0"}
            // borderTopRadius={"40px"}
            style={{
              position: "fixed",
              bottom: 0,
              width: "100%",
              maxWidth: "600px",
            }}
          >
            <Flex h={"65px"}>
              <CustomIconButton
                buttonBg={
                  exhibitorMenu?.activeMenu == ExhibitorScreens.HOME
                    ? "primary.main"
                    : "transparent"
                }
                icon={
                  exhibitorMenu?.activeMenu == ExhibitorScreens.HOME ? (
                    <GoHomeFill
                      size={30}
                      color={"#FF5F12"}
                      fontWeight={"light"}
                    />
                  ) : (
                    <GoHome size={30} color={"#1C1B1F"} fontWeight={"light"} />
                  )
                }
                onClick={() => {
                  MenuItemClick(ExhibitorScreens.HOME);
                  navigate("/exhibitor");
                }}
              />
              <CustomIconButton
                buttonBg={
                  exhibitorMenu?.activeMenu == ExhibitorScreens.DETAILS
                    ? "primary.main"
                    : "transparent"
                }
                icon={
                  exhibitorMenu?.activeMenu == ExhibitorScreens.DETAILS ? (
                    <MdFormatListBulleted
                      size={30}
                      color={"#FF5F12"}
                      fontWeight={"light"}
                    />
                  ) : (
                    <MdOutlineFormatListBulleted
                      size={30}
                      color={"#1C1B1F"}
                      fontWeight={"light"}
                    />
                  )
                }
                onClick={() => {
                  MenuItemClick(ExhibitorScreens.DETAILS);
                  navigate("/exhibitor/list");
                }}
              />
              <CustomIconButton
                buttonBg={
                  exhibitorMenu?.activeMenu == ExhibitorScreens.CALENDAR
                    ? "primary.main"
                    : "transparent"
                }
                icon={
                  exhibitorMenu?.activeMenu == ExhibitorScreens.CALENDAR ? (
                    <MdCalendarMonth
                      size={30}
                      color={"#FF5F12"}
                      fontWeight={"light"}
                    />
                  ) : (
                    <MdOutlineCalendarMonth
                      size={30}
                      color={"#1C1B1F"}
                      fontWeight={"light"}
                    />
                  )
                }
                onClick={() => {
                  MenuItemClick(ExhibitorScreens.CALENDAR);
                  navigate("/exhibitor/calendar");
                  // info("Feature coming soon!","");
                }}
              />
              <CustomIconButton
                buttonBg={
                  exhibitorMenu?.activeMenu == ExhibitorScreens.ANNOUNCEMENTS
                    ? "primary.main"
                    : "transparent"
                }
                icon={
                  exhibitorMenu?.activeMenu ==
                  ExhibitorScreens.ANNOUNCEMENTS ? (
                    <MdCampaign
                      size={30}
                      color={"#FF5F12"}
                      fontWeight={"light"}
                    />
                  ) : (
                    <MdOutlineCampaign
                      size={30}
                      color={"#1C1B1F"}
                      fontWeight={"light"}
                    />
                  )
                }
                onClick={() => {
                  MenuItemClick(ExhibitorScreens.ANNOUNCEMENTS);
                  navigate("/exhibitor/announcements");
                }}
              />
              <CustomIconButton
                buttonBg={
                  exhibitorMenu?.activeMenu == ExhibitorScreens.PROFILE
                    ? "primary.main"
                    : "transparent"
                }
                icon={
                  exhibitorMenu?.activeMenu == ExhibitorScreens.PROFILE ? (
                    <MdPerson3
                      size={30}
                      color={"#FF5F12"}
                      fontWeight={"light"}
                    />
                  ) : (
                    <MdOutlinePerson3
                      size={30}
                      color={"#1C1B1F"}
                      fontWeight={"light"}
                    />
                  )
                }
                onClick={() => {
                  MenuItemClick(ExhibitorScreens.PROFILE);
                  navigate("/exhibitor/myaccount");
                }}
              />
            </Flex>
          </Box>
        )}
      </Flex>
    </>
  );
};

export default ExhibitorLayout;
