import { Box, Flex, Text, VStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

//// Icons
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { BsFillPersonFill } from "react-icons/bs";
import { BiSolidLockAlt } from "react-icons/bi";
import { FaBoxOpen, FaHandshake } from "react-icons/fa";
import { IoLogOut } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { handleLogout } from "../../Utils/logout";
import { ExhibitorAPI } from "../../Apis/exhibitorAPI";
import { errorHandler } from "../../Utils/handler";
import { useToastWrapper } from "../../Wrapper/toastWrapper";
import LogoutWarningModal from "../../Components/Modals/LogoutWarningModal";
import { Modal } from "antd";
import { useDispatch } from "react-redux";
import actions from "../../redux/Actions";
import { MdOutlineSupportAgent, MdWorkspacePremium } from "react-icons/md";
import SupportModal from "../../Components/Modals/SupportModal";
import { ExhibitorScreens } from "../../Constants/data";

const Account = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const [data, setData] = useState<any>();

  const { success, error } = useToastWrapper();

  const [logoutModal, setLogoutModal] = useState(false);

  const handleLogoutUser = () => {
    dispatch(actions.user.logout());
    setLogoutModal(false);
    navigate("/login");
  };

  const handleCloseLogoutModal = () => {
    setLogoutModal(false);
  };

  const handleOpenLogoutModal = () => {
    setLogoutModal(true);
  };

  const [supportModal, setSupportModal] = useState(false);

  const handleOpenSupportModal = () => {
    setSupportModal(true);
  };

  const handleCloseSupportModal = () => {
    setSupportModal(false);
  };

  const getData = async () => {
    setLoading(true);
    try {
      const res = await ExhibitorAPI.getExhibitorProfile();
      if (res) {
        console.log(res);
        const obj = {
          name: res.data.data?.personName,
          companyName: res.data.data?.companyName,
          designation: res.data.data?.personDesignation,
          workEmail: res.data.data?.personEmail,
          // socialEmail: res.data.data?.personEmail,
          phoneNumber: res.data.data?.personPhoneNumber,
          companyWebsite: res.data.data?.companyUrl,
        };
        setData(obj);
      }
    } catch (err) {
      console.log(err);
      error(errorHandler(err));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
    dispatch(
      actions.exhibitorMenu.set({
        activeMenu: ExhibitorScreens.PROFILE
      })
    )
  }, []);

  return (
    <Box
      w={"100%"}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      minH={"100vh"}
      maxH={"100vh"}
    >
      <Modal
        closeIcon={null}
        footer={null}
        title={null}
        open={logoutModal}
        onOk={handleCloseLogoutModal}
        onCancel={handleCloseLogoutModal}
      >
        <LogoutWarningModal
          handleCloseModal={handleCloseLogoutModal}
          handleSubmit={handleLogoutUser}
        />
      </Modal>
      <Modal
        closeIcon={null}
        footer={null}
        title={null}
        open={supportModal}
        onOk={handleCloseSupportModal}
        onCancel={handleCloseSupportModal}
      >
        <SupportModal handleCloseModal={handleCloseSupportModal} />
      </Modal>
      <Flex mt={"30px"} alignItems={"center"} gap={4}>
        {/* <Text fontSize={"lg"} fontWeight={500}>
          My Account Info
        </Text>
        <AiOutlineQuestionCircle size={22} /> */}
        <Text
          textAlign={"center"}
          fontSize={"lg"}
          fontWeight={500}
          color={"gray.700"}
        >
          {data?.companyName || "Exhibitor Company Name"}
        </Text>
      </Flex>

      <Box mt={"auto"} w={"100%"}>
        {/* <Text
          textAlign={"center"}
          fontSize={"lg"}
          fontWeight={500}
          color={"gray.700"}
          mt={2}
        >
          { data?.companyName || "Exhibitor Company Name"}
        </Text> */}
        <Text
          textAlign={"center"}
          fontSize={"lg"}
          fontWeight={500}
          color={"gray.700"}
          mt={2}
        >
          Account Info
        </Text>

        <Box
          mt={"20px"}
          w={"100%"}
          bg={"linear.100"}
          pt={"30px"}
          pb={"100px"}
          borderTopRadius={"40px"}
          px={"30px"}
        >
          <VStack spacing={0}>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"start"}
              py={"20px"}
              gap={4}
              w={"100%"}
              borderBottom={"1px solid #E2E8F0"}
              onClick={() => {
                navigate("/exhibitor/profile");
              }}
            >
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                h={"50px"}
                w={"50px"}
                bg={"#ffffff1a"}
                rounded={"15px"}
              >
                <BsFillPersonFill size={24} color={"white"} />
              </Box>
              <Text fontSize={"lg"} fontWeight={500} color={"white"}>
                My Profile
              </Text>
            </Box>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"start"}
              py={"20px"}
              gap={4}
              w={"100%"}
              borderBottom={"1px solid #E2E8F0"}
              onClick={() => {
                navigate("/exhibitor/subscriptions");
              }}
            >
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                h={"50px"}
                w={"50px"}
                bg={"#ffffff1a"}
                rounded={"15px"}
              >
                <MdWorkspacePremium size={24} color={"white"} />
              </Box>
              <Text fontSize={"lg"} fontWeight={500} color={"white"}>
                Subscriptions
              </Text>
            </Box>
            {/* <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"start"}
              py={"20px"}
              gap={4}
              w={"100%"}
              borderBottom={"1px solid #E2E8F0"}
              onClick={() => {
                navigate("/meetings");
              }}
            >
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                h={"50px"}
                w={"50px"}
                bg={"#ffffff1a"}
                rounded={"15px"}
              >
                <FaHandshake size={24} color={"white"} />
              </Box>
              <Text fontSize={"lg"} fontWeight={500} color={"white"}>
                Meetings
              </Text>
            </Box> */}

            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"start"}
              py={"20px"}
              gap={4}
              w={"100%"}
              borderBottom={"1px solid #E2E8F0"}
              onClick={() => {
                navigate("/reset-password");
              }}
            >
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                h={"50px"}
                w={"50px"}
                bg={"#ffffff1a"}
                rounded={"15px"}
              >
                <BiSolidLockAlt size={24} color={"white"} />
              </Box>
              <Text fontSize={"lg"} fontWeight={500} color={"white"}>
                Change Password
              </Text>
            </Box>

            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"start"}
              py={"20px"}
              gap={4}
              w={"100%"}
              borderBottom={"1px solid #E2E8F0"}
              onClick={() => {
                navigate("/exhibitor/pas");
              }}
            >
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                h={"50px"}
                w={"50px"}
                bg={"#ffffff1a"}
                rounded={"15px"}
              >
                <FaBoxOpen size={24} color={"white"} />
              </Box>
              <Text fontSize={"lg"} fontWeight={500} color={"white"}>
                Products and Services
              </Text>
            </Box>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"start"}
              py={"20px"}
              gap={4}
              w={"100%"}
              borderBottom={"1px solid #E2E8F0"}
              onClick={() => {
                handleOpenSupportModal();
              }}
              //   borderBottom={"1px solid #E2E8F0"}
            >
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                h={"50px"}
                w={"50px"}
                bg={"#ffffff1a"}
                rounded={"15px"}
              >
                <MdOutlineSupportAgent size={24} color={"white"} />
              </Box>
              <Text fontSize={"lg"} fontWeight={500} color={"white"}>
                Support
              </Text>
            </Box>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"start"}
              py={"20px"}
              gap={4}
              w={"100%"}
              onClick={() => {
                // handleLogout();
                handleOpenLogoutModal();
              }}
              //   borderBottom={"1px solid #E2E8F0"}
            >
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                h={"50px"}
                w={"50px"}
                bg={"#ffffff1a"}
                rounded={"15px"}
              >
                <IoLogOut size={24} color={"white"} />
              </Box>
              <Text fontSize={"lg"} fontWeight={500} color={"white"}>
                Logout
              </Text>
            </Box>
          </VStack>
        </Box>
      </Box>
    </Box>
  );
};

export default Account;
