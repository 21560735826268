export const validateEmail = (email:string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
      );
  };

  export const disabledDate = (current:any) => {
    // Disable dates in the past
  if (current && current < new Date()) {
    return true;
  }

  // Disable dates more than 31 days in the future
  const nextMonth = new Date();
  nextMonth.setDate(nextMonth.getDate() + 31);
  if (current && current > nextMonth) {
    return true;
  }

  return false;
  };