import * as userSliceActions from "./slices/user";
import * as notificationSlice from "./slices/notification";
import * as exhibitorMenuSlice from "./slices/exhibitorMenu";
import * as visitorMenuSlice from "./slices/visitorMenu"
import * as exhibitorAssetSlice from "./slices/exhibitorAssetSlice"


const actions = {
  user: {
    set: userSliceActions.setUser,
    logout: userSliceActions.logout,
    changeToken: userSliceActions.changeToken,
  },
  notification: {
    get: notificationSlice.setNotifications,
    setLoading: notificationSlice.setLoading,
  },
  exhibitorMenu: {
    set: exhibitorMenuSlice.setActiveMenu
  },
  visitorMenu: {
    set: visitorMenuSlice.setActiveMenu
  },
  exhibitorAssets:{
    setAssets: exhibitorAssetSlice.setAssets,
    setLink: exhibitorAssetSlice.setLink,
    setTitle: exhibitorAssetSlice.setTitle,
    resetForm: exhibitorAssetSlice.resetForm
  }
};

export default actions;
