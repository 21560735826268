import { Box, Button, Flex, Image, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { Icons } from "../../Assets/icons";
import { relevanceFields } from "../../Constants/data";
import { IoClose } from "react-icons/io5";

type Props = {
  handleCloseModal: () => void;
  relevanceSort?: string;
  setRelevanceSort: (val: string) => void;
  role?: string;
  interestedTags?: string[];
  selectedTags?: any[];
  setSelectedTags?: (val: any[]) => void;
};

const FilterModal = (props: Props) => {
  return (
    <Box w={"100%"} h={"100%"}>
      <Box
        position={"relative"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        h={"60px"}
      >
        <Text
          fontSize={"20px"}
          fontWeight={700}
          textAlign={"center"}
          color={"black"}
        >
          Filter By
        </Text>
        <Image
          onClick={() => {
            props.handleCloseModal();
          }}
          position={"absolute"}
          src={Icons.cross}
          left={0}
          top={0}
        />
      </Box>

      <VStack gap={"18px"}>
        <Box px={2} py={3} bg={"gray.50"} borderRadius={"10px"} w={"100%"}>
          <Flex alignItems={"center"} gap={"16px"}>
            <Text fontWeight={600} fontSize={"md"}>
              Relevance
            </Text>
            <Flex alignItems={"center"} gap={"8px"}>
              {relevanceFields.map((val, ind) => {
                if (val.status == props.relevanceSort)
                  return (
                    <Box
                      key={ind}
                      h={"40px"}
                      w={"40px"}
                      border={"3px solid"}
                      borderColor={val.color}
                      p={1}
                      borderRadius={"50%"}
                      onClick={() => {
                        props.setRelevanceSort("");
                        props.handleCloseModal();
                      }}
                    >
                      <Box
                        w={"100%"}
                        h={"100%"}
                        bg={val.color}
                        borderRadius={"50%"}
                      ></Box>
                    </Box>
                  );
                else
                  return (
                    <Box
                      key={ind}
                      h={"20px"}
                      w={"20px"}
                      borderColor={"gray.500"}
                      borderRadius={"2÷px"}
                      p={"3px"}
                      onClick={() => {
                        props.setRelevanceSort(val.status);
                        props.handleCloseModal();
                      }}
                    >
                      <Box
                        w={"100%"}
                        h={"100%"}
                        bg={val.color}
                        borderRadius={"50%"}
                      ></Box>
                    </Box>
                  );
              })}
            </Flex>
          </Flex>
        </Box>
        {props.role == "Visitor" ? (
          <Box px={2} py={3} bg={"gray.50"} borderRadius={"10px"} w={"100%"}>
            <Flex gap={"16px"} direction={"column"}>
              <Text fontWeight={600} fontSize={"md"}>
                Interested Tags
              </Text>
              {props.interestedTags && props.interestedTags?.length > 0 ? (
                <Flex alignItems={"center"} flexWrap={"wrap"} gap={"8px"}>
                  {props.interestedTags.map((val, ind) => {
                    if (props.selectedTags?.includes(val))
                      return (
                        <Box
                          color={"#FFF"}
                          display={"flex"}
                          alignItems={"center"}
                          style={{
                            padding: "3px 7px",
                            color: "#FFF",
                            fontFamily: `"Inter Variable", sans-serif`,
                            fontWeight: "light",
                            backgroundColor: "#FF8D80",
                            borderRadius: "3px",
                            whiteSpace: "nowrap",
                          }}
                          onClick={() => {
                            let temp = props.selectedTags || [];
                            temp?.splice(temp?.indexOf(val), 1);
                            const arr = [...temp];
                            if (props.setSelectedTags)
                              props?.setSelectedTags(arr);
                          }}
                        >
                          <Text fontSize={"sm"} fontWeight={500}>
                            {val}
                          </Text>
                          <IoClose />
                        </Box>
                      );
                    else
                      return (
                        <Box
                          onClick={() => {
                            let temp = props.selectedTags || [];
                            temp?.push(val);
                            const arr = [...temp];
                            if (props.setSelectedTags)
                              props?.setSelectedTags(arr);
                          }}
                          style={{
                            padding: "3px 7px",
                            color: "#000000",
                            fontFamily: `"Inter Variable", sans-serif`,
                            fontWeight: "light",
                            backgroundColor: "#FFD2CD",
                            borderRadius: "3px",
                          }}
                        >
                          <Text fontSize={"sm"} fontWeight={500}>
                            {val}
                          </Text>
                        </Box>
                      );
                  })}
                </Flex>
              ) : (
                <Text fontSize={"sm"} fontWeight={500}>
                  No tags Found
                </Text>
              )}
            </Flex>
          </Box>
        ) : null}
      </VStack>
    </Box>
  );
};

export default FilterModal;
