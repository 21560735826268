import { Flex } from "@chakra-ui/react";
import React from "react";

type Props = {
  children: React.ReactNode;
};

const MobileLayout = ({ children }: Props) => {
  return (
    <Flex
      maxW={"600px"}
      w="100%"
      position={"relative"}
      direction="column"
      // pt={{ base: 4, lg: 0 }}
      alignSelf={"center"}
      mx={"auto"}
      minH={"100vh"}
    >
      {children}
    </Flex>
  );
};

export default MobileLayout;
