import { createSlice } from "@reduxjs/toolkit";

const subscriptionslice = createSlice({
  name: "subscription",

  initialState: {
    subscriptions: [],
    planName: '',
    emailSearch: false,
    relevanceMapping: false,
    notesFeature: false,
    exhibitorTagsLimit: 0,
    pendingMeetingsLimit: 0,
    digitalAssetsLimit: 0,
    closedMeetingsLimit: 0,
    promoteTopListing: false,
    isChatAllowed: false,
    isAnnouncementsAllowed: false,
    isPlanActive: false,
    meetingType: 'telephonic'
  },

  reducers: {
    setSubscriptions: (state, action) => {
      state.subscriptions = action.payload;
    },
    setPlanName: (state, action) => {
      state.planName = action.payload;
    },
    setEmailSearch: (state, action) => {
      state.emailSearch = action.payload;
    },
    setRelevanceMapping: (state, action) => {
      state.relevanceMapping = action.payload;
    },
    setNotesFeature: (state, action) => {
      state.notesFeature = action.payload;
    },
    setExhibitorTagsLimit: (state, action) => {
      state.exhibitorTagsLimit = parseInt(action.payload);
    },
    setPendingMeetingsLimit: (state, action) => {
      state.pendingMeetingsLimit = parseInt(action.payload);
    },
    setDigitalAssetsLimit: (state, action) => {
      state.digitalAssetsLimit = parseInt(action.payload);
    },
    setClosedMeetingsLimit: (state, action) => {
      state.closedMeetingsLimit = parseInt(action.payload);
    },
    setPromoteTopListing: (state, action) => {
      state.promoteTopListing = action.payload;
    },
    setIsChatAllowed: (state, action) => {
      state.isChatAllowed = action.payload;
    },
    setIsAnnouncementsAllowed: (state, action) => {
      state.isAnnouncementsAllowed = action.payload;
    },
    setIsPlanActive: (state, action) => {
      state.isPlanActive = action.payload;
    }
    , resetForm: (state) => {
      state.planName = ''
      state.emailSearch = false
      state.relevanceMapping = false
      state.notesFeature = false
      state.exhibitorTagsLimit = 0
      state.pendingMeetingsLimit = 0
      state.digitalAssetsLimit = 0
      state.closedMeetingsLimit = 0
      state.promoteTopListing = false
      state.isChatAllowed = false
      state.isAnnouncementsAllowed = false
      state.meetingType = 'telephonic'
      state.isPlanActive = false;
    }
  },
});

export const { resetForm, setClosedMeetingsLimit, setDigitalAssetsLimit, setEmailSearch,
  setExhibitorTagsLimit, setIsAnnouncementsAllowed, setIsChatAllowed,
  setNotesFeature, setPendingMeetingsLimit, setPlanName, setPromoteTopListing,
  setRelevanceMapping, setSubscriptions, setIsPlanActive

} = subscriptionslice.actions;

export const subscriptionReducer = subscriptionslice.reducer;
