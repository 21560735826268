export const timeElapsed = (dateString: string): string => {
    const now: Date = new Date();
    const past: Date = new Date(dateString);
    const diffInSeconds: number = Math.floor((now.getTime() - past.getTime()) / 1000);
  
    const secondsInMinute: number = 60;
    const secondsInHour: number = secondsInMinute * 60;
    const secondsInDay: number = secondsInHour * 24;
  
    if (diffInSeconds < secondsInMinute) {
      return `Sent just now`;
    } else if (diffInSeconds < secondsInHour) {
      const minutes: number = Math.floor(diffInSeconds / secondsInMinute);
      return `Sent ${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
    } else if (diffInSeconds < secondsInDay) {
      const hours: number = Math.floor(diffInSeconds / secondsInHour);
      return `Sent ${hours} hour${hours !== 1 ? 's' : ''} ago`;
    } else if (diffInSeconds < secondsInDay * 2) {
      return `Sent yesterday`;
    } else {
      const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
      const formattedDate: string = past.toLocaleDateString('en-US', options);
      return `Sent on ${formattedDate}`;
    }
  }
  