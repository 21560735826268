import {
  Box,
  Button,
  Flex,
  Image,
  Text,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Icons } from "../../Assets/icons";
import { CommonAPI } from "../../Apis/commonApi";
import { useToastWrapper } from "../../Wrapper/toastWrapper";
import { errorHandler } from "../../Utils/handler";
import Loader from "../Loader/Loader";
import { MdClose } from "react-icons/md";

type Props = {
  handleCloseModal: () => void;
  eventId?: string;
  forUser?: string;
  companyName?: string;
};

const NotesModal = (props: Props) => {
  const [notes, setNotes] = React.useState<string>("");

  const [loading, setLoading] = React.useState<boolean>(false);

  const { success, error } = useToastWrapper();

  const handleSubmit = async () => {
    // if(!notes) return error("Please enter notes","")
    setLoading(true);
    try {
      const obj = {
        eventId: props.eventId,
        forUser: props.forUser,
        body: notes,
      };
      const res = await CommonAPI.postNotes(obj);
      if (res) {
        props.handleCloseModal();
        success("Notes Added", "");
      }
      console.log(res);
    } catch (err) {
      console.log(err);
      error(errorHandler(err), "");
    } finally {
      setLoading(false);
    }
  };

  const getData = async () => {
    setLoading(true);
    try {
      const res = await CommonAPI.getNotes({
        eventId: props.eventId,
        forUser: props.forUser,
      });
      console.log(res);
      if (res) {
        setNotes(res?.data?.data?.body || "");
      }
    } catch (err) {
      error(errorHandler(err), "");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (props.eventId && props.forUser) getData();
  }, [props.eventId, props.forUser]);

  return (
    <Box w={"100%"} h={"100%"} py={"21px"}>
      {loading ? <Loader /> : null}
      <VStack
        position={"relative"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        className={"create-meeting-modal"}
      >
        {/* <Text
          fontSize={"20px"}
          fontWeight={700}
          textAlign={"center"}
          color={"black"}
        >
           Create meeting 
        </Text> */}
        {/* <Image
          onClick={() => {
            props.handleCloseModal();
          }}
          position={"absolute"}
          src={Icons.cross}
          right={0}
          top={0}
        /> */}
        <Box
          position={"absolute"}
          right={0}
          top={0}
          pb={3}
          cursor={"pointer"}
          zIndex={200}
        >
          <MdClose
            size={20}
            onClick={() => {
              props.handleCloseModal();
            }}
          />
        </Box>
      </VStack>
      <Box
        position={"relative"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
        // h={"60px"}
      >
        <Text
          fontSize={"md"}
          fontWeight={"regular"}
          textAlign={"center"}
          color={"black"}
          mb={"15px"}
        >
          Notes
        </Text>
        <Text
          fontSize={"sm"}
          fontWeight={"medium"}
          textAlign={"center"}
          color={"black"}
          mb={"16px"}
        >
          {props.companyName}
        </Text>
        {/* <Image
          onClick={() => {
            props.handleCloseModal();
          }}
          position={"absolute"}
          src={Icons.cross}
          left={0}
          top={0}
        /> */}
      </Box>
      <Box>
        <Textarea
          style={{
            border: "1px solid #D9D9D9",
            borderRadius: 0,
          }}
          value={notes}
          onChange={(e) => {
            setNotes(e.target.value);
            console.log(e.target.value);
          }}
          rows={6}
          placeholder="Enter Notes"
        />
        <Text fontSize={"sm"} color={"card.borderBlue"}>
          Note: Existing notes cannot be recovered if deleted
        </Text>
      </Box>
      <Flex w={"100%"} justifyContent={"space-between"} mt={"40px"} gap={2}>
        <Button
          onClick={() => {
            props.handleCloseModal();
          }}
          flex={1}
          w={"100%"}
          size={"md"}
          p={0}
          variant={"outline"}
          borderColor={"card.borderLight"}
          _hover={{ background: "secondary.light" }}
          borderRadius={"2px"}
        >
          <Text fontSize={"sm"} fontWeight={"regular"}>
            Cancel
          </Text>
        </Button>
        <Button
          onClick={handleSubmit}
          flex={1}
          w={"100%"}
          colorScheme="transparent"
          variant={"outline"}
          borderColor={"secondary.main"}
          _hover={{ background: "secondary.light" }}
          borderRadius={"2px"}
        >
          <Text fontSize={"sm"} fontWeight={"regular"}>
            Submit
          </Text>
        </Button>
      </Flex>
    </Box>
  );
};

export default NotesModal;
