import { Box, Button, Divider, Flex, Text, VStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Header from "../../Components/Common/Header";
import { useNavigate } from "react-router-dom";
import { Modal, Select } from "antd";
import FAQModal from "../../Components/Modals/FAQModal";
import { VisitorAPI } from "../../Apis/visitorAPI";
import { EventInterface } from "../../Interface/CommonInterface";
import { AiOutlineCarryOut, AiOutlineQuestionCircle } from "react-icons/ai";
import moment from "moment";
import { Images } from "../../Assets/images";
import { MdOutlineEventAvailable } from "react-icons/md";
import Loader from "../../Components/Loader/Loader";

type Props = {};

const Rewards = (props: Props) => {
  const [isModal, setIsModal] = useState(false);
  const [rewardStatements, setRewardStatements] = useState<any[]>([]);
  const [rewardRedemptions, setRewardRedemptions] = useState<any[]>([]);
  const [redemmablePoints, setRedemmablePoints] = useState<number>(0);
  const [redemptionInProgressPoints, setRedemptionInProgressPoints] =
    useState<number>(0);
  const [redemmedPoints, setRedemmedPoints] = useState<number>(0);
  const [events, setEvents] = useState<EventInterface[]>([]);
  const [event, setEvent] = useState<EventInterface>();
  const [loading, setLoading] = useState(false);

  const handleOpenModal = () => setIsModal(true);
  const handleCloseModal = () => setIsModal(false);

  const navigate = useNavigate();

  const getRewardsAndEvents = async () => {
    try {
      setLoading(true);
      const [res1, res2] = await Promise.all([
        VisitorAPI.getEvents(),
        VisitorAPI.getVisitorProfile(),
      ]);
      if (res1?.success) {
        const arr2 = [...res1.data.data].filter((s) =>
          moment(s.startDate).isBefore(moment())
        );
        const arr = [...arr2];
        setEvents(arr.reverse());
        setEvent(arr.reverse()[0]);
      }
      if (res2) {
        const params: any = {};
        params["visitorId"] = res2.data.data._id;
        const [data1, data2] = await Promise.all([
          VisitorAPI.getRewardsByVisitorId(params),
          VisitorAPI.getRewardsRedemption(params),
        ]);
        if (data1) {
          setRewardStatements(data1.data.data.rewardsStatements);
        }
        if (data2) {
          setRewardRedemptions(data2.data.data.rewardsRedemption);
        }
      }
    } catch (err: any) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const getRewardPoints = () => {
    setRedemmablePoints(rewardStatements[0].closingBalance);
  };
  const getRedemptionPoints = () => {
    setRedemptionInProgressPoints(rewardStatements[0]?.redemptionInProgress);
  };
  const getRedemmedPoints = () => {
    setRedemmedPoints(rewardStatements[0].pointsRedeemed);
  };
  useEffect(() => {
    getRewardsAndEvents();
  }, []);
  useEffect(() => {
    if (rewardStatements.length > 0) {
      getRewardPoints();
      getRedemptionPoints();
      getRedemmedPoints();
    }
  }, [rewardRedemptions]);
  const FAQButton = () => {
    return (
      <AiOutlineQuestionCircle
        style={{
          position: "absolute",
          right: "0px",
          top: "0px",
          height: "36px",
          width: "36px",
        }}
        onClick={handleOpenModal}
      />
    );
  };
  const onClosePopUp = () => {
    navigate(`/visitor/myaccount`);
  };
  return (
    <Box p={6} display={"flex"} flexDir={"column"} width={"100%"}>
      <Modal
        footer={null}
        title={null}
        open={isModal}
        onCancel={handleCloseModal}
        closeIcon={null}
        onOk={handleCloseModal}
      >
        <FAQModal handleCloseModal={handleCloseModal} />
      </Modal>
      <Header backButton righticon={FAQButton} onClosePopUp={onClosePopUp}>
        Rewards
      </Header>
      <Divider
        mt={5}
        borderWidth="6px"
        opacity={"1"}
        borderColor={"primary.main"}
      />
      <Box p={6} maxW="800px">
        <VStack spacing={6} align="flex-start" flexDirection={"column"}>
          {/* <Text style={{ fontWeight: "600" }}>
              Event Name: 
            </Text> */}
          <Select
            className="custom-select-noborder"
            showSearch
            style={{
              width: "100%",
              border: "1px solid #E2E8F0",
              outline: "none",
              height: "50px",
              fontFamily: '"Inter Variable", sans-serif',
              backgroundColor: "#f1edd0",
            }}
            value={event?.eventName}
            onChange={(e) => {
              setEvent(events.find((event) => event._id === e));
            }}
            placeholder="Event Name"
            optionFilterProp="children"
            options={[
              ...events?.map((event, index) => ({
                label:
                  event?.eventName +
                  "(" +
                  moment(event?.startDate).format("DD/MMM/YY") +
                  "-" +
                  moment(event?.endDate).format("DD/MMM/YY") +
                  ")",
                value: event?._id,
              })),
            ]}
          />
        </VStack>
      </Box>
      {/* <Box
        mt={5}
        py={7}
        // bg={"#22121927"}
        rounded={"18px"}
        bg={"#0013BC"}
        bgImage={Images.headerElements}
      >
        <Text
          fontSize={"15px"}
          color={"text.black"}
          textAlign={"center"}
          fontWeight={"semibold"}
        >
          My Reward Points
        </Text>
        <Text
          fontSize={"48px"}
          color={"text.black"}
          textAlign={"center"}
          fontWeight={"semibold"}
        >
          500
        </Text>
      </Box> */}
      {loading ?
       
        <Loader />
       : 
      <>
      {rewardStatements.length > 0 ? (
        <Flex
          alignItems={"center"}
          w={"100%"}
          bg={"#0013BC"}
          bgImage={Images.RewardsBanner}
          backgroundSize="cover"
          direction={"column"}
          // backgroundRepeat="no-repeat"
          // backgroundPosition="center"
          py={"40px"}
          px={"30px"}
        >
          <VStack
            w={"100%"}
            textAlign={"left"}
            justifyContent={"space-between"}
            display={"flex"}
            flexDirection={"row"}
          >
            <Box
              display={"flex"}
              flexDirection={"column"}
              width={"30%"}
              alignItems={"center"}
            >
              <Text fontWeight={500} color={"white"} fontSize={"48px"}>
                {redemmablePoints}
              </Text>
              <Text
                fontSize={"18px"}
                color={"white"}
                textAlign={"center"}
                fontWeight={"semibold"}
              >
                points{" "}
              </Text>
              <Button
                mt={1}
                h={"36px"}
                textAlign={"center"}
                bg={"secondary.main"}
                color={"secondary.light"}
                _hover={{
                  backgroundColor: "secondary.lightHover",
                }}
                onClick={() => {
                  navigate("/visitor/rewards-redeem");
                }}
              >
                Redeem
              </Button>
            </Box>
            <Box width={"50%"} fontWeight={"semibold"}>
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                width={"100%"}
                fontSize={"18px"}
                color={"secondary.main"}
              >
                <Text fontSize={"26px"}>{redemptionInProgressPoints}</Text>
                <Text>Redemption In-progress</Text>
              </Box>
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                width={"100%"}
                fontSize={"18px"}
                color={"#00FF00"}
              >
                <Text fontSize={"26px"}>{redemmedPoints}</Text>
                <Text>Points Redeemed</Text>
              </Box>
            </Box>
          </VStack>
        </Flex>
      ) : (
        <Flex
          alignItems={"center"}
          w={"100%"}
          bg={"primary.main"}
          backgroundSize="cover"
          direction={"row"}
          // backgroundRepeat="no-repeat"
          // backgroundPosition="center"
          py={"20px"}
          px={"30px"}
          fontSize={"26px"}
          color={"white"}
        >
          <Text>
            Reward points will show up here as and when meetings are completed.
          </Text>
          <MdOutlineEventAvailable
            style={{ width: "250px", height: "250px" }}
          />
        </Flex>
      )}
      {/* <Box mt={5}>
        <Text fontSize={"10px"} color={"text.black"} mt={1}>
          By clicking “Redeem” you agree to these{" "}
          <span style={{ fontWeight: "800", cursor: "pointer" }}>T&C</span>
        </Text>
      </Box> */}
      <Box mt={4}>
        <Text
          fontSize={"15px"}
          fontWeight={"semibold"}
          color={"text.black"}
          textAlign={"center"}
        >
          Statements
        </Text>
        <VStack>
          {/* {[0, 0, 0].map(() => ( */}
          {rewardStatements.map((rewardStatement) => (
            <Box
              w={"100%"}
              bg={"#C9DCEF"}
              p={3}
              rounded={"18px"}
              mt={3}
              px={5}
              py={4}
              display={"flex"}
              gap={4}
              justifyContent={"space-between"}
              alignItems={"start"}
              shadow={"md"}
              minH={"95px"}
              fontSize={"15px"}
            >
              <Box>
                <Text mb={3}>
                  <span style={{ fontWeight: "bold" }}>{"Date: "}</span>
                  {moment(rewardStatement?.updatedAt).format(
                    "DD MMMM YYYY, HH:mm a"
                  )}
                </Text>
              {rewardStatement?.txnType == "Add" ? (
                <Text mt={1}>
                  <span style={{ fontWeight: "bold" }}>{"Added: "}</span>{rewardStatement?.closingBalance}
                </Text>
              ) : (
                <Text mt={1}>
                  <span style={{ fontWeight: "bold" }}>{"Redemption: "}</span>{rewardStatement?.openingBalance}
                </Text>
              )}
                {rewardStatement?.txnType == "Redeem" && (
                  <Text mt={1}>
                    <span style={{ fontWeight: "bold" }}>{"Request Id: "}</span>
                    {rewardStatement?.redemptionRequestId}
                  </Text>
                )}
                {rewardStatement?.txnType == "Redeem" && (
                <Text mt={1}>
                  <span style={{ fontWeight: "bold" }}>{"Status: "}</span>
                  {
                    rewardRedemptions.find(
                      (item) =>
                        item.redemptionRequestId ===
                        rewardStatement?.redemptionRequestId
                    )?.redemptionStatus
                  }
                </Text>
                )}
                <Text mt={1}>
                  {/* Rewards points Redeemed FAS788FUS345 */}
                  <span style={{ fontWeight: "bold" }}>
                    {rewardStatement?.txnType == "Add"
                      ? "Reward Notes: "
                      : "Redemption Notes: "}
                  </span>
                  {rewardStatement?.rewardNotes}
                </Text>
              </Box>
              {/* {rewardStatement?.txnType == "Add" ? (
                <Text fontSize={"14px"} color={"green"}>
                  +{rewardStatement?.closingBalance}
                </Text>
              ) : (
                <Text fontSize={"14px"} color={"red"}>
                  -{rewardStatement?.openingBalance}
                </Text>
              )} */}
            </Box>
          ))}
          {/* {rewardRedemptions.map((rewardRedemption) => (
            <Box
              w={"100%"}
              bg={"#C9DCEF"}
              p={3}
              rounded={"18px"}
              mt={3}
              px={5}
              py={4}
              display={"flex"}
              gap={4}
              justifyContent={"space-between"}
              alignItems={"start"}
              shadow={"md"}
              minH={"95px"}
            >
              <Box>
                <Text
                  fontSize={"15px"}
                  fontWeight={"semibold"}
                  color={"gray.600"}
                >
                  {moment(rewardRedemption?.updatedAt).format(
                    "DD MMMM YYYY, HH:mm a"
                  )}
                </Text>
                <Text fontSize={"12px"} mt={2} maxW={"200px"}>
                  {rewardRedemption?.redemptionRequestId}{" "}
                </Text>
                <Text fontSize={"12px"} mt={2} maxW={"200px"}>
                  {rewardRedemption?.redemptionStatus}
                </Text>
                <Text fontSize={"12px"} mt={2} maxW={"200px"}>
                  {/* Rewards points Redeemed FAS788FUS345 */}
          {/* {rewardRedemption?.redemptionNotes}
                </Text>
              </Box>

              <Text fontSize={"14px"} color={"red"}>
                {/* -100 */}
          {/* </Text>
            </Box> */}
          {/* ))} */}
        </VStack>
      </Box>
      </>}
      {/* <HStack justifySelf={"end"} mt={5}>
        <Button
          flex={1}
          size={"md"}
          p={0}
          bg={"rgba(40,105,254,0.1)"}
          color={"#2869FE"}
          onClick={handleOpenModal}
        >
          FAQ
        </Button>
         <Button
          flex={1}
          size={"md"}
          p={0}
          bg={"rgba(40,105,254,0.1)"}
          color={"#2869FE"}
          onClick={() => {
            navigate("/visitor/rewards-statement");
          }}
        >
          Statement
        </Button> 
      </HStack> */}
    </Box>
  );
};

export default Rewards;
