import React, { useEffect } from "react";
import MobileLayout from "../Components/Layouts/MobileLayout/MobileLayout";
import { Box, Grid, GridItem, Image, Text, Fade } from "@chakra-ui/react";

import logo from "../Assets/images/logo-small.png";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import actions from "../redux/Actions";

type Props = {};

const Home = (props: Props) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.user.logout());
  }, []);

  return (
    <MobileLayout>
      <Box bg={"primary.blue"} minH={"100vh"}>
        <Fade
          in={true}
          transition={{ exit: { delay: 1 }, enter: { duration: 1 } }}
        >
          <Box
            mt={"40%"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            flexDirection={"column"}
          >
            <Text color={"secondary.orange"} fontSize={"3xl"}>
              CONNECT
            </Text>
            <Text fontSize={"sm"} color={"white"}>
              B2B Trade Show Extender
            </Text>
          </Box>
        </Fade>

        <Fade
          in={true}
          transition={{ exit: { delay: 1 }, enter: { duration: 2 } }}
        >
          <Box w={"80%"} mx={"auto"} mt={"100px"}>
            <Grid gap={4} templateColumns="repeat(2, 1fr)">
              {/* <GridItem>
                <BoxContainer onClick={() => navigate("/login?type=Event_Organizer")}>
                  Organizer
                </BoxContainer>
              </GridItem> */}
              <GridItem>
                <BoxContainer onClick={() => navigate("/login?type=Exhibitor")}>
                  Exhibitor
                </BoxContainer>
              </GridItem>
              <GridItem>
                <BoxContainer onClick={() => navigate("/login?type=Visitor")}>
                  Visitor
                </BoxContainer>
              </GridItem>
            </Grid>
          </Box>
        </Fade>

        <Fade
          in={true}
          transition={{ exit: { delay: 1 }, enter: { duration: 2 } }}
        >
          <Box mt={"200px"} display={"flex"} justifyContent={"center"}>
            <Image src={logo} w={"200px"} />
          </Box>
        </Fade>
      </Box>
    </MobileLayout>
  );
};

const BoxContainer = (props: any) => {
  return (
    <Box
      mx={"auto"}
      color={"button.yellow"}
      height={"100px"}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      fontWeight={600}
      width={"100px"}
      minW={"100px"}
      border={"2px"}
      shadow={"lg"}
      w={"fit-content"}
      borderColor={"#F1F3F740"}
      {...props}
      cursor={"pointer"}
      _hover={{
        borderColor: "button.yellow",
      }}
    >
      {props?.children}
    </Box>
  );
};

export default Home;
