import { Box, Button, Flex, Input, Text, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
import { useToastWrapper } from "../../Wrapper/toastWrapper";
import { ExhibitorAPI } from "../../Apis/exhibitorAPI";
import { errorHandler } from "../../Utils/handler";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../redux/Actions";

type Props = {
  handleCloseModal: () => void;
  exhibitorId: string;
  addUrl: boolean;
};

const AddUrlModal = (props: Props) => {
  const exhibitorAsset = useSelector((state: any) => state.exhibitorAsset);

  const dispatch = useDispatch();

  const [formData, setFormData] = useState<any>({
    title: "",
    url: "",
  });

  const resetValues = () => {
    if (props.addUrl) {
      console.log("on pageload...", props.addUrl);
      setFormData({ ...formData, title: "", url: "" });
    }
  };

  useEffect(() => {
    resetValues();

    return;
  }, []);

  const [loading, setLoading] = useState<boolean>(false);

  const { success, error } = useToastWrapper();

  const handleSubmit = async () => {
    // if(!notes) return error("Please enter notes","")
    setLoading(true);
    try {
      const urlRegex = new RegExp(
        /^(https?:\/\/(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(?:\/[^\s]*)?)$/
      );
      const url = exhibitorAsset.link;
      if (urlRegex.test(url)) {
        const obj = {
          exhibitorId: props.exhibitorId,
          assetTitle: exhibitorAsset.title,
          assetUrl: exhibitorAsset.link,
        };
        const res = await ExhibitorAPI.addAsset(obj);
        if (res) {
          success("Asset Added", "");
          dispatch(actions.exhibitorAssets.resetForm({}));
          const res = await ExhibitorAPI.getAssets();
          if (res) {
            console.log("this is res inside add url..", res);

            dispatch(
              actions.exhibitorAssets.setAssets({
                links: res.data.data,
              })
            );
          }
          props.handleCloseModal();
        }
      } else {
        error("Invalid URL");
      }
    } catch (err) {
      console.log(err);
      error(errorHandler(err), "");
    } finally {
      setLoading(false);
    }
  };

  const handleChangeLink = (e: any) => {
    dispatch(
      actions.exhibitorAssets.setLink({
        link: e.target.value,
      })
    );
  };

  const handleChangeTitle = (e: any) => {
    dispatch(
      actions.exhibitorAssets.setTitle({
        title: e.target.value,
      })
    );
  };

  return (
    <Box w={"100%"} h={"100%"} py={"21px"}>
      <VStack
        position={"relative"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        className={"create-meeting-modal"}
      >
        <Box
          position={"absolute"}
          right={0}
          top={0}
          pb={3}
          cursor={"pointer"}
          zIndex={200}
        >
          <MdClose
            size={20}
            onClick={() => {
              props.handleCloseModal();
            }}
          />
        </Box>
      </VStack>
      <Box
        position={"relative"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
        // h={"60px"}
      >
        <Text
          fontSize={"md"}
          fontWeight={"regular"}
          textAlign={"center"}
          color={"black"}
          mb={"15px"}
        >
          Add asset
        </Text>
      </Box>
      <Box>
        <Text fontSize={16} fontWeight={"normal"}>
          Title
        </Text>
        <Input
          placeholder=""
          type="text"
          _placeholder={{
            color: "gray.500",
            fontWeight: "400",
            fontSize: "16px",
          }}
          mb={5}
          border={"1px"}
          outline={"none"}
          _focus={{ outline: "none", border: "0px" }}
          h={"40px"}
          w={"100%"}
          px={2}
          borderColor={"gray.300"}
          name="title"
          value={exhibitorAsset.title}
          onChange={handleChangeTitle}
        />
        <Text fontSize={16} fontWeight={"normal"}>
          URL
        </Text>
        <Input
          placeholder=""
          type="text"
          _placeholder={{
            color: "gray.500",
            fontWeight: "400",
            fontSize: "16px",
          }}
          mb={10}
          border={"1px"}
          outline={"none"}
          _focus={{ outline: "none", border: "0px" }}
          h={"40px"}
          w={"100%"}
          px={2}
          borderColor={"gray.300"}
          name="link"
          value={exhibitorAsset.link}
          onChange={handleChangeLink}
        />
      </Box>
      <Flex w={"100%"} justifyContent={"space-between"} mt={"40px"} gap={2}>
        <Button
          onClick={() => {
            props.handleCloseModal();
          }}
          flex={1}
          w={"100%"}
          size={"md"}
          p={0}
          variant={"outline"}
          borderColor={"card.borderLight"}
          _hover={{ background: "secondary.light" }}
          borderRadius={"2px"}
        >
          <Text fontSize={"sm"} fontWeight={"regular"}>
            Cancel
          </Text>
        </Button>
        <Button
          onClick={handleSubmit}
          flex={1}
          w={"100%"}
          colorScheme="transparent"
          variant={"outline"}
          borderColor={"secondary.main"}
          _hover={{ background: "secondary.light" }}
          borderRadius={"2px"}
        >
          <Text fontSize={"sm"} fontWeight={"regular"}>
            Add
          </Text>
        </Button>
      </Flex>
    </Box>
  );
};

export default AddUrlModal;
