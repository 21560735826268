import { Box, Button, Flex, Image, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { Icons } from "../../Assets/icons";
import { RelevanceFieldsNewArr, relevanceFields } from "../../Constants/data";
import { ExhibitorAPI } from "../../Apis/exhibitorAPI";
import { useToastWrapper } from "../../Wrapper/toastWrapper";
import { errorHandler } from "../../Utils/handler";

interface RelevanceData {
  prequalified_company_name: string;
  prequalified_user_id: string[];
  event_id: string;
  status: string;
}
type Props = {
  handleCloseModal: () => void;
  relaodData: () => void;
  data: RelevanceData;
};

const RelevanceModal = (props: Props) => {
  const { success, error } = useToastWrapper();

  const HandleSubmit = async (status: String) => {
    const arr = [];
    for (const i of props.data?.prequalified_user_id) {
      const obj = {
        prequalified_company_name: props.data.prequalified_company_name,
        prequalified_user_id: i,
        event_id: props.data.event_id,
        status,
      };
      arr.push(obj);
    }

    console.log(arr);

    try {
      const res = await ExhibitorAPI.PostRelevanceVisitor(arr);
      console.log(res);
      if (res) {
        // success("Relevance Updated","")
        props.handleCloseModal();
        props.relaodData();
      }
    } catch (err) {
      console.log(err);
      error(errorHandler(err), "");
    }
  };
  return (
    <Box w={"100%"} h={"100%"}>
      <Box
        position={"relative"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        h={"60px"}
      >
        <Text
          fontSize={"20px"}
          fontWeight={700}
          textAlign={"center"}
          color={"black"}
        >
          Relevance
        </Text>
        <Image
          onClick={() => {
            props.handleCloseModal();
          }}
          position={"absolute"}
          src={Icons.cross}
          left={0}
          top={0}
        />
      </Box>

      <Box p={6}>
        <Text fontSize={"18px"} fontWeight={"bold"} mt={2}>
          Is {props.data.prequalified_company_name} relevant to you?
        </Text>
        <Text fontSize={"12px"} fontWeight={500} opacity={0.6} mt={1.5}>
          Color indicator will appear next to the company name
        </Text>
      </Box>

      <Box flexGrow={1} borderTopRadius={"40px"} pt={"2px"}>
        <Flex direction={"column"} gap={"26px"}>
          {RelevanceFieldsNewArr.map((field, index) => (
            <Flex
              key={index}
              p={4}
              background={
                props?.data?.status == field.status ? "#E0E0E0" : "#FAFAFA"
              }
              borderRadius={"18px"}
              fontSize={"15px"}
              fontWeight={"600"}
              justifyContent={"space-between"}
              alignItems={"center"}
              onClick={() => {
                HandleSubmit(field.status);
              }}
            >
              <Flex alignItems={"center"} gap={"12px"}>
                <Box
                  h={"32px"}
                  w={"32px"}
                  border={"1px solid"}
                  borderColor={"gray.200"}
                  borderRadius={"10px"}
                  p={"6px"}
                >
                  <Box
                    w={"100%"}
                    h={"100%"}
                    backgroundColor={field.colorHash}
                    borderRadius={"50%"}
                  ></Box>
                </Box>
                {field.title}
              </Flex>

              {/* <Box>
                                    <Image src={props?.data?.status==field.status?Icons.CheckFilled:Icons.CheckOutline}/>
                                </Box> */}
            </Flex>
          ))}
        </Flex>
      </Box>
    </Box>
  );
};

export default RelevanceModal;
