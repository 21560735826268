// export const relevanceFields = [
//   {
//     title: "Yes, Relevant",
//     color: "button.green",
//     check: false,
//     status: "Relevant",
//     colorHash: "#4CAF50",
//   },
//   {
//     title: "No, Not relevant",
//     color: "button.red",
//     check: false,
//     status: "Not_Relevant",
//     colorHash: "#FF3D00",
//   },
//   {
//     title: "Unknown, Need to research",
//     color: "button.yellow",
//     check: true,
//     status: "Under_Research",
//     colorHash: "#FEBA08",
//   },
//   {
//     title: "Existing relationship",
//     color: "primary.main",
//     check: false,
//     status: "Existing_Relationship",
//     colorHash: "#050A30",
//   },
// ];

export const relevanceFields = [
  {
    title: "Yes, Relevant",
    color: "#159300",
    check: false,
    status: "Relevant",
    colorHash: "#159300",
  },
  {
    title: "No, Not relevant",
    color: "#9B9B9B",
    check: false,
    status: "Not_Relevant",
    colorHash: "#9B9B9B",
  },
  {
    title: "Unknown, Need to research",
    color: "#FF5F12",
    check: true,
    status: "Under_Research",
    colorHash: "#FF5F12",
  },
  {
    title: "Existing relationship",
    color: "#0E122F",
    check: false,
    status: "Existing_Relationship",
    colorHash: "#0E122F",
  },
];

export const RelevanceFieldsNew = {
  RELEVANT: {
    title: "Relevant",
    color: "",
    colorHash:"#159300",
    status:"Relevant"
  },
  EXISTING: {
    title: "Existing",
    color: "",
    colorHash:"#0E122F",
    status:"Existing_Relationship"
  },
  REVIEW: {
    title: "Review",
    color: "",
    colorHash:"#FF5F12",
    status:"Under_Research"
  },
  NOTRELEVANT: {
    title: "Ignore",
    color: "",
    colorHash:"#9B9B9B",
    status:"Not_Relevant"
  },
}

export const RelevanceFieldsNewArr = [
   {
    title: "Relevant",
    color: "#159300",
    colorHash:"#159300",
    status:"Relevant"
  },
   {
    title: "Existing",
    color: "#0E122F",
    colorHash:"#0E122F",
    status:"Existing_Relationship"
  },
   {
    title: "Review",
    color: "#FF5F12",
    colorHash:"#FF5F12",
    status:"Under_Research"
  },
   {
    title: "Ignore",
    color: "#9B9B9B",
    colorHash:"#9B9B9B",
    status:"Not_Relevant"
  },
]

export const MeetingsField = {
  CONFIRMED: {
    title: "Confirmed",
    color: "",
    colorHash:"#159300"
  },
  RECEIVED: {
    title: "Received",
    color: "",
    colorHash:"#0E122F"
  },
  SENT: {
    title: "Sent",
    color: "",
    colorHash:"#FF5F12"
  },
  COMPLETED: {
    title: "Completed",
    color: "",
    colorHash:"#9B9B9B"
  },
}

export const MEETING_REQUEST_STATUS = {
  TOTAL: "total",
  PENDING: "pending",
  CONFIRM: "accepted",
  EXPIRED: "expired",
  CANCELLED: "cancelled",
  CLOSED: "closed",
  PROPOSE_ALTERNATE_DATE: "proposeAlternateDate",
  PUT_ON_HOLD: "putOnHold",
  REQUEST_RECEIVED: "requestReceived",
  RESPONSE_AWAITED: "responseAwaited",
};

export const ExhibitorScreens = {
  HOME : "HOME",
  ANNOUNCEMENTS : "ANNOUNCEMENTS",
  CALENDAR : "CALENDAR",
  DETAILS : "DETAILS",
  PROFILE : "PROFILE"
}

export const VisitorScreens = {
  HOME : "HOME",
  SCAN : "SCAN",
  CALENDAR : "CALENDAR",
  DETAILS : "DETAILS",
  PROFILE : "PROFILE"
}
