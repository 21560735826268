import { useSelector } from "react-redux";
import { api } from "./config/axiosConfig.js";
import { defineCancelApiObject } from "./config/axiosUtils.js";
import { store } from "../redux/Store.ts";
// const {userId, token, role} = useSelector(state => state.user);

const { token, userId, role } = store.getState().user;

export const AdminAPI = {
  /////////////////////////
  // Organizer
  ////////////////////////

  postOrganizer: async (register, cancel = false) => {
    const { token } = await store.getState().user;
    console.log(token);
    const response = await api.request({
      url: "/admin/event-organizers/",
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      data: register,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },

  getOrganizer: async (params, cancel = false) => {
    const { token } = await store.getState().user;
    const response = await api.request({
      url: "/admin/event-organizers/",
      method: "GET",
      params: params,
      headers: { Authorization: `Bearer ${token}` },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },

  putOrganizer: async (id, data, cancel = false) => {
    const { token } = await store.getState().user;
    const response = await api.request({
      url: `/admin/event-organizers/${id}`,
      method: "PUT",
      headers: { Authorization: `Bearer ${token}` },
      data: data,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },

  deleteOrganizer: async (id, cancel = false) => {
    const { token } = await store.getState().user;
    const response = await api.request({
      url: `/admin/event-organizers/${id}`,
      method: "DELETE",
      headers: { Authorization: `Bearer ${token}` },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },

  /////////////////////////
  // Event
  ////////////////////////

  postEvent: async (data, cancel = false) => {
    const { token } = await store.getState().user;
    const response = await api.request({
      url: "/admin/events/",
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      data: data,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },

  getEvent: async (params, cancel = false) => {
    const { token } = await store.getState().user;
    const response = await api.request({
      url: "/admin/events/",
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
      params: params,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },

  getEventById: async (eventID, cancel = false) => {
    const { token } = await store.getState().user;
    const response = await api.request({
      url: "/admin/events/" + eventID,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
      // params: params,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },

  putEvent: async (id, data, cancel = false) => {
    const { token } = await store.getState().user;
    const response = await api.request({
      url: `/admin/events/${id}`,
      method: "PUT",
      headers: { Authorization: `Bearer ${token}` },
      data: data,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },

  deleteEvent: async (id, cancel = false) => {
    const { token } = await store.getState().user;
    const response = await api.request({
      url: `/admin/events/${id}`,
      method: "DELETE",
      headers: { Authorization: `Bearer ${token}` },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },

  ///////////////////////////
  // Exhibitors
  //////////////////////////

  postExhibitor: async (data, cancel = false) => {
    const { token } = await store.getState().user;

    const response = await api.request({
      url: "/admin/exhibitors/",
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      data: data,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },

  getExhibitor: async (params, cancel = false) => {
    const { token } = await store.getState().user;
    const response = await api.request({
      url: "/admin/exhibitors/",
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
      params: params,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },

  getEventExhibitor: async (params, cancel = false) => {
    const { token } = await store.getState().user;
    const response = await api.request({
      url: "/admin/event-exhibitors/",
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
      params: params,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },

  getExhibitorById: async (id, cancel = false) => {
    const { token } = await store.getState().user;
    const response = await api.request({
      url: `/admin/exhibitors/${id}/`,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },

  putExhibitor: async (id, data, cancel = false) => {
    const { token } = await store.getState().user;
    const response = await api.request({
      url: `/admin/exhibitors/${id}`,
      method: "PUT",
      headers: { Authorization: `Bearer ${token}` },
      data: data,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },

  deleteExhibitor: async (id, cancel = false) => {
    const { token } = await store.getState().user;
    const response = await api.request({
      url: `/admin/exhibitors/${id}`,
      method: "DELETE",
      headers: { Authorization: `Bearer ${token}` },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },

  ///////////////////////////
  // Vistors
  //////////////////////////

  postVisitor: async (data, cancel = false) => {
    const { token } = await store.getState().user;
    console.log(token);
    const response = await api.request({
      url: "/admin/visitors/",
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      data: data,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },

  getVisitor: async (params, cancel = false) => {
    const { token } = await store.getState().user;
    console.log(token);
    const response = await api.request({
      url: "/admin/visitors/",
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
      params: params,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },

  getEventVisitor: async (params, cancel = false) => {
    const { token } = await store.getState().user;
    console.log(token);
    const response = await api.request({
      url: "/admin/event-visitors/",
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
      params: params,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },

  getVisitorsByEventId: async (id, cancel = false) => {
    const { token } = await store.getState().user;
    const response = await api.request({
      url: `/events/${id}/visitors/`,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },

  putVisitor: async (id, data, cancel = false) => {
    const { token } = await store.getState().user;
    console.log(token);
    const response = await api.request({
      url: `/admin/visitors/${id}`,
      method: "PUT",
      headers: { Authorization: `Bearer ${token}` },
      data: data,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },

  deleteVisitor: async (id, cancel = false) => {
    const { token } = await store.getState().user;
    console.log(token);
    const response = await api.request({
      url: `/admin/visitors/${id}`,
      method: "DELETE",
      headers: { Authorization: `Bearer ${token}` },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },

  bulkUploadUsers: async (data, params, cancel = false) => {
    const { token } = await store.getState().user;
    console.log(token);
    const response = await api.request({
      url: `/auth/bulk-upload-users`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "form-data",
      },
      data: data,
      params: params,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },

  resetPassword: async (userId, cancel = false) => {
    const { token } = await store.getState().user;
    console.log(token);
    const response = await api.request({
      url: `/admin/users/${userId}/change-password`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "form-data",
      },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },

  //////////////////////////
  // Subscriptions
  //////////////////////////

  getSubscriptions: async (eventId, params, cancel = false) => {
    const { token } = await store.getState().user;
    console.log(token);
    const response = await api.request({
      url: "/subscriptions/" + eventId,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
      params: params,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },

  addSubscriptionPlan: async (data, cancel = false) => {
    const { token } = await store.getState().user;
    console.log(token);
    const response = await api.request({
      url: `/subscriptions`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },

  updateSubscriptionPlan: async (id, data, cancel = false) => {
    const { token } = await store.getState().user;
    console.log(token);
    const response = await api.request({
      url: `/subscriptions/${id}`,
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },

  deleteSubscription: async (id, cancel = false) => {
    const { token } = await store.getState().user;
    console.log(token);
    const response = await api.request({
      url: `/subscriptions/${id}`,
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },

  requestSubscriptionPlan: async (data, cancel = false) => {
    const { token } = await store.getState().user;
    console.log(token);
    const response = await api.request({
      url: `/subscriptions/sendEmail`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
};

const cancelApiObject = defineCancelApiObject(AdminAPI);
