import '@fontsource-variable/inter';
import React from 'react';
import './App.css';
import Router from './Routes/Router';

function App() {
  return (
    <Router/>
  );
}

export default App;
